import React, { useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { any, objectOf } from 'prop-types'
import styled from 'styled-components'
import { months, weekdays } from '@helpers/string-helper'
import { flatten, uniq } from 'lodash'
import { breakPoints, colors } from '@constants/styles'
import { getDateFull } from '@helpers/date'
import MiniProductTile from './mini-product-tile'
import DeliverySectionShippingType from './delivery-section-shipping-type'
import CalendarPopupSplit from './delivery-section-calendar-popup-split'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.h4`
  border-top: 1px solid #e7e7ea;
  border-bottom: 1px solid #e7e7ea;
  color: ${colors.darkerGrey};
  display: flex;
  justify-content: space-between;
  span {
    font-size: 18px;
    font-weight: 700;
    margin-top: 1px;
  }
`

const Content = styled(Column)`
  margin: 20px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    margin: 10px 0 0 0;
  }
`

const GridWrapper = styled(Grid)`
  justify-content: space-between;
  @media only screen and (max-width: ${breakPoints['medium-max']}) {
    flex-direction: column;
  }
`

const ProductItem = styled(Column)`
  margin: 10px 0 0 10px;
`

const SplitDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ProductsWrapper = styled.div`
  flex-direction: column;
`

const SplitDeliverySectionForm = ({ order, cart }) => {
  const myRef = useRef([])

  const lineItemsFlat = uniq(
    flatten(order?.lineItems.map(item => (item.childItems ? [item, ...item.childItems] : item))),
  )

  const shipmentsData = order.splitCalendar.map((shipment, index) => ({
    expressDelivery: order?.splitDeliveryDates ? order.splitDeliveryDates[index]?.expressDelivery : false,
    data: shipment,
    items: uniq(
      flatten(
        shipment.skus.map(sku =>
          cart.cartItems.filter(
            item =>
              item.product.sku === sku ||
              item.product.items_in_room?.map(subItem => subItem.sku).includes(sku) ||
              item.product.items_in_room?.map(subItem => subItem.sku).includes(order.packageSkuMap?.[sku]) ||
              item.activeAddons?.map(activeAddon => activeAddon.sku).includes(sku) ||
              order.lineItems.filter(lineItem => {
                if (lineItem.sku === item.sku) {
                  item.subItems = lineItem.childItems
                  return lineItem.childItems.map(childItem => childItem.sku).includes(sku)
                }

                return false
              }).length > 0,
          ),
        ),
      ),
    ),
    deliveryDate: order?.splitDeliveryDates?.[index]?.deliveryDate,
    deliveryDateObject: new Date(order?.splitDeliveryDates?.[index]?.deliveryDate),
  }))

  const getDeliveryDates = (isPickup, indx) => {
    const dateFormatter = dateString => {
      const newDate = new Date(dateString)
      return {
        date: dateString,
        readable: `${months[newDate.getUTCMonth()]} ${newDate.getUTCDate()}`,
        dayOfWeek: weekdays[newDate.getUTCDay()].substring(0, 3),
      }
    }

    const { deliveryCalendar } = shipmentsData?.[indx]?.data
    if (!isPickup) {
      /* premium delivery or doorway delivery dates with potential express dates prepended to the front of a new array */
      if (deliveryCalendar?.expressDeliveryDates?.length > 0) {
        return [
          ...deliveryCalendar?.expressDeliveryDates
            ?.map(dateFormatter)
            ?.map(date => ({ ...date, isExpressDelivery: true })),
          ...deliveryCalendar?.deliveryDates?.map(dateFormatter),
        ]
      }
      return deliveryCalendar?.deliveryDates?.map(dateFormatter)
    }

    /* pickup delivery dates */
    return deliveryCalendar?.pickupDates?.map(dateFormatter)
  }

  return (
    <SplitDetailWrapper>
      {shipmentsData.map((shipment, index) => {
        myRef.current[index] = React.createRef()
        return (
          <>
            <Header ref={myRef.current[index]}>
              <span>
                Delivery {index + 1} of {shipmentsData.length}
              </span>
              <CalendarPopupSplit
                order={order}
                myRef={myRef.current[index]}
                initialDate={shipment.deliveryDate}
                shipmentsData={shipmentsData}
                shipment={shipment}
                index={index}
                testId="select-delivery-date-button-split"
              />
            </Header>
            <Content>
              <GridWrapper container wrap="nowrap">
                <Grid item xs={12} md={6}>
                  <DeliverySectionShippingType
                    order={order}
                    fullDeliveryDate={getDateFull(
                      order?.splitDeliveryDates?.[index]?.deliveryDate || getDeliveryDates(order.isPickup, index),
                    )}
                    isExpress={shipment.expressDelivery}
                    myRef={myRef.current[index]}
                    isSplit
                    shipmentsData={shipmentsData}
                    shipment={shipment}
                    index={index}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProductsWrapper>
                    {shipment.items.map((item, i) => {
                      const itemsInPackage = item.product.items_in_room
                        ?.map(itemInside => ({ ...itemInside, subItems: [] }))
                        .filter(
                          itemInRoom =>
                            shipment.data.skus.includes(itemInRoom.sku) ||
                            shipment.data.skus
                              .map((shipmentSku, iterator) => {
                                if (!itemInRoom.subItems || iterator === 0) {
                                  itemInRoom.subItems = []
                                }

                                if (order.packageSkuMap?.[shipmentSku] === itemInRoom.sku) {
                                  itemInRoom.subItems.push(
                                    lineItemsFlat.filter(lineItem => lineItem.sku === shipmentSku)[0]?.title,
                                  )
                                  itemInRoom.subItems = uniq(itemInRoom.subItems)
                                }

                                return shipmentSku
                              })
                              .some(shipmentSku => order.packageSkuMap?.[shipmentSku] === itemInRoom.sku),
                        )

                      return (
                        <ProductItem key={item.sku}>
                          <Column>
                            {item?.product && (
                              <MiniProductTile
                                packageSkuMap={order.packageSkuMap}
                                index={i}
                                sku={item.product.sku}
                                title={item.product.title}
                                image={item.product.primary_image}
                                subItems={item.subItems?.filter(({ sku }) => shipment.data.skus.includes(sku)) || []}
                                quantity={item.quantity}
                                href={item.product.route}
                                productCount={shipment.items.length}
                                requiredAddon={item.product.required}
                                isStoreSku={item.product.isStoreSku || false}
                                items={itemsInPackage || []}
                              />
                            )}
                            {item?.activeAddons &&
                              item?.activeAddons
                                .filter(({ sku }) => shipment.data.skus.includes(sku))
                                .map(addonItem => (
                                  <MiniProductTile
                                    index={i}
                                    key={addonItem?.sku}
                                    sku={addonItem?.sku}
                                    title={addonItem?.title}
                                    image={addonItem?.image || addonItem?.primary_image}
                                    quantity={addonItem?.quantity}
                                    href={addonItem?.route}
                                    productCount={shipment.items.length}
                                    requiredAddon={item.required}
                                    isStoreSku={item.isStoreSku || false}
                                  />
                                ))}
                          </Column>
                        </ProductItem>
                      )
                    })}
                  </ProductsWrapper>
                </Grid>
              </GridWrapper>
            </Content>
          </>
        )
      })}
    </SplitDetailWrapper>
  )
}

SplitDeliverySectionForm.propTypes = {
  order: objectOf(any),
  cart: objectOf(any),
}

export default SplitDeliverySectionForm

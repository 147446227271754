import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { func, shape, string } from 'prop-types'
import styled from 'styled-components'
import { sentryLogger, levels, sentryMessages, setExtra } from '@helpers/sentry-logger'
import { getDeliverySpecificBody } from '@helpers/checkout/delivery-section'
import { updateDelivery } from '@services/checkout'
import { setOrder, setDeliveryMode } from '@redux/modules/checkout'
import { updateDeliveryAnalytics } from '@helpers/google-tag-manager'
import { PrimaryButton } from '@shared/button-types'
import { breakPoints, colors, fonts } from '@constants/styles'
import loaderLight from '../../../../../assets/images/loader-light.svg'

const StyledLoader = styled.img`
  height: 26px;
  margin-top: -5px;
  margin-bottom: -5px;
`

const StyledFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  .error {
    color: ${colors.red};
    font-size: ${fonts.txtSmall};
    padding: 10px;
  }
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    border-top: 1px solid #e7e7e7;
    padding-top: 10px;
  }
`

const Footer = ({ closeModal, order, selectedDeliveryMode }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const getDefaultSplitDate = (splitDeliveryIndex, deliveryMode) => {
    const { deliveryCalendar } = order.splitCalendar[splitDeliveryIndex]
    switch (deliveryMode) {
      case 'delivery':
        return deliveryCalendar.deliveryDates[0] || []
      case 'pickup':
        return deliveryCalendar.pickupDates[0] || []
      case 'express':
        return deliveryCalendar.expressDeliveryDates[0] || []
      default:
        return deliveryCalendar.deliveryDates[0] || []
    }
  }

  const getDefaultNonSplitDate = deliveryMode => {
    switch (deliveryMode) {
      case 'delivery':
        return order.deliveryCalendar[0] || []
      case 'pickup':
        return order.pickupCalendar[0] || []
      case 'express':
        return order?.expressDeliveryDates[0] || []
      default:
        return order.deliveryCalendar[0] || []
    }
  }

  const updateDeliveryMode = mode => {
    let newOrder
    setLoading(true)
    if (order.splitDeliveryDates) {
      newOrder = {
        ...order,
        deliveryDate: getDefaultSplitDate(order.splitCalendar.length - 1, mode),
        splitDelivery: order.splitCalendar.map((item, index) => getDefaultSplitDate(index, mode)),
      }
    } else {
      newOrder = {
        ...order,
        deliveryDate: getDefaultNonSplitDate(order, mode),
      }
    }
    updateDelivery(getDeliverySpecificBody(newOrder, mode))
      .then(data => {
        dispatch(setDeliveryMode(mode))
        dispatch(setOrder(data))
        closeModal()
        setLoading(false)
        setError(false)
        updateDeliveryAnalytics(data)
      })
      .catch(err => {
        setLoading(false)
        setError(true)
        sentryLogger({
          configureScope: {
            level: levels.error,
            orderId: order.orderId,
            text: `Update Delivery Modal Failure ${err}`,
            type: setExtra,
          },
          captureMessage: {
            level: levels.error,
            message: sentryMessages.updateDeliveryFailure,
            type: 'text',
          },
        })
      })
  }
  return (
    <StyledFooter>
      {error && <p className="error">There was an error updating your delivery options. Please try again.</p>}
      <PrimaryButton
        aria-label="Change Delivery Type"
        onClick={() => updateDeliveryMode(selectedDeliveryMode)}
        tabIndex="0"
        type="button"
        value="Change Delivery Type"
        style={{ textTransform: `uppercase`, width: '200px' }}
      >
        {!loading ? 'Save' : <StyledLoader alt="updating delivery options" className="loader" src={loaderLight} />}
      </PrimaryButton>
    </StyledFooter>
  )
}

Footer.propTypes = {
  closeModal: func,
  order: shape({
    orderId: string,
  }),
  selectedDeliveryMode: string,
}

export default Footer

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bool, object, string } from 'prop-types'
import styled from 'styled-components'
import { breakPoints, colors } from '@constants/styles'
import DeliveryModal from './delivery-modal'
import { DOORWAY_DELIVERY, PICK_UP, PROFESSIONAL_DELIVERY } from './constants'

const StyledButton = styled.button`
  color: ${colors.primary};
  font-size: 15px;
  margin-top: ${({ showAsLink }) => (showAsLink ? '' : '1px')};
  font-weight: ${({ showAsLink }) => (showAsLink ? 'normal' : '700')};
  font-style: ${({ showAsLink }) => (showAsLink ? 'italic' : 'normal')};
  text-transform: ${({ showAsLink }) => (showAsLink ? 'capitalize' : 'uppercase')};
  text-decoration: ${({ showAsLink }) => (showAsLink ? 'underline dotted' : 'currentColor solid none')};
  text-underline-offset: ${({ showAsLink }) => (showAsLink ? '2px' : '0')};
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: ${({ showAsLink }) => (showAsLink ? '12px' : '15px')};
  }
`

export const DeliveryModalWrapper = ({ deliveryZone, order, showAsLink }) => {
  const [showDeliveryModal, setShowDeliveryModal] = useState(false)
  const getDeliveryType = () => {
    if (order.isPickup && !order.doorwayDelivery) {
      return PICK_UP
    }
    if (!order.isPickup && !order.doorwayDelivery) {
      return PROFESSIONAL_DELIVERY
    }
    if (!order.isPickup && order.doorwayDelivery) {
      return DOORWAY_DELIVERY
    }
    return PROFESSIONAL_DELIVERY
  }

  const showDeliveryModalHandler = e => {
    e.preventDefault()
    setShowDeliveryModal(true)
  }

  return (
    <>
      <StyledButton
        onClick={showDeliveryModalHandler}
        onKeyDown={e => e.keyCode === 13 && showDeliveryModalHandler(e)}
        showAsLink={showAsLink}
        type="button"
        value="Change Delivery Type"
      >
        change type
      </StyledButton>
      {showDeliveryModal && (
        <DeliveryModal
          closeModal={() => setShowDeliveryModal(false)}
          deliveryZone={deliveryZone}
          modalOpen={showDeliveryModal}
          order={order}
          selectedDeliveryMode={getDeliveryType()}
        />
      )}
    </>
  )
}

DeliveryModalWrapper.propTypes = {
  deliveryZone: string,
  order: object,
  showAsLink: bool,
}

const mapStateToProps = state => ({
  order: state.checkout.order,
  deliveryZone: state.location.rtg_location.delivery_zone,
})

export default connect(mapStateToProps, null)(DeliveryModalWrapper)

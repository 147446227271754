import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

const ConfirmationModal = ({ shouldShowModal, closeModal = () => {}, children, label = 'Confirm Payment Removal' }) => (
  <Dialog
    onClose={closeModal}
    aria-labelledby="remove-payment-modal"
    open={shouldShowModal}
    tabIndex="0"
    id="panel1"
    className="credit-card-container"
    fullWidth
    maxWidth="xs"
  >
    <DialogTitle id="credit-card-modal">{label}</DialogTitle>
    <DialogContent>{children}</DialogContent>
  </Dialog>
)

ConfirmationModal.propTypes = {
  children: PropTypes.any,
  closeModal: PropTypes.any,
  shouldShowModal: PropTypes.any,
  label: PropTypes.string,
}

export default ConfirmationModal

import PropTypes from 'prop-types'
import React from 'react'
import { availabilityStockMessage } from '@helpers/product'
import { getCurrentLocation } from '@helpers/geo-location'
import DeliveryProduct from '../delivery-product'

const DeliveryTypeSection = ({ deliveryItems, ups, cart, usps }) => {
  const rtg_location = getCurrentLocation()
  return (
    <>
      <div className="shipped-section small-12 large-5">
        {deliveryItems.map((item, index) => {
          const itemInCart = cart.cartItems.filter(cartItem => cartItem.sku === item.sku)[0]
          let stockMessage = 'Shipping directly from vendor'

          if (itemInCart?.product?.warehouseAvailability?.[rtg_location.region]?.availabilityDate) {
            const availableOn = `${
              itemInCart.product.warehouseAvailability[rtg_location.region].availabilityDate
            }T00:00:00.000Z`

            stockMessage = availabilityStockMessage({
              availableOn,
              product: itemInCart.product,
              rtg_location,
            })
          }

          return (
            <>
              {/* eslint-disable-next-line no-nested-ternary */}
              <h3>{`Shipped to Your Door ${ups ? 'via UPS' : usps ? 'via USPS' : 'from Vendor'}`}</h3>
              <h4 className="small-12">
                <p className="bold">FREE</p>
                <p dangerouslySetInnerHTML={{ __html: stockMessage }} />
              </h4>
              <DeliveryProduct
                key={item.sku || item.product.sku}
                product={itemInCart ? itemInCart.product : item}
                productCount={deliveryItems.length}
                quantity={item.quantity}
                index={index}
                noImage={!item && !item.primary_image}
                warrantyEnabled={item.warrantyEnabled}
              />
            </>
          )
        })}
      </div>
      <br />
    </>
  )
}

DeliveryTypeSection.propTypes = {
  cart: PropTypes.any,
  deliveryItems: PropTypes.any,
  ups: PropTypes.any,
  usps: PropTypes.any,
}

export default DeliveryTypeSection

import React, { useState, useEffect } from 'react'
import { objectOf, any, arrayOf, string, bool, func } from 'prop-types'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import Modal from 'react-modal'
import { hideCouponInvalidModal } from '@redux/modules/checkout'
import InfoModal from '@shared/info-modal'
import { removeLocalStorage, getFromBrowserStorage } from '@helpers/storage'
import { getCartsLegalRequirements } from '@helpers/checkout/review-section'
import { setCheckoutDeliveryStep } from '@helpers/checkout/local-storage'
import ReviewSectionWarrantyInfo from './review-section-warranty-info'
import ReviewSectionInvalidFields from './review-section-invalid-fields'
import ReviewSectionTermsConditions from './review-section-terms-conditions'
import ReviewSectionEscStoreCartConditions from './review-section-esc-storecart-conditions'
import ReviewSectionPickupConfirmation from './review-section-pickup-confirmation'
import { ReviewSectionWrapper, ModalContentWrapper } from './styles'
import AcceptanceErrorMessage from './acceptance-error-message'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

export const ReviewSection = ({ order, checkoutStep, invalidFields, showCouponInvalidModal, dispatch }) => {
  const [checkoutRedesign] = useState(getFromBrowserStorage('session', 'checkoutRedesign'))
  const getWarrantyInfo = getCartsLegalRequirements(order)
  const { reviewInfo } = order

  useEffect(() => {
    if (order?.paymentInfo?.length > 0) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'click',
        interaction: {
          category: 'checkout',
          action: 'payment-type-confirm',
          label: order?.selectedPaymentType,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.paymentInfo])

  useEffect(() => {
    if (checkoutStep !== 'shipping') {
      setCheckoutDeliveryStep()
    }
  }, [checkoutStep])

  const onModalClose = () => {
    dispatch(hideCouponInvalidModal())
    // TODO: navigation need to add code to track ga data

    navigate('/cart')
    removeLocalStorage('coupon')
    removeLocalStorage('couponType')
  }

  return checkoutStep === 'review' ? (
    <>
      <ReviewSectionWrapper checkoutRedesign={checkoutRedesign}>
        <p className="review-text">Please review your order above and make any changes you need.</p>

        {/* ...TC... */}
        <ReviewSectionInvalidFields invalidFields={invalidFields} acceptTermsChecked={reviewInfo.acceptTerms} />
        <ReviewSectionTermsConditions reviewInfo={reviewInfo} isRequired={getWarrantyInfo} />

        {/* ...pickup date... */}
        {order.deliveryDate && order.isPickup && (
          <AcceptanceErrorMessage
            invalidFields={invalidFields}
            acceptanceFieldChecked={reviewInfo.acceptPickupTerms}
            message="*You must confirm the pickup date before continuing."
          />
        )}
        <ReviewSectionPickupConfirmation order={order} reviewInfo={reviewInfo} isRequired={getWarrantyInfo} />

        {/* ...lifestyle adjustable base plan... */}
        {getWarrantyInfo.acceptSevenYearTerms !== undefined && (
          <AcceptanceErrorMessage
            invalidFields={invalidFields}
            acceptanceFieldChecked={reviewInfo.acceptSevenYearTerms}
            message="*You must accept the lifestyle adjustable base plan terms and conditions before continuing."
          />
        )}
        <ReviewSectionEscStoreCartConditions order={order} reviewInfo={reviewInfo} isRequired={getWarrantyInfo} />

        {/* ...furniture protection plan... */}
        {getWarrantyInfo.acceptServiceContractTerms !== undefined && (
          <AcceptanceErrorMessage
            invalidFields={invalidFields}
            acceptanceFieldChecked={reviewInfo.acceptServiceContractTerms}
            message="*You must accept the furniture protection plan terms and conditions before continuing."
          />
        )}
        <ReviewSectionWarrantyInfo order={order} reviewInfo={reviewInfo} isRequired={getWarrantyInfo} />
      </ReviewSectionWrapper>
      <InfoModal shouldShowModal={showCouponInvalidModal} closeModal={onModalClose}>
        <ModalContentWrapper>
          Sorry we could not validate the coupon at the moment. Your coupon may be already redeemed or expired.
        </ModalContentWrapper>
      </InfoModal>
    </>
  ) : null
}

ReviewSection.propTypes = {
  reviewInfo: objectOf(any),
  order: objectOf(any),
  invalidFields: arrayOf(string),
  checkoutStep: string,
  showCouponInvalidModal: bool,
  dispatch: func,
}

const mapStateToProps = state => ({
  order: state.checkout.order,
  checkoutStep: state.checkout.checkoutStep,
  invalidFields: state.checkout.reviewInvalidFields,
  showCouponInvalidModal: state.checkout.showCouponInvalidModal,
})

export default connect(mapStateToProps)(ReviewSection)

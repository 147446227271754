import React, { useState } from 'react'
import DeliveryDateModal from '@shared/modals/DatePicker'
import { months, weekdays } from '@helpers/string-helper'
import { ClickAwayListener } from '@mui/base'
import { updateDelivery } from '@services/checkout'
import { getDeliverySpecificBody } from '@helpers/checkout/delivery-section'
import { setOrder } from '@redux/modules/checkout'
import { updateDeliveryAnalytics } from '@helpers/google-tag-manager'
import { useDispatch, useSelector } from 'react-redux'
import { logSentry } from '@helpers/sentry-logger'
import styled from 'styled-components'
import { breakPoints, colors } from '@constants/styles'
import { any, bool, objectOf, string } from 'prop-types'

const StyledButton = styled.button`
  color: ${colors.primary};
  margin-top: ${({ showAsLink }) => (showAsLink ? '' : '1px  !important')};
  font-size: ${({ bold }) => (bold ? '18px  !important' : '15px')};
  font-weight: ${({ bold }) =>
    bold ? '700 !important' : ({ showAsLink }) => (showAsLink ? 'normal !important' : '700 !important')};
  font-style: ${({ bold }) =>
    bold ? 'normal  !important' : ({ showAsLink }) => (showAsLink ? 'italic' : 'normal  !important')};
  text-transform: ${({ showAsLink }) => (showAsLink ? 'capitalize  !important' : 'uppercase  !important ')};
  text-decoration: ${({ showAsLink }) =>
    showAsLink ? 'underline dotted  !important' : 'currentColor solid none  !important'};
  text-underline-offset: ${({ showAsLink }) => (showAsLink ? '2px  !important' : '0  !important')};
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: ${({ bold }) =>
      bold ? '14px !important' : ({ showAsLink }) => (showAsLink ? '12px !important' : '15px !important')};
  }
`

export const CalendarPopup = ({
  order,
  initialDate,
  myRef,
  showAsLink = false,
  bold = false,
  text = 'Change Date',
  testId,
}) => {
  const dispatch = useDispatch()
  const deliveryMode = useSelector(state => state?.checkout?.deliveryMode)
  const [modalVisibility, setModalVisibility] = useState(false)

  const updateDeliveryDate = date => {
    if (order) {
      order.deliveryDate = date.date
      return updateDelivery(getDeliverySpecificBody(order, deliveryMode || order?.deliveryMode))
        .then(data => {
          dispatch(setOrder(data))
          setModalVisibility(false)
          updateDeliveryAnalytics(data)
        })
        .catch(() => logSentry('Update Delivery Modal Failure'))
    }

    logSentry('Update Delivery Modal Failure')
    return null
  }

  const handleOpenModal = e => {
    e.preventDefault()
    setModalVisibility(true)
  }

  const handleCloseModal = e => {
    e.stopPropagation()
    setModalVisibility(false)
  }

  const handleClickAway = e => {
    e.stopPropagation()
    setModalVisibility(false)
  }

  const getDeliveryDates = isPickup => {
    const dateFormatter = dateString => {
      const newDate = new Date(dateString)
      return {
        date: dateString,
        readable: `${months[newDate.getUTCMonth()]} ${newDate.getUTCDate()}`,
        dayOfWeek: weekdays[newDate.getUTCDay()].substring(0, 3),
      }
    }

    if (!isPickup) {
      /* premium delivery dates with potential express dates prepended to the front of a new array */
      if (order?.expressCalendar?.length > 0) {
        return [
          ...order?.expressCalendar?.map(dateFormatter)?.map(date => ({ ...date, isExpressDelivery: true })),
          ...order?.deliveryCalendar?.map(dateFormatter),
        ]
      }
      return order?.deliveryCalendar?.map(dateFormatter)
    }
    /* pickup delivery dates */
    return order?.pickupCalendar?.map(dateFormatter)
  }

  const deliveryDateObject = new Date(order?.deliveryDate)

  return (
    <ClickAwayListener onClickAway={e => handleClickAway(e)}>
      {/* set a reference to the parent div so we can place the popup in the correct position */}
      <>
        <StyledButton
          data-testid={testId}
          onClick={e => handleOpenModal(e)}
          onKeyDown={e => e.keyCode === 13 && setModalVisibility(true)}
          showAsLink={showAsLink}
          bold={bold}
          type="button"
          value="Select Delivery Date"
        >
          {text}
        </StyledButton>
        <DeliveryDateModal
          initialDate={{
            date: initialDate,
            readable: `${months[deliveryDateObject.getUTCMonth()]} ${deliveryDateObject.getUTCDate()}`,
          }}
          isOpen={modalVisibility}
          onSubmit={updateDeliveryDate}
          closeModal={e => handleCloseModal(e)}
          onClose={e => handleCloseModal(e)}
          dateRange={getDeliveryDates(order.isPickup)}
          contentLabel="Change Delivery Date Calendar"
          buttonText="Change Delivery Date"
          currentRef={myRef} // pass in the div ref
        />
      </>
    </ClickAwayListener>
  )
}

CalendarPopup.propTypes = {
  order: objectOf(any),
  initialDate: string,
  myRef: objectOf(any),
  showAsLink: bool,
  bold: bool,
  text: string,
  testId: string,
}

export default CalendarPopup

import React from 'react'
import { connect } from 'react-redux'
import { arrayOf, bool, object, objectOf, string } from 'prop-types'
import { handleAffirm } from '@helpers/checkout/payment-section/affirm'
import PaymentSection from './payment-section'

class PaymentSectionWrapper extends React.Component {
  componentDidMount() {
    const order = JSON.parse(sessionStorage.getItem('order'))
    if (order) handleAffirm(order)
  }

  render() {
    const { order, checkoutStep, checkoutStepsCompleted, paymentInvalidFields, cart } = this.props
    return (
      <PaymentSection
        cart={cart}
        invalidFields={paymentInvalidFields}
        order={order}
        checkoutStep={checkoutStep}
        checkoutStepsCompleted={checkoutStepsCompleted}
      />
    )
  }
}

PaymentSectionWrapper.propTypes = {
  paymentInvalidFields: arrayOf(string),
  order: object,
  cart: object,
  checkoutStep: string,
  checkoutStepsCompleted: objectOf(bool),
}

const mapStateToProps = state => ({
  order: state.checkout.order,
  cart: state.cart.cart,
  checkoutStep: state.checkout.checkoutStep,
  checkoutStepsCompleted: state.checkout.checkoutStepsCompleted,
  paymentInvalidFields: state.checkout.paymentInvalidFields,
})

export default connect(mapStateToProps)(PaymentSectionWrapper)

import React, { useState, useEffect } from 'react'
import { func, array, string, object, bool, node, element } from 'prop-types'
import SubmitButton from './submit-button'
import DatePicker from './date-picker'
import { StyledPopover, DateWrapper } from './styles'
/**
 * DateModal
 * Creates a modal with a slider presenting date ranges for selection
 */
function DateModal({ children, dateRange, isOpen, initialDate, onSubmit, closeModal, buttonText, currentRef }) {
  const [selectedDate, setSelectedDate] = useState(initialDate)

  const handleSubmit = async date => {
    await onSubmit(date)
    setSelectedDate(null)
  }

  useEffect(() => {
    setSelectedDate(null)
  }, [dateRange])

  let top
  let left
  if (currentRef?.current !== undefined) {
    /* use the passed in ref to determine where to anchor the popup */
    top = currentRef.current?.getBoundingClientRect().top
    left = currentRef.current?.getBoundingClientRect().left
  }

  if (!isOpen) return null
  return (
    <>
      <StyledPopover
        sx={{ boxShadow: 0 }}
        open={isOpen}
        anchorReference="anchorPosition"
        anchorPosition={{ top: top + 20, left: left + 885 }} // add on offsets to center the popup under the change date button
        onClose={closeModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <DateWrapper autoFocus>
          <DatePicker dates={dateRange} selectedDate={selectedDate || initialDate} setSelectedDate={setSelectedDate} />
          {/* 
            Checking for null selectedDate is to prevent the selectedDate from being outside the given date range
            If component was properly unmounted this would not be an issue
          */}
          <SubmitButton
            buttonText={buttonText}
            onSubmit={handleSubmit}
            selectedDate={selectedDate || initialDate}
            setSelectedDate={setSelectedDate}
          />
          {selectedDate?.isExpressDelivery && children}
        </DateWrapper>
      </StyledPopover>
    </>
  )
}

DateModal.propTypes = {
  onSubmit: func,
  dateRange: array,
  initialDate: object,
  isOpen: bool,
  closeModal: func,
  children: node,
  buttonText: string,
  currentRef: object,
}

export default DateModal

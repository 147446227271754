/* eslint-disable react/prop-types */
// TODO: add prop types
import React from 'react'
import Button from '@material-ui/core/Button'
import generateTestId from '@hooks/generateTestId'
import { savePageScroll } from '@helpers/product'
import styled from 'styled-components'

const ButtonLabel = styled.span`
  text-align: left;
  display: flex;
  width: 100%;
  color: #333;
  text-transform: initial;
`

export default function RTGLinkV2(props) {
  const { handleClick, config } = props
  const { mui, gtm, aria } = config
  const { id, label } = aria
  const { type, variant, secondary, fullWidth, startIcon, justify } = mui
  const getTestID = generateTestId()
  const enableScrollSave = ['/furniture', '/mattress', '/search'].includes(window.location.pathname)
  if (type === 'button') {
    return (
      <Button
        // MUI CONFIG
        variant={variant || 'text'}
        color={secondary ? 'secondary' : 'primary'} // Default Color === "primary"
        fullWidth={fullWidth || false}
        startIcon={startIcon || false}
        // GOOGLE TAG MANAGER CONFIG
        gtm-category={gtm.category}
        gtm-action={gtm.action}
        gtm-label={gtm.label}
        gtm-value={gtm.value}
        // AUTOMATION TEST ID
        data-testid={getTestID(gtm.category, gtm.label)}
        // WCAG
        id={aria.id}
        // HANDLE CLICK AND DATALAYER PUSH
        onClick={() => {
          window.dataLayer.push(gtm) // Data required by Google Tag Manager
          if (enableScrollSave) savePageScroll()
          handleClick()
        }}
        // INLINE STYLE OVERRIDES
        style={{
          justifyContent: justify || 'flex-start',
        }}
      >
        <ButtonLabel>{aria.label}</ButtonLabel>
      </Button>
    )
  }
}

import { store } from '@redux/store'
import { updatePayment } from '@services/checkout'
import { sentryLogger, levels, setExtra, cleanOrderObject } from '@helpers/sentry-logger'
import { setOrder, setCheckoutStep } from '@redux/modules/checkout'
import { getOrder } from '../global'
import { getRemainingTotal, getInfoWithGiftAndFinance } from './payment-section'
import { checkoutStepAnalytics } from '../../google-tag-manager'
import { setCheckoutDeliveryStep } from '../local-storage'

export const getAffirmBody = () => {
  const order = getOrder()
  const body = createOrderBody(order)
  return body
}

const getAffirmLineItems = lineItems => {
  const affirmItems = []
  if (lineItems) {
    for (let i = 0, n = lineItems.length; i < n; i++) {
      affirmItems.push({
        sku: lineItems[i].sku,
        display_name: lineItems[i].title || 'unknown',
        unit_price: lineItems[i].unitPrice,
        qty: lineItems.quantity,
      })
    }
  }
  return affirmItems
}

export const handleAffirm = order => {
  const index = window.location.href.indexOf('?checkout_token')
  if (index > 0) {
    const af = window.location.href.slice(index + 16)
    window.history.replaceState({}, document.title, '/checkout')
    if (order) {
      let afPaymentInfo = []
      const giftFin = getInfoWithGiftAndFinance()
      if (giftFin && giftFin.length > 0) {
        afPaymentInfo = giftFin
      }
      afPaymentInfo.push({
        paymentType: 'AFF',
        paymentProperties: {
          checkout_token: af,
          order_id: order.orderId,
        },
      })
      updatePayment({
        paymentInfo: afPaymentInfo,
        orderId: order.orderId,
      })
        .then(data => {
          store.dispatch(setOrder(data))
          store.dispatch(setCheckoutStep('review'))
          checkoutStepAnalytics('review')
          // remove from local storage, although the checkout step is review
          setCheckoutDeliveryStep()
          try {
            // scroll to payment/review section after adding Affirm successfully
            if (window && window.scroll) {
              window.scroll({ top: 500, behavior: 'smooth', block: 'start' })
            }
          } catch (e) {
            console.error('Page: Checkout | scroll error', e)
          }
        })
        .catch(() => {
          sentryLogger({
            configureScope: {
              type: setExtra,
              level: levels.error,
              order: cleanOrderObject(order),
            },
            captureMessage: {
              type: 'text',
              message: 'Checkout - Affirm update payment',
              level: levels.error,
            },
          })
        })
    }
  }
}

export const createOrderBody = order => {
  const applyFinance =
    order &&
    order.paymentInfo &&
    order.paymentInfo.filter(
      payment =>
        payment.paymentType === 'FIN' ||
        (payment.paymentType === 'DBUY' && payment.paymentProperties && payment.paymentProperties.hasPayments),
    ).length > 0
  let body = null
  if (order) {
    body = {
      order_id: order.orderId,
      merchant: {
        user_cancel_url: window.location.href,
        user_confirmation_url: window.location.href,
        user_confirmation_url_action: 'GET',
      },
      shipping: {
        name: {
          first: order.contact.firstName,
          last: order.contact.lastName,
        },
        address: {
          line1: order.shippingAddress.address1,
          line2: order.shippingAddress.address2,
          city: order.shippingAddress.city,
          state: order.shippingAddress.state,
          zipcode: order.shippingAddress.zip,
        },
        email: order.contact.email,
        phone_number: order.contact.phone,
      },
      billing: {
        name: {
          first: order.payer ? order.payer.firstName : order.contact.firstName,
          last: order.payer ? order.payer.lastName : order.contact.lastName,
        },
        address: {
          line1: order.billingAddress ? order.billingAddress.address1 : order.shippingAddress.address1,
          line2: order.billingAddress ? order.billingAddress.address2 : order.shippingAddress.address2,
          city: order.billingAddress ? order.billingAddress.city : order.shippingAddress.city,
          state: order.billingAddress ? order.billingAddress.state : order.shippingAddress.state,
          zipcode: order.billingAddress ? order.billingAddress.zip : order.shippingAddress.zip,
        },
        email: order.contact.email,
        phone_number: order.contact.phone,
      },
      items: getAffirmLineItems(order.lineItems),
      discounts: {},
      currency: 'USD',
      shipping_amount: order.totalDeliveryCharge,
      tax_amount: order.tax,
      total: Math.floor(getRemainingTotal(true, null, true, applyFinance).total * 100),
    }
  }
  return body
}

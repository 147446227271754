import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import CardContent from '@material-ui/core/CardContent'
import React from 'react'
import styled from 'styled-components'
import { currencyFormatUS } from '@helpers/string-helper'
import { onAddAnotherCard, onRemoveGiftCard } from '@helpers/checkout/payment-section/gift-cards'

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        minHeight: 50,
        borderRadius: 0,
        marginTop: '1rem',
      },
      label: {
        fontSize: '1rem',
        fontWeight: 'bold',
      },
    },
  },
})

const Container = styled.div``

// const StyledAppliedGiftCards = styled.div`
//   margin-top: 0.25em;
//   h4 {
//     display: inline;
//     font-weight: 600;
//   }
//   .applied-gift-card-container {
//     max-width: 21em;
//     .applied-gift-card {
//       width: 95%;
//       margin-top: 0.5em;
//       padding: 1em;
//       display: flex;
//       align-items: center;
//       font-size: ${fonts.txtSmall};
//       @media only screen and (max-width: ${breakPoints.small}) {
//         font-size: ${fonts.txtSmall};
//       }
//       .gift-card-info {
//         display: inline-block;
//         margin-left: 1em;
//       }
//       .number {
//         display: inline;
//         font-size: inherit;
//       }
//       .bold {
//         font-weight: 700;
//         display: inline;
//         margin: 0 0.25em;
//         font-size: inherit;
//         &.left {
//           margin-left: 0;
//         }
//       }
//       .remove-card {
//         background: ${colors.primary};
//         width: 3.25em;
//         height: 3.25em;
//         border-radius: 50%;
//         &:hover {
//           background: ${colors.lightBlue};
//         }
//         .loader {
//           height: 1.5rem;
//           width: 1.5rem;
//         }
//         .close {
//           position: relative;
//           top: 0;
//           right: 0;
//           left: 0;
//           bottom: 0;
//           width: 2.75em;
//           height: 2.75em;
//           margin: auto;
//           background-size: 100% !important;
//         }
//       }
//     }
//   }
//   .add-another-card {
//     color: ${colors.primary};
//     font-size: ${fonts.txtXsmall};
//     &:before {
//       content: '+';
//       margin-right: 0.25em;
//     }
//     &:hover {
//       text-decoration: underline;
//     }
//   }

const AppliedGiftCards = ({ giftCards, order, setGiftCardState, removing }) => {
  const [expanded, setExpanded] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleExpandClick}
        endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        fullWidth
        disableRipple
      >
        Applied Gift Cards
      </Button>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        style={{
          marginTop: 10,
        }}
      >
        {/* APPLIED GIFT CARDS */}
        <Grid container spacing={1}>
          {giftCards.map((giftCard, idx) => {
            const authorizedAmount = giftCard.authorizedAmount ? giftCard.authorizedAmount : giftCard.balance
            return (
              <Grid item xs={giftCards.length > 1 ? 6 : 12} key={giftCard.giftCardNumber}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      style={{
                        borderBottom: '1px solid #eee',
                        paddingBottom: 12,
                        marginBottom: 12,
                      }}
                    >
                      <Grid
                        item
                        xs={1}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginLeft: 11,
                        }}
                      >
                        <Chip color="primary" label={idx + 1} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography color="textSecondary">
                          Gift Card ending in *
                          <span
                            style={{
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                            }}
                          >
                            {`${giftCard.giftCardNumber.substr(-4)}`}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      style={{
                        borderBottom: '1px solid #eee',
                        paddingBottom: 12,
                      }}
                    >
                      <Typography color="textSecondary">Credit: {currencyFormatUS(giftCard.balance)}</Typography>
                      <Typography color="textSecondary">
                        Balance: {currencyFormatUS(giftCard.balance - authorizedAmount)}
                      </Typography>
                    </Grid>
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      tabIndex="0"
                      aria-label="Remove Gift Card"
                      fullWidth
                      onClick={() => onRemoveGiftCard(order, giftCard, setGiftCardState)}
                    >
                      Remove This Card
                    </Button>
                    {/* {!removing && ( */}
                    {/*  <img */}
                    {/*    className="icon close" */}
                    {/*    alt="close icon" */}
                    {/*    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E" */}
                    {/*  /> */}
                    {/* )} */}
                    {/* {removing && <img className="loader" alt="Removing gift card" src={loaderLight} />} */}
                    {/* </button> */}
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Collapse>
      {/* ADD ANOTHER GIFT CARD BUTTON */}
      {!order.giftCardInfo.addAnotherCard && order && order.amountDue > 0 && (
        <Button
          onClick={() => onAddAnotherCard(order)}
          color="primary"
          size="small"
          variant="contained"
          aria-label="Add Another Gift Card"
          value="Add Another Gift Card"
          fullWidth
          disableElevation
        >
          Add Another Gift Card
        </Button>
      )}
    </ThemeProvider>
  )
}

AppliedGiftCards.propTypes = {
  giftCards: PropTypes.any,
  order: PropTypes.any,
  removing: PropTypes.any,
  setGiftCardState: PropTypes.any,
}

export default AppliedGiftCards

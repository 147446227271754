import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import CheckoutMain from '../components/checkout'

const Checkout = ({ props }) => (
  <Layout {...props} checkout>
    <Helmet title="Checkout - Rooms To Go" meta={[{ name: 'robots', content: 'noindex, nofollow' }]} />
    <CheckoutMain />
  </Layout>
)

Checkout.propTypes = {
  props: PropTypes.any,
}

export default Checkout

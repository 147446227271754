import React, { useState, useEffect, useRef, useCallback } from 'react'
import { array, any, object, bool, objectOf, arrayOf } from 'prop-types'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import { format } from 'date-fns'
import { breakPoints, colors } from '@constants/styles'
import { useDispatch, useSelector } from 'react-redux'
import { updateDelivery, checkSplitElig, updatePayment } from '@services/checkout'
import { getDeliverySpecificBody } from '@helpers/checkout/delivery-section'
import { sentryLogger, levels, setExtra, logSentry } from '@helpers/sentry-logger'
import { currencyFormatUS } from '@helpers/string-helper'
import { updateDeliveryAnalytics } from '@helpers/google-tag-manager'
import { getDateFull } from '@helpers/date'
import SelfPickupImage from '@assets/images/delivery-types/self_pickup.svg'
import { setOrder, setSplitDeliveryDates } from '@redux/modules/checkout'
import ProfessionalDeliveryIcon from '@assets/images/delivery-types/professional_delivery.svg'
import DoorwayDeliveryIcon from '@assets/images/delivery-types/doorway_delivery.svg'
import Spinner from '@components/generic/loader'
import { store } from '@redux/store'
import { removeKlarnaSession } from '@helpers/checkout/payment-section/payment-section'

import { getRegionZone } from '@helpers/geo-location'
import DeliveryTypeModal from './delivery-type-modal'
import MiniProductTile from './mini-product-tile'
import DeliverySectionPickup from './delivery-section-pickup'
import DeliverySectionShippingType from './delivery-section-shipping-type'
import DeliverySectionSplitForm from './delivery-section-split-form'
import RadioButton from './delivery-modal/radio-button'
import CalendarPopup from './delivery-section-calendar-popup'

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Bold = styled.b`
  font-weight: 700;
`

const DeliverySectionContent = styled(Row)`
  flex-direction: column;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    display: block;
  }
`

const DeliveryType = styled(Column)`
  flex: 1 1 0;
  margin: 0 15px 15px;
`

const DeliveryDate = styled(Column)`
  flex: 1 1 0;
  margin: 0 15px 15px;
`

const Header = styled.h4`
  border-top: 1px solid #e7e7ea;
  border-bottom: 1px solid #e7e7ea;
  color: ${colors.darkerGrey};
  display: flex;
  justify-content: space-between;
  span {
    font-size: 18px;
    font-weight: 700;
    margin-top: 1px;
    font-style: normal;
  }
`

const Content = styled(Column)`
  margin: 20px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    margin: 10px 0 0 0;
  }
`

const GridContent = styled(Grid)`
  margin-top: 10px;
  margin-left: 20px;
`

const DeliveryFee = styled(Row)`
  color: ${colors.darkerGrey};
  font-size: 15px;
  margin: 10px 0;
  span {
    margin-right: 10px;
  }
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: 12px;
    margin: 0;
  }
`

const Title = styled(Bold)`
  color: ${colors.darkerGrey};
  font-size: 18px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: 15px;
  }
`

const Italic = styled.span`
  color: ${colors.darkerGrey};
  font-size: 15px;
  font-style: italic;
  span {
    color: #168736;
  }
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: 12px;
  }
`

const IconContainer = styled.div`
  &&& img,
  svg {
    width: 65px;
    padding: 0 5px;
    margin-top: 0.2rem;
    align-self: flex-start;
    @media only screen and (max-width: ${breakPoints['large-max']}) {
      width: auto;
      min-width: 40px;
    }
  }
`

const ProductItem = styled(Row)`
  margin: 10px 0;
`

const SplitDeliveryOptions = styled.div`
  &&& {
    color: ${colors.darkerGrey};
    display: flex;
    flex-direction: column;
    font-size: 15px;
    margin: 0 15px 15px;
    text-align: left;
    div {
      align-items: baseline;
      display: flex;
      flex-direction: row;
      label {
        font-size: 15px;
        margin-left: 5px;
      }
      input {
        margin: 0;
      }
    }
  }
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

const GridWrapper = styled(Grid)`
  justify-content: space-between;
  @media only screen and (max-width: ${breakPoints['medium-max']}) {
    flex-direction: column;
  }
`

const regionInPR = getRegionZone().region === 'PR'

const removeAllPaymentMethods = async order => {
  if (!order || !order.paymentInfo) {
    return
  }

  const data = await updatePayment({
    orderId: order.orderId,
    paymentInfo: [],
  })
  store.dispatch(setOrder(data))
  removeKlarnaSession()
}

const renderTitle = (isPickup, isDoorwayDelivery) => {
  if (isPickup) return <Title>Self-Pickup</Title>
  if (isDoorwayDelivery) return <Title>Doorway Delivery</Title>
  return <Title>Premium Delivery and Set-Up</Title>
}

const renderImage = (isPickup, isDoorwayDelivery) => {
  if (isPickup) return <img alt="Self-Pickup" name="Self-Pickup" src={SelfPickupImage} />
  return (
    <img
      alt={isDoorwayDelivery ? 'Doorway Delivery' : 'Premium Delivery'}
      name={isDoorwayDelivery ? 'Doorway Delivery' : 'Premium Delivery'}
      src={isDoorwayDelivery ? DoorwayDeliveryIcon : ProfessionalDeliveryIcon}
    />
  )
}

const getDefaultSplitDate = (order, splitDeliveryIndex) =>
  order.isPickup
    ? order?.splitCalendar?.[splitDeliveryIndex]?.deliveryCalendar?.pickupDates?.[0] || []
    : order?.splitCalendar?.[splitDeliveryIndex]?.deliveryCalendar?.deliveryDates?.[0] || []

const getDefaultNonSplitDate = (order, deliveryMode) => {
  switch (deliveryMode) {
    case 'delivery':
      return _get(order, 'lineItems.0.deliveryType') === 'E'
        ? _get(order, 'expressCalendar.0', _get(order, 'deliveryCalendar.0', []))
        : _get(order, 'deliveryCalendar.0', [])
    case 'pickup':
      return _get(order, 'pickupCalendar.0', [])
    case 'express':
      return _get(order, 'expressCalendar.0', [])
    default:
      return _get(order, 'deliveryCalendar.0', [])
  }
}

const DeliverySectionForm = ({ order, rtgDeliveryItems, isExpress, cart, storeCartLineItems }) => {
  const dispatch = useDispatch()
  const [splitSelectedFlag, setSplitSelectedFlag] = useState(!!order?.splitDeliveryDates)
  const [splitEligFlag, setSplitEligFlag] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const myRef = useRef()
  const [deliveryDate, setDeliveryDate] = useState(order.deliveryDate)
  const deliveryMode = useSelector(state => state.checkout.deliveryMode)
  const deliverySectionVisited = useSelector(state => state.checkout.deliverySectionVisited)

  const onlineCartItems = cart && cart.cartItems && cart.cartItems.length > 0
  const anyCartItems = onlineCartItems || (storeCartLineItems && storeCartLineItems.length > 0)
  const isDoorwayDelivery = order.doorwayDelivery
  const stringedCart = JSON.stringify(order.lineItems)

  // const splitEligFlag = !!order.splitCalendar  // TODO for the day we get the api response right

  const getDeliveryCharge = useCallback(() => {
    if (!order.isPickup && isDoorwayDelivery) return `${currencyFormatUS(order.doorwayDeliveryCharge || 0)}`
    if (!order.isPickup && !isDoorwayDelivery) return `${currencyFormatUS(order.totalDeliveryCharge || 0)}`
    if (order.isPickup) return 'Free'
    return ''
  }, [isDoorwayDelivery, order.doorwayDeliveryCharge, order.isPickup, order.totalDeliveryCharge])

  const [deliveryChargesValue, setDeliveryChargesValue] = useState(getDeliveryCharge())
  useEffect(() => {
    const previousDeliveryChargesValue = deliveryChargesValue
    const thisDeliveryChargesValue = getDeliveryCharge()
    if (previousDeliveryChargesValue !== thisDeliveryChargesValue) {
      setDeliveryChargesValue(thisDeliveryChargesValue)
      removeAllPaymentMethods(order)
    }
    /* Select earliest available date in pickup calendar, excluding today */
    if (order.isPickup) {
      if (order.pickupCalendar[0] === format(new Date(), 'yyyy-MM-dd')) {
        setDeliveryDate(order.pickupCalendar[1])
      } else {
        setDeliveryDate(order.pickupCalendar[0])
      }
    } else {
      setDeliveryDate(order.deliveryDate)
    }
  }, [
    order.isPickup,
    order.doorwayDelivery,
    order.doorwayDeliveryCharge,
    order.totalDeliveryCharge,
    deliveryChargesValue,
    getDeliveryCharge,
    order,
  ])
  useEffect(() => {
    setDeliveryDate(order.deliveryDate)
  }, [order])
  useEffect(() => {
    let fetching = false

    async function fetchSplitCheckElig() {
      const result = await checkSplitElig(order.orderId, order?.shippingAddress?.zip)
      if (!fetching) {
        setSplitEligFlag(regionInPR ? false : result)
        if (!result && order && !deliverySectionVisited) {
          toggleDefault()
        }
      }
    }
    try {
      if (order.orderId && order?.shippingAddress?.zip) {
        fetchSplitCheckElig()
      }
    } catch (error) {
      sentryLogger({
        configureScope: {
          type: setExtra,
          message: `cart - fetching split check eligibility: ${error}`,
          level: levels.error,
        },
      })
    }
    return () => {
      fetching = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringedCart, order.orderId, deliverySectionVisited])

  const toggleDefault = () => {
    setIsLoading(true)
    const newOrder = {
      ..._omit(order, ['splitDelivery', 'splitDeliveryDates']),
      deliveryDate: getDefaultNonSplitDate(order, deliveryMode),
    }
    updateDelivery(getDeliverySpecificBody(newOrder, deliveryMode))
      .then(data => {
        dispatch(setOrder(data))
        dispatch(setSplitDeliveryDates({}))
        updateDeliveryAnalytics(data)
        setSplitSelectedFlag(false)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        logSentry(order, 'Update Delivery Failure')
      })
  }

  const toggleSplit = () => {
    setIsLoading(true)
    //  set our dates
    const updateOrder = {
      ...order,
      deliveryDate: getDefaultSplitDate(order, order.splitCalendar.length - 1),
      splitDelivery: order.splitCalendar.map((item, index) => getDefaultSplitDate(order, index)),
    }

    updateDelivery(getDeliverySpecificBody(updateOrder, deliveryMode))
      .then(data => {
        dispatch(setOrder(data))
        updateDeliveryAnalytics(data)
        setSplitSelectedFlag(true)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        logSentry(order, 'Update Delivery Failure')
      })
  }

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }
  return (
    <>
      {rtgDeliveryItems.length > 0 && anyCartItems && (
        <DeliverySectionContent>
          <DeliveryType>
            <Header>
              <span>TYPE</span>
              <DeliveryTypeModal />
            </Header>
            <Content>
              <Row>
                <IconContainer>{renderImage(order.isPickup, isDoorwayDelivery)}</IconContainer>
                <Column>
                  {renderTitle(order.isPickup, isDoorwayDelivery)}
                  <DeliveryFee>
                    <span>Delivery Fee: </span>
                    <Bold>{getDeliveryCharge()}</Bold>
                  </DeliveryFee>
                  <Italic>
                    Click <DeliveryTypeModal showAsLink /> for more delivery choices{' '}
                    {order.doorwayDeliveryPossible && order.doorwayDeliveryCharge === 0 && !isDoorwayDelivery && (
                      <>
                        including <span>FREE</span> doorway delivery.
                      </>
                    )}
                    {isDoorwayDelivery && (
                      <>
                        including <span>PREMIUM</span> delivery and set-up.
                      </>
                    )}
                  </Italic>
                </Column>
              </Row>
              <Column>
                <DeliverySectionPickup order={order} />
              </Column>
            </Content>
          </DeliveryType>
          {splitEligFlag && order.splitCalendar ? (
            <SplitDeliveryOptions>
              <Header>
                <span>DELIVERY OPTIONS</span>
              </Header>
              <GridContent container direction="column">
                <Grid container item>
                  <RadioButton id="notsplit" checked={!splitSelectedFlag} name="split" onChange={toggleDefault} />
                  <label htmlFor="notsplit">Deliver all sets together</label>
                </Grid>
                <Grid container item>
                  <RadioButton id="split" checked={splitSelectedFlag} name="split" onChange={toggleSplit} />
                  <label htmlFor="split">Deliver as they are available</label>
                </Grid>
              </GridContent>
            </SplitDeliveryOptions>
          ) : null}
          <DeliveryDate>
            {splitSelectedFlag ? (
              <DeliverySectionSplitForm order={order} isExpress={isExpress} cart={cart} />
            ) : (
              <>
                <Header ref={myRef}>
                  <span>{order?.defaultDelivery ? 'ESTIMATED DATES' : 'DELIVERY DATE'}</span>
                  <CalendarPopup
                    order={order}
                    initialDate={deliveryDate}
                    myRef={myRef}
                    testId="select-delivery-date-button"
                  />
                </Header>
                <Content>
                  <GridWrapper container wrap="nowrap">
                    <Grid item>
                      <DeliverySectionShippingType
                        order={order}
                        fullDeliveryDate={getDateFull(deliveryDate)}
                        isExpress={isExpress}
                        myRef={myRef}
                      />
                    </Grid>
                    <Grid item>
                      {rtgDeliveryItems.map((item, index) => {
                        const itemInCart = cart.cartItems.filter(cartItem => cartItem.sku === item.sku)[0]
                        return (
                          <ProductItem key={item.sku}>
                            <Column>
                              {itemInCart?.product && (
                                <MiniProductTile
                                  index={index}
                                  sku={item?.sku}
                                  title={item?.title}
                                  image={itemInCart?.product?.primary_image}
                                  quantity={item?.quantity}
                                  href={itemInCart?.product?.route}
                                  productCount={rtgDeliveryItems.length}
                                  requiredAddon={itemInCart?.product?.required}
                                  isStoreSku={itemInCart?.product?.isStoreSku || false}
                                />
                              )}
                              {itemInCart?.activeAddons &&
                                itemInCart?.activeAddons.map(addonItem => (
                                  <MiniProductTile
                                    index={index}
                                    key={addonItem?.sku}
                                    sku={addonItem?.sku}
                                    title={addonItem?.title}
                                    image={addonItem?.image || addonItem?.primary_image}
                                    quantity={addonItem?.quantity}
                                    href={addonItem?.route}
                                    productCount={rtgDeliveryItems.length}
                                    requiredAddon={item.required}
                                    isStoreSku={item.isStoreSku || false}
                                  />
                                ))}
                            </Column>
                          </ProductItem>
                        )
                      })}
                    </Grid>
                  </GridWrapper>
                </Content>
              </>
            )}
          </DeliveryDate>
        </DeliverySectionContent>
      )}
    </>
  )
}

DeliverySectionForm.propTypes = {
  order: objectOf(any),
  rtgDeliveryItems: arrayOf(object),
  isExpress: bool,
  cart: objectOf(any),
  storeCartLineItems: array,
}

export default DeliverySectionForm

import React, { useEffect } from 'react'
import { any, objectOf } from 'prop-types'
import { weekdays, months } from '@helpers/string-helper'
import { setReviewInfo } from '@helpers/checkout/review-section'

const ReviewSectionPickupConfirmation = ({ order, reviewInfo: { acceptPickupTerms } }) => {
  let deliveryDate
  let day
  let month
  let dayNum
  let year
  let deliveryDates = new Set()

  if (order.deliveryDate) {
    deliveryDate = new Date(new Date(order.deliveryDate).setHours(new Date(order.deliveryDate).getHours() + 6))
    day = weekdays[deliveryDate.getDay()]
    month = months[deliveryDate.getMonth()]
    dayNum = deliveryDate.getDate()
    year = deliveryDate.getFullYear()

    deliveryDates.add(`${day || 'weekday'}, ${month || 'month'} ${dayNum || 'day'}, ${year || 'year'}`)
  }

  if (order.splitDeliveryDates) {
    deliveryDates = new Set()

    order.splitDeliveryDates.forEach(splitDelivery => {
      deliveryDate = new Date(
        new Date(splitDelivery.deliveryDate).setHours(new Date(splitDelivery.deliveryDate).getHours() + 6),
      )
      day = weekdays[deliveryDate.getDay()]
      month = months[deliveryDate.getMonth()]
      dayNum = deliveryDate.getDate()
      year = deliveryDate.getFullYear()

      deliveryDates.add(`${day || 'weekday'}, ${month || 'month'} ${dayNum || 'day'}, ${year || 'year'}`)
    })
  }

  const acceptDeliveryText = `
    <span>
      I confirm that I have chosen
    </span>
    <span style="color: #eb141f;">
      ${Array.from(deliveryDates).join(' </span>&<span style="color: #eb141f;"> ')}
    </span>
    <span>
      as my pickup ${
        deliveryDates.size > 1 ? 'dates' : 'date'
      }. I understand that the person associated with the billing account
      must be present at the time of pickup with a valid, government issued ID. I acknowledge that wait time could be up to two 
      hours and that I am responsible for the transportation and assembly of all furniture picked up. If necessary, I will 
      reschedule my pick up date online or by calling customer service at 1-800-766-6786.
    </span>`

  useEffect(() => {
    // following line is necessary, because on relaod it should be false
    if (acceptPickupTerms) setReviewInfo(false, 'acceptPickupTerms')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {order.deliveryDate && order.isPickup && (
        <div className="checkbox-container">
          <input
            id="pickupTerms"
            type="checkbox"
            name="Accept Pickup Terms"
            checked={!!acceptPickupTerms}
            onChange={e => setReviewInfo(e.target.checked, 'acceptPickupTerms')}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            className="text-container"
            htmlFor="pickupTerms"
            dangerouslySetInnerHTML={{ __html: acceptDeliveryText }}
          />
        </div>
      )}
    </>
  )
}

ReviewSectionPickupConfirmation.propTypes = {
  order: objectOf(any),
  reviewInfo: objectOf(any),
}

export default ReviewSectionPickupConfirmation

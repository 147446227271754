import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'
import { setDeclineModalClose } from '@helpers/checkout/global'
import loaderLight from '../../../assets/images/loader-light.svg'
import '../../../assets/css/components/checkout/checkout-parts/decline-modal.sass'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

const DeclineModal = ({ modalOpen, loading, type, pType }) => (
  <Modal
    isOpen={modalOpen}
    onRequestClose={() => setDeclineModalClose(type, pType)}
    contentLabel={` ${type} Declined Modal`}
    className="decline-modal"
    overlayClassName="modal-overlay"
  >
    <div className="modal-content">
      <div className="grid-x">
        <div className="text-container small-12">
          {type === 'Invalid delivery date' ? (
            <span className="msg">
              Your delivery date has expired. Please go back to reconfirm a delivery date and resubmit your order. For
              help, please call <a href="tel:1-888-709-5380">1-888-709-5380</a> Option #1.
            </span>
          ) : (
            <span className="msg">
              We were unable to authorize your {type}. Please select another payment type and resubmit your order. For
              help, please call <a href="tel:1-888-709-5380">1-888-709-5380</a> Option #1.
            </span>
          )}
        </div>
        <button
          type="button"
          className="blue-action-btn"
          tabIndex="0"
          value="Continue to Site"
          aria-label="Continue to Site"
          onClick={() => setDeclineModalClose(type, pType)}
        >
          {!loading ? 'Close' : <img className="loader" alt={`Close ${type} decline modal`} src={loaderLight} />}
        </button>
      </div>
    </div>
  </Modal>
)

DeclineModal.propTypes = {
  loading: PropTypes.any,
  modalOpen: PropTypes.any,
  pType: PropTypes.any,
  type: PropTypes.any,
}

export default DeclineModal

// dependencies
import React, { useCallback, useState, useRef } from 'react'
import { object, number, func } from 'prop-types'
import styled from 'styled-components'
import { Button, Grid, List } from '@material-ui/core'

// services
import { updatePayment } from '@services/checkout'
import { store } from '@redux/store'
import { setOrder } from '@redux/modules/checkout'

// constants
import { breakPoints } from '@constants/styles'

// helpers
import { sentryLogger, levels, setExtra, cleanOrderObject, sentryMessages } from '@helpers/sentry-logger'
import { onRemoveGiftCard } from '@helpers/checkout/payment-section/gift-cards'
import { removeKlarnaSession } from '@helpers/checkout/payment-section/payment-section'

// components
import RTGLink from '@shared/link'
import { LoadingSpinner } from '@components/shared/LoadingSpinner'
import ConfirmationModal from '@shared/confirmation-modal'
import PaymentAppliedItem from './payment-applied-item'
import { PAYMENT_TYPES, PAYMENT_LABELS } from './payment-section'

const PaymentAppliedContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 340px;
  color: #333;
  @media only screen and (max-width: ${breakPoints.medium}) {
    width: 100%;
  }
  .label {
    font-weight: 600;
  }
  .balance {
    text-align: right;
    padding-right: 21px;
  }
`

const LoadingSpinnerWrapper = styled.div`
  height: 2.45rem;
  width: 100%;
`

const removePayment = async (order, setLoading, paymentType, callback) => {
  if (!order || !order.paymentInfo) {
    return
  }

  setLoading(true)
  const paymentInfo = order.paymentInfo.filter(payment => payment?.paymentType !== paymentType)

  const data = await updatePayment({
    orderId: order?.orderId,
    paymentInfo,
  })

  try {
    store.dispatch(setOrder(data))
    setLoading(false)
    callback()
  } catch (e) {
    setLoading(false)
    sentryLogger({
      configureScope: {
        type: setExtra,
        level: levels.error,
        orderId: order.orderId,
        paymentType: paymentInfo[0].paymentType,
        order: cleanOrderObject(order),
      },
      captureMessage: {
        type: 'text',
        message: sentryMessages.paymentUpdateFailure,
        level: levels.error,
      },
    })
  }
  if (paymentType === PAYMENT_TYPES.KLN) {
    removeKlarnaSession()
  }
  if (paymentType === PAYMENT_TYPES.KLN) {
    removeKlarnaSession()
  }
}

const AppliedPayments = ({ order, total, closeModals }) => {
  const cardInfoRef = useRef(null)
  const [paymentRemoveLoading, setPaymentRemoveLoading] = useState(false)
  const [paymentRemoveConfirmationModalVisible, setPaymentRemoveConfirmationModalVisible] = useState(false)

  const paymentT = cardInfoRef.current?.paymentType
  const eventLabel = PAYMENT_LABELS[paymentT] || paymentT

  const afterCardRemovalCallback = useCallback(() => {
    cardInfoRef.current = null
    setPaymentRemoveConfirmationModalVisible(false)
  }, [])

  const afterGiftCardRemovalCallback = useCallback(value => {
    setPaymentRemoveLoading(value)
    if (value === false) {
      cardInfoRef.current = null
      setPaymentRemoveConfirmationModalVisible(false)
    }
  }, [])

  return (
    <PaymentAppliedContainer>
      {/* TODO: ABSTRACT REMOVE PAYMENT METHOD MODAL */}
      <ConfirmationModal label="Payment removal confirmation" shouldShowModal={paymentRemoveConfirmationModalVisible}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <p>Are you sure you want to remove this payment type?</p>
          </Grid>
          <Grid item xs={6}>
            <RTGLink
              data={{
                slug: '/checkout',
                category: 'checkout',
                action: 'payment-type-remove',
                label: eventLabel,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                type="button"
                disabled={paymentRemoveLoading}
                disableElevation
                onClick={() => {
                  const paymentType = cardInfoRef.current?.paymentType
                  closeModals()
                  if (paymentType === PAYMENT_TYPES.GIFT) {
                    onRemoveGiftCard(
                      order,
                      { giftCardNumber: cardInfoRef.current.paymentProperties?.cardNumber },
                      afterGiftCardRemovalCallback,
                    )
                  } else if (
                    [
                      PAYMENT_TYPES.AFF,
                      PAYMENT_TYPES.CYBERV3,
                      PAYMENT_TYPES.VISA,
                      PAYMENT_TYPES.GEN,
                      PAYMENT_TYPES.DBUY,
                      PAYMENT_TYPES.PALV2,
                      PAYMENT_TYPES.KLN,
                      PAYMENT_TYPES.BPAY,
                    ].includes(paymentType)
                  ) {
                    removePayment(order, setPaymentRemoveLoading, paymentType, afterCardRemovalCallback)
                  }
                }}
                style={{
                  minHeight: 50,
                  borderRadius: 0,
                  marginTop: '1rem',
                }}
              >
                {paymentRemoveLoading ? (
                  <LoadingSpinnerWrapper>
                    <LoadingSpinner />
                  </LoadingSpinnerWrapper>
                ) : (
                  'Yes'
                )}
              </Button>
            </RTGLink>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="button"
              disableElevation
              onClick={() => {
                setPaymentRemoveConfirmationModalVisible(false)
              }}
              style={{
                minHeight: 50,
                borderRadius: 0,
                marginTop: '1rem',
              }}
            >
              No
            </Button>
          </Grid>
        </Grid>
      </ConfirmationModal>
      <p className="label">Payment Applied</p>
      <List dense disablePadding>
        {order?.paymentInfo?.map((p, i) => (
          <PaymentAppliedItem
            key={`applied-payment-${p.paymentProperties?.cardNumber || i}`}
            payment={p}
            order={order}
            cardInfoRef={cardInfoRef}
            setPaymentRemoveConfirmationModalVisible={setPaymentRemoveConfirmationModalVisible}
          />
        ))}
      </List>
      <p className="balance">
        Balance Remaining: <strong>${total.toFixed(2)}</strong>
      </p>
    </PaymentAppliedContainer>
  )
}

AppliedPayments.propTypes = {
  order: object,
  total: number,
  closeModals: func,
}

export default AppliedPayments

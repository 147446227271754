import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import AffirmLogo from '@assets/images/co-affirm.svg'
import RTGLinkV2 from '@shared/RTGLinkV2'

const AffirmButton = ({ affirmLoaded, affirmFunc, affirm_config, affirmClick }) => {
  if (!affirmLoaded) {
    return (
      <Helmet>
        <script>{affirmFunc(window, affirm_config, 'affirm', 'checkout', 'ui', 'script', 'ready')}</script>
      </Helmet>
    )
  }

  return (
    <RTGLinkV2
      config={{
        mui: {
          type: 'button',
          fullWidth: true,
          color: 'primary',
          justify: 'flex-start',
          startIcon: <img src={AffirmLogo} width="175px" height="50px" alt="Short Term Financing" />,
        },
        aria: {
          id: 'payment-option-affirm',
          label: 'Short Term Financing',
        },
        gtm: {
          slug: '/checkout',
          category: 'checkout',
          action: 'payment-type-open',
          label: 'Affirm',
          event: 'click',
        },
      }}
      handleClick={affirmClick}
    />
  )
}

AffirmButton.propTypes = {
  affirmClick: PropTypes.any,
  affirmFunc: PropTypes.func,
  affirmLoaded: PropTypes.any,
  affirm_config: PropTypes.any,
}

export default AffirmButton

import React, { useCallback, useState, useMemo } from 'react'
import classNames from 'classnames'
import ReactToolTip from 'react-tooltip'
import { object, string, array, bool, objectOf } from 'prop-types'
import styled from 'styled-components'
import { get, filter } from 'lodash'

import { setOrderInfo, getKlarnaAvailability } from '@helpers/checkout/global'
import { Grid, DialogTitle, DialogContent, Dialog, createTheme, ThemeProvider } from '@material-ui/core'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import GiftCardLogo from '@assets/images/co-giftCard.svg'
import rtgCredit from '@assets/images/co-synchrony.svg'
import GenesisLogo from '@assets/images/co-genesis.svg'
import RTGLinkV2 from '@shared/RTGLinkV2'
import { getTotalDisplayValue } from '@helpers/order'
import { shouldShowPayments, removeKlarnaSession } from '@helpers/checkout/payment-section/payment-section'
import { getCreditCardDecision } from '@helpers/checkout/payment-section/credit-card'
import ErrorMessage from '@shared/error-message'
import { colors, fonts } from '@constants/styles'
import { onChangeUseGiftCard } from '@helpers/checkout/payment-section/gift-cards'
import { getFromBrowserStorage } from '@helpers/storage'
import { useDispatch } from 'react-redux'
import generateTestId from '@hooks/generateTestId'
import { getRegionZone } from '@helpers/geo-location'
import BitPayLogo from '@images/co-bitpay.svg'
import BitpayModal from '@components/checkout/checkout-parts/payment-section/bitpay-modal'
import { getFinancePlans } from '@helpers/finance'
import PaypalButton from './paypal'
import Affirm from './affirm'
import PaymentCondensed from './payment-condensed'
import GiftCards from './gift-cards'
import CreditCardMicroform from './credit-card-microform'
import RoomsToGoCredit from './rooms-to-go-credit'
import RoomsToGoCreditBackup from './rooms-to-go-credit-backup'
import { PaymentForm } from './styles'
import KlarnaPayment from './klarna-payment'
import ApplePayPaymentOption from './apple-pay-payment-option'
import AppliedPayments from './payment-applied'

const regionInPR = getRegionZone().region === 'PR'

const disableDBuy = getFromBrowserStorage('session', 'digital-buy-disabled')

const GenesisMessage = styled.p`
  color: ${colors.success};
  font-size: ${fonts.txtMediumUp};
  margin: 5px 0 0;
`

const ButtonLabel = styled.span`
  text-align: left;
  display: flex;
  width: 100%;
  color: #333;
  font-size: 14px;
  text-transform: initial;
`

const PaymentOption = styled.div`
  border-top: 1px solid #dedede;
  min-height: 70px;
`

const PaypalOption = styled(Grid)`
  display: flex;
  max-width: 175px;
  min-width: 175px;
  height: 55px;
  margin-left: 3px;
  margin-right: 8px;
  margin-top: 8px;
  z-index: 1;
`

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        minHeight: 75,
        borderRadius: 0,
        borderTop: '1px solid #dedede',
        '&:focus': {
          outline: ['none', '!important'],
        },
      },
      startIcon: {
        minWidth: 175,
      },
      textPrimary: {
        color: '#333',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 24px 16px',
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: 'bold',
      },
    },
  },
})

export const PAYMENT_TYPES = {
  CYBERV3: 'CYBERV3',
  GEN: 'GEN',
  VISA: 'VISA',
  AFF: 'AFF',
  GIFT: 'GIFT',
  DBUY: 'DBUY',
  PALV2: 'PALV2',
  APP: 'APP',
  FIN: 'FIN',
  BPAY: 'BIT',
  KLN: 'KLN',
}

export const PAYMENT_LABELS = {
  CYBERV3: 'Credit',
  GEN: 'Genesis Credit',
  VISA: 'Visa Checkout',
  AFF: 'Affirm',
  GIFT: 'Gift',
  DBUY: 'Rooms To Go',
  PALV2: 'PayPal',
  APP: 'Apple Pay',
  GIFTCD: 'Gift Card',
  BPAY: 'bitpay',
  KLN: 'Klarna',
}

export const PAYMENT_CHECKOUT_LABELS = {
  CYBERV3: 'Credit',
  GEN: 'Genesis Credit X-{{account_number}}',
  VISA: 'Visa Checkout',
  AFF: 'Affirm Finance',
  GIFT: 'Gift Card X-{{account_number}}',
  DBUY: 'RTG Credit X-{{account_number}}',
  PALV2: 'PayPal',
  APP: 'Apple Pay',
  GIFTCD: 'Gift Card',
  BPAY: 'BitPay',
  KLN: 'Klarna',
}

export const PAYMENT_LOGOS = {
  AFF: 'affirm',
  GIFT: 'gift',
  DBUY: 'rtg',
  PALV2: 'paypal',
  BPAY: 'bitpay',
  KLN: 'klarna',
}

export const PAYMENT_CYBERV3_LABELS = {
  visa: 'Visa',
  mastercard: 'MasterCard',
  maestro: 'Discover',
  amex: 'American Express',
}

export const MODAL_TYPES = {
  credit: 'credit',
  finance: 'finance',
  genesis: 'genesis',
  giftCard: 'giftCard',
  paypal: 'paypal',
  applePay: 'applePay',
  bitpay: 'bitpay',
  klarna: 'klarna',
}

const OTHER_PAYMENT_METHODS = [
  PAYMENT_LABELS.PALV2,
  PAYMENT_LABELS.VISA,
  PAYMENT_LABELS.AFF,
  PAYMENT_LABELS.APP,
  PAYMENT_LABELS.BPAY,
  PAYMENT_LABELS.KLN,
]

const INITIAL_MODAL_STATE = Object.keys(MODAL_TYPES).reduce((init, key) => {
  init[key] = false
  return init
}, {})

const FIELD = 'selectedPaymentType'

const GIFT_CARD_SKU_CODE = '83333333'

const CHECKOUT_STEP = {
  payment: 'payment',
}

const PaymentSection = ({ order, checkoutStep, checkoutStepsCompleted, invalidFields, cart }) => {
  const dispatch = useDispatch()
  const showPaymentMethods = shouldShowPayments(order)
  const decision = getCreditCardDecision()
  // const affirmAllowed = affirmIsAllowed(order)
  const isKlarnaAvailable = getKlarnaAvailability(order, cart)
  const getTestID = generateTestId()

  const [hideFinModal, setHideFinModal] = useState(false)
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE)

  const hasGenesis = order?.paymentInfo?.filter(({ paymentType }) => paymentType === PAYMENT_TYPES.GEN)[0]

  let hideMore = false
  if (order?.paymentInfo?.length > 0) {
    const fin = order.paymentInfo.filter(({ paymentType }) =>
      [PAYMENT_TYPES.FIN, PAYMENT_TYPES.GEN, PAYMENT_TYPES.DBUY].includes(paymentType),
    )
    if (fin.length === 1 && order.paymentInfo.length === 1) {
      hideMore = order.amountDue <= 0
    }
  }

  const morePaymentMethods = useMemo(
    () => (order ? OTHER_PAYMENT_METHODS.includes(order.selectedPaymentType) : false),
    [order],
  )

  const storeCartLineItems = useMemo(
    () => (order && order.lineItems ? order.lineItems.filter(lineItem => lineItem.isStoreSku) : []),
    [order],
  )
  // in the following line I assumed that isCheckout flag is true
  const total = getTotalDisplayValue(order, true, checkoutStepsCompleted, cart, storeCartLineItems)
  const giftCardInCart = !!filter(get(cart, 'cartItems', []), item => get(item, 'product.sku') === GIFT_CARD_SKU_CODE)
    .length
  const openModal = useCallback(
    type => {
      setModalState({
        ...modalState,
        [type]: true,
      })

      // eslint-disable-next-line default-case
      switch (type) {
        case MODAL_TYPES.credit:
          setOrderInfo(PAYMENT_LABELS.CYBERV3, FIELD, true)
          break
        // TODO do we need this?
        case MODAL_TYPES.paypal:
          setOrderInfo(PAYMENT_LABELS.PALV2, FIELD, true)
          break
        case MODAL_TYPES.applePay:
          setOrderInfo(PAYMENT_LABELS.APP, FIELD)
          break
        case MODAL_TYPES.finance:
        case MODAL_TYPES.genesis:
          setOrderInfo(PAYMENT_LABELS.DBUY, FIELD, false)
          break
        case MODAL_TYPES.giftCard:
          onChangeUseGiftCard(
            order,
            order.giftCardInfo.useGiftCard ? order.giftCardInfo.useGiftCard : !order.giftCardInfo.useGiftCard,
          )
          setOrderInfo(PAYMENT_LABELS.GIFTCD, FIELD, true)
          break
        case MODAL_TYPES.klarna:
          // TODO: implement klarna
          // onChangeUseGiftCard(
          //   order,
          //   order.giftCardInfo.useGiftCard ? order.giftCardInfo.useGiftCard : !order.giftCardInfo.useGiftCard,
          // )
          setOrderInfo(PAYMENT_LABELS.KLN, FIELD, true)
          break
      }
    },
    [order, modalState],
  )

  const closeModals = useCallback(() => setModalState(INITIAL_MODAL_STATE), [])

  const hideModal = useCallback(val => setHideFinModal(val), [])

  const renderPayPal = () => (
    <PaymentOption>
      <Grid container alignItems="center">
        <PaypalOption item>
          <PaypalButton testID={getTestID('checkout', 'PayPal')} isCheckout setOrderInfo={setOrderInfo} order={order} />
          {/* @todo should this be setOrderInfo('PayPal', 'selectedPaymentType', true) */}
        </PaypalOption>
        <Grid item>
          <ButtonLabel>Payment Service</ButtonLabel>
        </Grid>
      </Grid>
    </PaymentOption>
  )

  // TODO: Clean these conditional helpers up
  // const NO_PAYMENT_APPLIED = order?.selectedPaymentType.length === 0
  const SYNCHRONY_PAYMENT_APPLIED =
    order?.paymentInfo?.filter(({ paymentType }) => paymentType === PAYMENT_TYPES.DBUY).length > 0
  const GENESIS_PAYMENT_APPLIED =
    order?.paymentInfo?.filter(({ paymentType }) => paymentType === PAYMENT_TYPES.GEN).length > 0
  const AFFIRM_PAYMENT_APPLIED =
    order?.paymentInfo?.filter(({ paymentType }) => paymentType === PAYMENT_TYPES.AFF).length > 0
  const GIFT_CARD_APPLIED =
    order?.paymentInfo?.filter(({ paymentType }) => paymentType === PAYMENT_TYPES.GIFT).length > 0

  // returns Synchrony plans over the threshold amount
  const financePlans = getFinancePlans(0, true).find(plan => plan !== undefined) // need at least one to display DBUY payment option

  const NO_ACTIVE_FINANCING = !GENESIS_PAYMENT_APPLIED && !AFFIRM_PAYMENT_APPLIED && !SYNCHRONY_PAYMENT_APPLIED
  return (
    <ThemeProvider theme={theme}>
      {checkoutStep !== CHECKOUT_STEP.payment &&
        order?.selectedPaymentType !== PAYMENT_LABELS.BPAY &&
        order?.amountDue <= 0 &&
        checkoutStepsCompleted.payment && <PaymentCondensed order={order} />}
      {checkoutStep !== CHECKOUT_STEP.payment &&
        order?.selectedPaymentType === PAYMENT_LABELS.BPAY &&
        checkoutStepsCompleted.payment && (
          <PaymentCondensed
            order={{
              ...order,
              paymentInfo: [{ paymentType: PAYMENT_LABELS.BPAY }],
            }}
          />
        )}
      {checkoutStep === CHECKOUT_STEP.payment && (
        <>
          {order?.paymentInfo?.length && order?.amountDue < order?.total ? (
            <AppliedPayments order={order} total={total} closeModals={closeModals} />
          ) : (
            ''
          )}

          {total > 0 && (
            <PaymentForm>
              {order?.paymentInfo?.length && order?.amountDue < order?.total ? (
                <p className="required-label">Choose An Additional Payment Method</p>
              ) : (
                <p className="required-label">Choose Your Payment Method</p>
              )}

              {invalidFields.length > 0 && (
                <ErrorMessage
                  customMessage={{
                    message: 'Cannot continue until a payment has been submitted.',
                    id: 'payment-section',
                  }}
                />
              )}
              {/* TODO: Create a component that can be used for each button */}
              {/* CREDIT/DEBIT CARD */}
              <div>
                <RTGLinkV2
                  config={{
                    // MUI Config
                    mui: {
                      type: 'button', // default: text
                      fullWidth: true, // default: false
                      color: 'primary', // default: primary
                      justify: 'flex-start', // default: flex-start
                      startIcon: (
                        <PaymentSvg
                          uniqueNameForId="creditMicroForm2"
                          cards={['discover', 'mastercard', 'visa', 'amex']}
                          cordY="0"
                          vpHeight="50"
                          width="175px"
                          height="50px"
                        />
                      ),
                    },
                    // WCAG
                    aria: {
                      id: 'payment-option-credit',
                      label: 'Credit or Debit', // Visible label. Supports HTML
                    },
                    // Google Tag Manager Config
                    gtm: {
                      slug: '/checkout', // Used to match event with origin page when generating reports
                      category: 'checkout', // Where these actions should be reported within GTM
                      label: PAYMENT_LABELS.CYBERV3, // The label should identify the payment type
                      action: 'payment-type-open', // An action should be generic
                      event: 'click', // Describe the event taking place
                    },
                  }}
                  handleClick={() => openModal(MODAL_TYPES.credit)}
                />
                <Dialog
                  onClose={closeModals}
                  aria-labelledby="payment-option-credit"
                  open={modalState[MODAL_TYPES.credit]}
                  tabIndex="0"
                  id="payment-modal-credit"
                  className="credit-card-container"
                >
                  <DialogTitle id="payment-modal-credit__title">Enter Credit/Debit Card</DialogTitle>
                  <DialogContent>
                    {showPaymentMethods && (
                      <>
                        {order.selectedPaymentType === PAYMENT_LABELS.CYBERV3 && !morePaymentMethods && (
                          <div
                            className={classNames('billing-iframe', {
                              accepted: decision,
                            })}
                          >
                            {order && order.amountDue > 0 && hasGenesis && (
                              <GenesisMessage>{`After applying Genesis Credit, you have a remaining balance due of $${order.amountDue.toFixed(
                                2,
                              )}`}</GenesisMessage>
                            )}
                            <CreditCardMicroform order={order} onClose={closeModals} />
                          </div>
                        )}
                      </>
                    )}
                  </DialogContent>
                </Dialog>
              </div>
              {/* TODO: Create a component that can be used for each button */}
              {/* ROOMS TO GO CREDIT CARD (SYNCHRONY) */}
              {NO_ACTIVE_FINANCING && financePlans && (
                <div>
                  <RTGLinkV2
                    config={{
                      // MUI Config
                      mui: {
                        type: 'button', // default: text
                        fullWidth: true, // default: false
                        color: 'primary', // default: primary
                        justify: 'flex-start', // default: flex-start
                        startIcon: (
                          <img src={rtgCredit} width="175px" height="50px" alt="Rooms To Go Credit Card by Synchrony" />
                        ),
                      },
                      // WCAG
                      aria: {
                        id: 'payment-option-synchrony',
                        label: 'Financing', // Visible label. Supports HTML
                      },
                      // Google Tag Manager Config
                      gtm: {
                        slug: '/checkout', // Used to match event with origin page when generating reports
                        category: 'checkout', // Where these actions should be reported within GTM
                        label: 'Rooms To Go Finance', // The label should identify the payment type
                        action: 'payment-type-open', // An action should be generic
                        event: 'click', // Describe the event taking place
                      },
                    }}
                    handleClick={() => openModal(MODAL_TYPES.finance)}
                  />
                  <Dialog
                    onClose={closeModals}
                    aria-labelledby="payment-option-synchrony"
                    open={modalState[MODAL_TYPES.finance]}
                    tabIndex="0"
                    id="payment-modal-synchrony"
                    className={classNames('rooms-to-go-credit-container', {
                      hide: hideFinModal,
                    })}
                  >
                    <DialogTitle id="payment-modal-synchrony__title" style={{ paddingBottom: 0 }}>
                      Select Financing Plan
                    </DialogTitle>
                    <DialogContent>
                      {disableDBuy === true ? (
                        <RoomsToGoCreditBackup order={order} cardType="Synchrony" onFinClose={closeModals} />
                      ) : (
                        <RoomsToGoCredit
                          order={order}
                          cardType="Synchrony"
                          onFinHide={val => hideModal(val)}
                          onFinClose={closeModals}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {/* PAYPAL */}
              {renderPayPal()}
              {/* KLARNA */}
              {!regionInPR && NO_ACTIVE_FINANCING && isKlarnaAvailable && (
                <div>
                  <RTGLinkV2
                    config={{
                      // MUI Config
                      mui: {
                        type: 'button', // default: text
                        fullWidth: true, // default: false
                        color: 'primary', // default: primary
                        justify: 'flex-start', // default: flex-start
                        startIcon: (
                          <img
                            src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
                            width="100%"
                            alt="Klarna payment option"
                            style={{ backgroundColor: '#ffb3c7', borderRadius: '5px', maxHeight: '55px' }}
                          />
                        ),
                      },
                      // WCAG
                      aria: {
                        id: 'payment-option-synchrony',
                        label: '4 Interest-Free Payments', // Visible label. Supports HTML
                      },
                      // Google Tag Manager Config
                      gtm: {
                        slug: '/checkout', // Used to match event with origin page when generating reports
                        category: 'checkout', // Where these actions should be reported within GTM
                        label: 'Klarna', // The label should identify the payment type
                        action: 'payment-type-open', // An action should be generic
                        event: 'click', // Describe the event taking place
                      },
                    }}
                    handleClick={() => openModal(MODAL_TYPES.klarna)}
                  />
                  <Dialog
                    onClose={() => {
                      closeModals()
                      removeKlarnaSession()
                    }}
                    aria-labelledby="payment-option-synchrony"
                    open={modalState[MODAL_TYPES.klarna]}
                    tabIndex="0"
                    id="payment-modal-synchrony"
                    className={classNames('rooms-to-go-credit-container', {
                      hide: hideFinModal,
                    })}
                  >
                    <DialogTitle id="payment-modal-synchrony__title">Select Financing Plan</DialogTitle>
                    <DialogContent>
                      <KlarnaPayment orderId={order.orderId} total={order.amountDue || order.total} />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {/* APPLE PAY */}
              {!regionInPR && <ApplePayPaymentOption order={order} />}
              {/* TODO: Create a component that can be used for each button */}
              {/* AFFIRM */}
              {!regionInPR && NO_ACTIVE_FINANCING && (
                <Affirm
                  testID={getTestID('checkout', 'Affirm')}
                  setOrderInfo={() => setOrderInfo('Affirm', 'selectedPaymentType')}
                />
              )}
              {/* BITPAY */}
              {!regionInPR && !GIFT_CARD_APPLIED && NO_ACTIVE_FINANCING && (
                <>
                  <RTGLinkV2
                    config={{
                      // MUI Config
                      mui: {
                        type: 'button', // default: text
                        fullWidth: true, // default: false
                        color: 'primary', // default: primary
                        justify: 'flex-start', // default: flex-start
                        startIcon: <img src={BitPayLogo} width="175px" height="50px" alt="Bitpay Logo" />,
                      },
                      // WCAG
                      aria: {
                        id: 'payment-option-bitpay',
                        label: 'Bitcoin & Ethereum', // Visible label. Supports HTML
                      },
                      // Google Tag Manager Config
                      gtm: {
                        slug: '/checkout', // Used to match event with origin page when generating reports
                        category: 'checkout', // Where these actions should be reported within GTM
                        label: 'Bitpay Cryptocurrency', // The label should identify the payment type
                        action: 'payment-type-open', // An action should be generic
                        event: 'click', // Describe the event taking place
                      },
                    }}
                    handleClick={() => openModal(MODAL_TYPES.bitpay)}
                  />
                  <BitpayModal closeModals={closeModals} open={modalState[MODAL_TYPES.bitpay]} order={order} />
                </>
              )}
              {/* GENESIS - Hidden if SYNCHRONY payment is applied */}
              {!regionInPR && NO_ACTIVE_FINANCING && (
                <div>
                  <RTGLinkV2
                    config={{
                      mui: {
                        type: 'button',
                        fullWidth: true,
                        color: 'primary',
                        justify: 'flex-start',
                        startIcon: <img src={GenesisLogo} width="175px" height="50px" alt="Genesis Credit" />,
                      },
                      aria: {
                        id: 'payment-option-genesis',
                        label: 'Genesis Credit',
                      },
                      gtm: {
                        slug: '/checkout',
                        category: 'checkout',
                        action: 'payment-type-open',
                        label: PAYMENT_LABELS.GEN,
                        event: 'click',
                      },
                    }}
                    handleClick={() => openModal(MODAL_TYPES.genesis)}
                  />
                  <Dialog
                    onClose={closeModals}
                    aria-labelledby="payment-option-genesis"
                    open={modalState[MODAL_TYPES.genesis]}
                    id="payment-modal-genesis"
                    tabIndex="0"
                    className={classNames('rooms-to-go-credit-container', {
                      hide: hideFinModal,
                    })}
                  >
                    <DialogTitle id="payment-modal-genesis_title">Enter Genesis Account</DialogTitle>
                    <DialogContent>
                      {disableDBuy === true ? (
                        <RoomsToGoCreditBackup order={order} cardType="GENESIS" onFinClose={closeModals} />
                      ) : (
                        <RoomsToGoCredit
                          order={order}
                          cardType="GENESIS"
                          onFinHide={val => hideModal(val)}
                          onFinClose={closeModals}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {/* GIFT CARD */}
              {!giftCardInCart && NO_ACTIVE_FINANCING && (
                <div>
                  <RTGLinkV2
                    config={{
                      mui: {
                        type: 'button',
                        fullWidth: true,
                        color: 'primary',
                        justify: 'flex-start',
                        startIcon: <img src={GiftCardLogo} width="175px" height="50px" alt="RTG Gift Card" />,
                      },
                      aria: {
                        id: 'payment-option-rtg-gift-card',
                        label: 'RTG Gift Card',
                      },
                      gtm: {
                        slug: '/checkout',
                        category: 'checkout',
                        action: 'payment-type-open',
                        label: PAYMENT_LABELS.GIFT,
                        event: 'click',
                      },
                    }}
                    handleClick={() => openModal(MODAL_TYPES.giftCard)}
                  />
                  <Dialog
                    onClose={closeModals}
                    aria-labelledby="rtg-giftCard-modal"
                    open={modalState[MODAL_TYPES.giftCard]}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle id="rtg-giftCard-modal">Enter Gift Card</DialogTitle>
                    <DialogContent>
                      {order?.selectedPaymentType !== 'bitpay' && (
                        <div
                          className={classNames('gift-card-container', {
                            'gift-cards-active': order.giftCardInfo.useGiftCard,
                          })}
                        >
                          <GiftCards order={order} />
                        </div>
                      )}
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              <ReactToolTip id="creditDisabled" place="top" type="dark" effect="float">
                Must remove financing to use another payment method.
              </ReactToolTip>
              <ReactToolTip id="Affirm" place="top" type="dark" effect="float">
                Can't use Affirm to submit a down payment.
              </ReactToolTip>
              <ReactToolTip place="top" type="dark" effect="float">
                Must remove financing to use another payment method.
              </ReactToolTip>
            </PaymentForm>
          )}
        </>
      )}
    </ThemeProvider>
  )
}

PaymentSection.propTypes = {
  order: object,
  cart: object,
  checkoutStep: string,
  checkoutStepsCompleted: objectOf(bool),
  invalidFields: array,
}

export default PaymentSection

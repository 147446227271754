import React from 'react'
import { getUrlParams } from '@helpers/string-helper'
import { setOrderInfo } from '@helpers/checkout/global'
import { setOrderFinancePlanInfo, validateRTGCreditInfo } from '@helpers/checkout/payment-section/rtg-finance'
import { objectOf, any, func } from 'prop-types'
import loaderLight from '@assets/images/loader-light.svg'
import { getFinancePlans } from '@helpers/finance'
import { fetchCreditPostback } from '@services/credit'
import ErrorMessage from '@shared/error-message'
import { generateErrorMessage } from '@helpers/errors'
import SynchronyLogo from '@shared/svgs/synchrony-logo'
import GenesisLogo from '@shared/svgs/genesis-logo'
import DropdownSlider from './DropdownSlider'
import { HardLine } from './DropdownSlider/styles'
import CheckoutInput from '../checkout-input'
import SynchronyApply from './synchrony-apply'
import FinancePlan from './finance-plan'
import AcknowledgeCheckBoxes from './finance-plan/acknowledge-checkboxes'
import SelectedPlan from './finance-plan/selected-plan'
import {
  SubmitButtonContainer,
  SubmitRtgButton,
  CreditOptionsButton,
  FullWidthCentered,
  LoadingSpinner,
  LogoWrapper,
} from './styles'

const GENESIS = 'GENESIS'
const SYNCHRONY = 'Synchrony'

export default class RoomsToGoCredit extends React.Component {
  constructor(props) {
    super(props)
    this.financeCard = React.createRef()
    this.acknowledge = React.createRef()
    this.terms = React.createRef()
    this.financePlan = React.createRef()
    this.financePlan2 = React.createRef()
    this.phoneNumber = React.createRef()
    this.state = {
      creditCardNumber: '',
      cardProvider: '',
      checkedPlan: false,
      financePlans: [],
      hasFinance: false,
      invalidFields: [],
      loading: false,
      postbackRefId: null,
      rtgCreditInfo: {
        acknowledge: false,
        terms: false,
      },
      financeApproval: {
        provider: '',
        status: '',
      },
      genesisAttempts: 0,
      phoneNumber: '',
    }
  }

  componentDidMount = async () => {
    const { order } = this.props
    const realFinancePlans = getFinancePlans(0, true)
    await this.setState({
      financePlans: realFinancePlans,
    })
    if (order && order.paymentInfo) {
      order.paymentInfo.forEach(payment => {
        if (payment.paymentType === 'GEN' || payment.paymentType === 'FIN') {
          this.setState({ hasFinance: true })
          this.setCreditCardNumber(payment.paymentProperties.accountNumber)
        }
      })
    }
    if (order && order.financePlan && order.financePlan.code === '') {
      setOrderFinancePlanInfo({
        code: realFinancePlans[0].financeCode,
        hasPayments: realFinancePlans[0].downPaymentRequired,
      })
    }
    fetchCreditPostback().then(data => this.setState({ postbackRefId: data.postbackRefId }))

    if (window !== undefined) {
      const financeApproval = getUrlParams(window.location.search)

      if (financeApproval.status) {
        this.setState({ financeApproval })
      }
    }
  }

  setCreditCardNumber = async value => {
    const { financePlans } = this.state
    let cardProvider = ''
    if (value) {
      if (value.length >= 1) {
        const firstDigit = value.slice(0, 1)
        if (firstDigit === '6') {
          this.setRTGCreditInfo(false, 'acknowledge')
          setOrderInfo({ code: financePlans[0].financeCode, hasPayment: true }, 'financePlan')
          cardProvider = SYNCHRONY
        } else if (firstDigit === '7') {
          cardProvider = GENESIS
          setOrderInfo({ code: GENESIS, hasPayment: true }, 'financePlan')
          this.setRTGCreditInfo(true, 'acknowledge')
        }
        await this.setState({ creditCardNumber: value, cardProvider, invalidFields: [] })

        if (this.financePlan?.current?.checked === false) {
          this.financePlan.current.checked = true
        }
        return this.setState({ checkedPlan: true })
      }
    }
    this.setRTGCreditInfo(false, 'acknowledge')
    this.setRTGCreditInfo(false, 'terms')

    return this.setState({ creditCardNumber: value, cardProvider })
  }

  setRTGCreditInfo = (info, field) => {
    this.setState(prevState => ({
      rtgCreditInfo: {
        ...prevState.rtgCreditInfo,
        [field]: info,
      },
    }))
  }

  setRTGCreditState = (info, field, callback = null) => {
    if (field) {
      this.setState(
        prevState => ({
          ...prevState,
          [field]: info,
        }),
        callback,
      )
    } else {
      this.setState(
        prevState => ({
          ...prevState,
          ...info,
        }),
        callback,
      )
    }
  }

  trimCreditCard = card => card.replace(/\s/g, '').trim()

  hasInvalidFields = () => {
    const { rtgCreditInfo, cardProvider, checkedPlan, creditCardNumber, genesisAttempts } = this.state
    const invalidFields = []
    let attempts = genesisAttempts

    if (cardProvider === SYNCHRONY && checkedPlan === false) {
      invalidFields.push('financePlan')
    }
    if (rtgCreditInfo.acknowledge === false) invalidFields.push('acknowledge')
    if (rtgCreditInfo.terms === false) {
      if (cardProvider === 'GENESIS') {
        invalidFields.push('genesis-terms')
      } else invalidFields.push('terms')
    }
    if (cardProvider === 'GENESIS' && !invalidFields.includes('genesis-terms')) {
      attempts += 1
    }
    if (attempts === 3) {
      invalidFields.push('genesis-attempts')
    }
    if (this.trimCreditCard(creditCardNumber).length !== 16 && attempts !== 3) {
      invalidFields.push('finance')
    }

    if (!invalidFields.length) {
      this.setState({ loading: true, genesisAttempts: attempts })
    } else {
      this.setState({ invalidFields, genesisAttempts: attempts })
    }

    return invalidFields.length > 0
  }

  createErrorMessage = (field, index) => {
    const errorMessage = generateErrorMessage(field)

    if (index === 0) {
      switch (field) {
        case 'finance':
          if (this.financeCard.current.value.length === 16) break
          this.financeCard.current.focus()
          break
        case 'terms':
          if (this.terms.current.checked === true) break
          this.terms.current.focus()
          break
        case 'acknowledge':
          if (this.acknowledge.current.checked === true) break
          this.acknowledge.current.focus()
          break
        case 'financePlan':
          if (this.financePlan.current.checked === true || this.financePlan2.current.checked === true) {
            break
          }
          this.financePlan.current.focus()
          break
        default:
          break
      }
    }
    return errorMessage
  }

  getLogos = () => {
    const { cardProvider } = this.state
    switch (cardProvider) {
      case GENESIS:
        return <GenesisLogo />
      case SYNCHRONY:
        return <SynchronyLogo />
      default:
        return (
          <>
            <SynchronyLogo style={{ height: '60px' }} /> <p>|</p> <GenesisLogo />
          </>
        )
    }
  }

  render() {
    const { order, onFinClose } = this.props
    const {
      financePlans = [],
      postbackRefId,
      creditCardNumber,
      cardProvider,
      rtgCreditInfo,
      invalidFields,
      loading,
      financeApproval,
      errorMsg,
      hasFinance,
      genesisAttempts,
      phoneNumber,
    } = this.state
    const currentPlan =
      financePlans.length && financePlans.filter(plan => plan?.financeCode === order?.financePlan?.code)[0]
    const financePayments = order.paymentInfo.filter(
      payment => payment.paymentType === 'FIN' || payment.paymentType === 'GEN',
    )[0]
    const isGenesis = cardProvider !== GENESIS

    return (
      <>
        <DropdownSlider title="Rooms To Go Credit Provided By Synchrony" showTitle={isGenesis}>
          {hasFinance ? (
            <SelectedPlan plan={currentPlan} getLogos={this.getLogos} financePayments={financePayments} />
          ) : (
            <>
              {cardProvider !== GENESIS && <SynchronyApply postbackRefId={postbackRefId} />}
              <LogoWrapper>{this.getLogos()}</LogoWrapper>
              {errorMsg && <ErrorMessage customMessage={{ message: errorMsg, id: 'finance-error' }} />}
              {invalidFields.length > 0 &&
                invalidFields.map((field, index) => (
                  <ErrorMessage
                    key={field}
                    customMessage={this.createErrorMessage(field, index)}
                    invalidFields={[field]}
                  />
                ))}
              {financeApproval.status === 'approved' || financeApproval.status.length === 0 ? (
                <CheckoutInput
                  type="text"
                  field="finance"
                  id="credit-card"
                  label="Account Number*"
                  info={creditCardNumber}
                  setInfo={this.setCreditCardNumber}
                  name="credit-card"
                  placeholder="xxxx xxxx xxxx xxxx"
                  parentRef={this.financeCard}
                  disabled={genesisAttempts === 3}
                />
              ) : (
                <FullWidthCentered>
                  <CreditOptionsButton
                    to="/credit-options"
                    onClick={() => {
                      // TODO: navigation need to add code to track ga data
                    }}
                  >
                    Credit Options
                  </CreditOptionsButton>
                </FullWidthCentered>
              )}
              {cardProvider === 'GENESIS' && (
                <CheckoutInput
                  type="text"
                  field="finance"
                  id="phone"
                  label="Phone Number Associated with your Genesis Credit Account*"
                  info={phoneNumber}
                  setInfo={value => this.setState({ phoneNumber: value })}
                  name="phoneNumber"
                  placeholder=""
                  parentRef={this.phoneNumber}
                  disabled={genesisAttempts === 3}
                />
              )}
              {cardProvider && (
                <>
                  <FinancePlan
                    cardProvider={cardProvider}
                    order={order}
                    plans={financePlans}
                    financePlanRef={this.financePlan}
                    financePlan2Ref={this.financePlan2}
                  />
                  <AcknowledgeCheckBoxes
                    rtgCreditInfo={rtgCreditInfo}
                    setRTGCreditInfo={this.setRTGCreditInfo}
                    financePlan={currentPlan}
                    termsRef={this.terms}
                    acknowledgeRef={this.acknowledge}
                    cardProvider={cardProvider}
                  />
                  <SubmitButtonContainer>
                    <SubmitRtgButton
                      type="button"
                      value="Submit"
                      onClick={async e => {
                        if (!this.hasInvalidFields()) {
                          await validateRTGCreditInfo(
                            e,
                            currentPlan,
                            {
                              financePlan: order.financePlan.code,
                              cardNumber: this.trimCreditCard(creditCardNumber),
                              hasPayment: order.financePlan.hasPayment,
                              phoneNumber: phoneNumber.replace(/[-.()]/g, ''),
                            },
                            this.setRTGCreditState,
                            onFinClose,
                          )
                        }
                      }}
                      disabled={genesisAttempts === 3}
                    >
                      {!loading && 'Submit'}
                      {loading && <LoadingSpinner alt="Submitting rooms to go credit card" src={loaderLight} />}
                    </SubmitRtgButton>
                  </SubmitButtonContainer>
                </>
              )}
            </>
          )}
        </DropdownSlider>
        <HardLine />
      </>
    )
  }
}

RoomsToGoCredit.propTypes = {
  order: objectOf(any).isRequired,
  onFinClose: func,
}

import React from 'react'
import styled from 'styled-components'
import { breakPoints } from '@constants/styles'
import { objectOf, any, func } from 'prop-types'
import { removeFinancingPlan } from '@helpers/checkout/payment-section/rtg-finance'
import { LogoWrapper, SubmitRtgButton } from '../styles'
import MaskedCredit from '../../../masked-credit'

const SelectedPlanWrapper = styled.section`
  margin: 10px 0;
`

const PlanText = styled.p`
  margin: 10px 0 0;

  @media screen and (max-width: ${breakPoints.small}) {
    font-size: 16px;
  }
`

const RemoveFinanceButton = styled(SubmitRtgButton)`
  margin-top: 10px;
  width: auto;
`

const SelectedPlan = ({ plan, getLogos, financePayments, onFinClose }) =>
  plan?.financeCode && financePayments ? (
    <SelectedPlanWrapper>
      <LogoWrapper>{financePayments.paymentType === 'GEN' ? getLogos('GENESIS') : getLogos('Synchrony')}</LogoWrapper>
      <PlanText>Finance Option: {plan.siteFriendlyLabel}</PlanText>
      <PlanText>Authorized Amount: ${financePayments.authorizedAmount}</PlanText>
      {financePayments?.paymentProperties?.accountNumber && (
        <PlanText>
          Account: <MaskedCredit lastFour={financePayments.paymentProperties.accountNumber.slice(12)} />
        </PlanText>
      )}
      <RemoveFinanceButton onClick={() => removeFinancingPlan(onFinClose)} type="button">
        Remove Financing
      </RemoveFinanceButton>
    </SelectedPlanWrapper>
  ) : null

SelectedPlan.propTypes = {
  plan: objectOf(any).isRequired,
  getLogos: func.isRequired,
  financePayments: objectOf(any).isRequired,
  onFinClose: func,
}

export default SelectedPlan

import React from 'react'
import { bool } from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'
import { getRemainingTotal } from '@helpers/checkout/global'
import { DownpaymentParagraph } from './styles'

const DownPayment = ({ downPaymentRequired }) => {
  const orderAmounts = getRemainingTotal(true, null, true)
  return (
    <>
      {downPaymentRequired ? (
        <DownpaymentParagraph>
          <span>
            {`Small down payment required - ${
              orderAmounts ? currencyFormatUS(orderAmounts.tax + orderAmounts.deliveryCost) : currencyFormatUS(0)
            } (shipping & sales tax).`}
          </span>
        </DownpaymentParagraph>
      ) : (
        <DownpaymentParagraph>No down payment required</DownpaymentParagraph>
      )}
    </>
  )
}

DownPayment.propTypes = {
  downPaymentRequired: bool.isRequired,
}

export default DownPayment

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { decodeHtml } from '@helpers/string-helper'
import { productUrl } from '@helpers/route'
import { colors } from '@constants/styles'
import RoomItemList from '@shared/room-item-list'
import RTGLink from '../../../shared/link'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`

const ProductLink = styled(RTGLink)`
  flex: 0 0 auto;
`

const ProductTitle = styled.span`
  flex: 1;
`

const Quantity = styled.span`
  flex: 0 0 auto;
`

const SubItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
`

const ProductDetails = styled.div`
  color: ${colors.darkerGrey};
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 15px;

  a,
  p {
    font-size: 15px;
    font-weight: bold;
  }

  span {
    font-size: 12px;
  }
`

const ProductThumbnail = styled.div`
  width: 125px;
  display: flex;
  justify-content: center;
`

const MiniProductTile = ({
  href,
  image,
  isStoreSku,
  quantity,
  requiredAddon,
  sku,
  title,
  items,
  subItems,
  packageSkuMap,
}) => {
  // if (sku == "10137520") {
  //   debugger;
  // }
  const route = href || null

  // if (items?.length === 0 && subItems?.length === 0) {
  //   return null
  // }

  const isProductIsComplete = () => {
    const numberOfPieces = Object.values(packageSkuMap).filter(value => value === sku).length
    return numberOfPieces === subItems.length
  }

  return (
    <Row>
      {!requiredAddon && image && (
        <ProductLink
          data={{
            url: productUrl(route),
            altDesc: title || '',
          }}
          aria-hidden="true"
          tabIndex="-1"
        >
          <ProductThumbnail>
            <img src={`${image}${sku !== '83333333' ? '&h=62' : ''}`} alt="" role="presentation" aria-hidden="true" />
          </ProductThumbnail>
        </ProductLink>
      )}
      <ProductDetails>
        <RTGLink
          data={{
            slug: productUrl(route),
            altDesc: title || '',
          }}
          disabled={requiredAddon || isStoreSku}
        >
          <p>{title ? decodeHtml(title) : `SKU: ${sku} `}</p>
        </RTGLink>
        {(!subItems || subItems.length === 0 || isProductIsComplete()) && <Quantity>QTY: {` ${quantity}`}</Quantity>}
        {(!items || !items.length) && subItems && subItems.length > 0 && !isProductIsComplete() && (
          <span>
            Includes the following items from this package:{' '}
            {subItems.map(subItem => (
              <SubItem key={subItem.sku}>
                <ProductTitle>{subItem.title}</ProductTitle>
                <Quantity>QTY: {` ${quantity * subItem.quantity}`}</Quantity>
              </SubItem>
            ))}
          </span>
        )}
        <RoomItemList
          hideBorders
          packageSkuMap={packageSkuMap}
          fullWidth
          items={items}
          text="This delivery includes the following items from this room"
        />
      </ProductDetails>
    </Row>
  )
}

MiniProductTile.propTypes = {
  href: PropTypes.any,
  image: PropTypes.any,
  isStoreSku: PropTypes.any,
  items: PropTypes.shape({
    length: PropTypes.any,
  }),
  packageSkuMap: PropTypes.any,
  quantity: PropTypes.any,
  requiredAddon: PropTypes.any,
  sku: PropTypes.string,
  subItems: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  title: PropTypes.string,
}

export default MiniProductTile

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import CheckoutContentType from './checkout-step-content-type'

const SectionContent = styled.div`
  &&& {
    padding: 0.7em;
    background: ${colors.primaryInvert};
    text-align: right;
    width: 90%;
    margin: 0.5rem 5% 0 5%;
    @media only screen and (max-width: ${breakPoints.small}) {
      width: 100%;
      text-align: center;
      margin: 0;
    }
    .btn-container {
      display: flex;
      align-items: center;
      a {
        width: 100%;
        &.bitpay {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #333;
        }
        & > svg {
          margin-left: 0.7em;
        }
        & > span {
          text-align: end;
        }
      }
      @media only screen and (max-width: ${breakPoints.small}) {
        //position: sticky;
        bottom: 2.5rem;
        right: 0;
        z-index: 1;
        padding: 0.4rem 0.45rem 0 0.45rem;
        background: ${colors.primaryInvert};
        width: 100%;
        height: 4rem;
      }
      @media only screen and (max-width: ${breakPoints.medium}) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        position: sticky;
        bottom: 2.35rem;
        right: 0;
        z-index: 1;
        padding: 0.5em;
        background: ${colors.primaryInvert};
        width: 111%;
        margin: 0 -5.5%;
        text-align: center;
      }
      .loader {
        height: 2.75rem;
        @media only screen and (min-width: ${breakPoints['medium-min']}) {
          margin-right: 7%;
        }
      }
      .continue-btn,
      .place-order-btn {
        background-color: ${colors.primary};
        color: ${colors.primaryInvert};
        border-radius: 0;
        padding: 12px;
        text-align: center;
        box-sizing: border-box;
        font-size: ${fonts.txtMedium};
        text-transform: uppercase;
        font-weight: bold;
        position: relative;
        @media only screen and (min-width: ${breakPoints['medium-min']}) {
          right: 1.5%;
        }
        @media only screen and (max-width: ${breakPoints.medium}) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
          right: 0;
        }
        &:focus {
          outline: solid 3px -webkit-focus-ring-color;
        }
        &:hover {
          background-color: ${colors.lightBlue};
        }
        .loader {
          height: 1.688rem;
          margin: -1.2rem -1rem -1rem;
        }
      }
      .continue-btn {
        width: 10em;
      }
      .place-order-btn {
        margin: 0 auto;
        width: 12em;
      }
      .paypal-buttons-layout-horizontal {
        width: 100% !important;
      }
    }
    .text-disclaimer {
      display: flex;
      flex-direction: row;
      padding: 16px;
      text-align: left;
      .daggers {
        margin-right: 5px;
      }
      .cell {
        flex: 1;
      }
      i {
        font-size: 0.7rem;
        font-style: italic;
      }
      p {
        i {
          text-decoration: underline;
        }
      }
    }
  }
`

const CheckoutStepContent = props => {
  const { children, checkoutStep, sectionType, loading, nextSection, onSetCheckoutStep, order } = props

  return (
    <SectionContent>
      {children}
      {checkoutStep === sectionType && (
        <CheckoutContentType
          checkoutStep={checkoutStep}
          nextSection={nextSection}
          order={order}
          onSetCheckoutStep={onSetCheckoutStep}
          loading={loading}
        />
      )}
    </SectionContent>
  )
}

CheckoutStepContent.propTypes = {
  children: PropTypes.object,
  checkoutStep: PropTypes.string,
  sectionType: PropTypes.string,
  loading: PropTypes.bool,
  nextSection: PropTypes.string,
  onSetCheckoutStep: PropTypes.any,
  order: PropTypes.any,
}

export default CheckoutStepContent

import React from 'react'
import { bool, func, string } from 'prop-types'
import styled from 'styled-components'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import RadioButtonUncheckedOutlined from '@mui/icons-material/RadioButtonUncheckedOutlined'

const Radio = styled.input.attrs(({ checked, name, onChange }) => ({
  autoFocus: checked,
  checked,
  name,
  onChange,
  type: 'radio',
}))`
  && {
    // hide the radio button, but keep the element on the DOM
    visibility: hidden;
    width: 0;
    margin: ${({ mobile }) => (mobile ? '0' : '20px auto 0')};
  }
`
const SelectedText = styled.span`
  color: #008550;
  padding-left: 8px;
  font-weight: 700;
  bottom: 4px;
  position: relative;
`
const SelectedIcon = styled.span`
  color: #008550;
  bottom: -2px;
  left: -3px;
  position: relative;
`
const SelectedIconMobile = styled.span`
  color: #008550;
  bottom: -6px;
  left: -6px;
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`
const UnselectedIconMobile = styled.span`
  color: #333;
  bottom: -6px;
  left: -6px;
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`
/**
 * this is the radio button for the 'select a delivery type' popup
 * if the variable mobile is NOT passed in, the word 'Selected'
 * appears to the right of the radio button
 *
 * @param checked whether the radio button appears selected
 * @param name uses a CONST to compare when selected
 * @param onChange sets a state to match the CONST
 * @param mobile whether or not we are viewing this element in a mobile view
 * @returns {JSX.Element}
 * @constructor
 */
const RadioButton = ({ checked, name, onChange, mobile }) => (
  <>
    <Radio checked={checked} name={name} onChange={onChange} mobile={mobile} />
    {checked && !mobile && (
      <>
        <SelectedIcon>
          <CheckCircleOutlinedIcon />
        </SelectedIcon>
        <SelectedText>Selected</SelectedText>
      </>
    )}
    {checked && mobile && (
      <>
        <SelectedIconMobile>
          <CheckCircleOutlinedIcon />
        </SelectedIconMobile>
      </>
    )}
    {!checked && mobile && (
      <>
        <UnselectedIconMobile>
          <RadioButtonUncheckedOutlined />
        </UnselectedIconMobile>
      </>
    )}
  </>
)

RadioButton.propTypes = {
  checked: bool,
  name: string,
  onChange: func,
  mobile: bool,
}

export default RadioButton

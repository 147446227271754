import PropTypes from 'prop-types'
import React from 'react'
import { onChangeUseGiftCard } from '@helpers/checkout/payment-section/gift-cards'
import ErrorMessage from '@shared/error-message'
import GiftCardInputs from './gift-card-inputs'
import AppliedGiftCards from './applied-gift-cards'

const GiftCards = ({
  order,
  removing,
  loading,
  invalidFields,
  giftCards,
  paymentProperties,
  setGiftCardState,
  unableToAddMessage,
}) => (
  <>
    {order && (
      <>
        <input
          id="giftCardCheckbox"
          type="checkbox"
          name="Use Gift Card"
          aria-label="Use Gift Card"
          checked={order.giftCardInfo.useGiftCard}
          onChange={e => onChangeUseGiftCard(order, e.target.checked)}
          aria-hidden="true"
          hidden
        />
        {order.giftCardInfo.useGiftCard && invalidFields.length > 0 && (
          <>
            {' '}
            <ErrorMessage invalidFields={invalidFields} />
          </>
        )}
        {giftCards.length > 0 && order.giftCardInfo.useGiftCard && (
          <AppliedGiftCards
            order={order}
            removing={removing}
            giftCards={giftCards}
            setGiftCardState={setGiftCardState}
          />
        )}
        {((giftCards && giftCards.length < 1) || order.giftCardInfo.addAnotherCard) && (
          <>
            {order.giftCardInfo.useGiftCard && (
              <GiftCardInputs
                unableToAddMessage={unableToAddMessage}
                order={order}
                setGiftCardState={setGiftCardState}
                invalidFields={invalidFields}
                loading={loading}
              />
            )}
          </>
        )}
      </>
    )}
  </>
)

GiftCards.propTypes = {
  giftCards: PropTypes.any,
  invalidFields: PropTypes.any,
  loading: PropTypes.any,
  order: PropTypes.any,
  paymentProperties: PropTypes.any,
  removing: PropTypes.any,
  setGiftCardState: PropTypes.any,
  unableToAddMessage: PropTypes.any,
}

export default GiftCards

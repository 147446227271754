import React, { useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { updatePayment } from '@services/checkout'
import { setCheckoutStep, setOrder } from '@redux/modules/checkout'
import { setOrderInfo } from '@helpers/checkout/global'
import ConfirmationModal from '@shared/confirmation-modal'
import { PAYMENT_TYPES } from '@components/checkout/checkout-parts/payment-section/payment-section'
import { useDispatch } from 'react-redux'
import { bool, func, object } from 'prop-types'
import styled from 'styled-components'
import loaderLight from '@images/loader-light.svg'

const LoadingSpinner = styled.img`
  height: 2rem;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    width: 33.75%;
  }
`
const Container = styled(Grid)`
  button {
    font-weight: bold;
    min-height: 50px;
    border-radius: 0;
    margin-top: 1rem;
  }
`
const TextWrapper = styled(Grid)`
  text-align: center;
  p:first-child {
    font-weight: bold;
    font-size: large;
  }
`
const BitPayModal = ({ closeModals, open, order }) => {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const handleContinue = () => {
    const paymentInfo = [
      {
        paymentType: PAYMENT_TYPES.BPAY,
        paymentProperties: {},
      },
    ]
    setLoading(true)
    updatePayment({
      paymentInfo,
      orderId: order.orderId,
    })
      .then(finalOrder => {
        dispatch(setOrder(finalOrder))
      })
      .finally(() => {
        setOrderInfo('bitpay', 'selectedPaymentType')
        dispatch(setCheckoutStep('review'))
        setLoading(false)
      })
  }
  return (
    <ConfirmationModal closeModal={closeModals} shouldShowModal={open} label="  ">
      <Container container spacing={2}>
        <TextWrapper item xs={12}>
          <p>Your order will be submitted to BitPay securely upon completing Step 4.</p>
          <p>Click CONTINUE to submit your order to BitPay or CANCEL to select another payment type.</p>
        </TextWrapper>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            type="button"
            fullWidth
            disabled={isLoading}
            disableElevation
            onClick={handleContinue}
          >
            {isLoading && <LoadingSpinner alt="Placing order" src={loaderLight} />}
            {!isLoading && <>CONTINUE</>}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="default"
            type="button"
            fullWidth
            disableElevation
            onClick={closeModals}
            style={{
              borderWidth: 2,
              borderColor: 'black',
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Container>
    </ConfirmationModal>
  )
}
BitPayModal.propTypes = {
  order: object,
  closeModals: func,
  open: bool,
}
export default BitPayModal

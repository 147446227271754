import React from 'react'
import { objectOf, any } from 'prop-types'
import { TextField } from '@material-ui/core'
import { setDeliveryInfo } from '@helpers/checkout/delivery-section'
import styled from 'styled-components'
import { colors, breakPoints } from '@constants/styles'
import generateTestId from '@hooks/generateTestId'

const Input = styled(TextField)`
  &&&& {
    input {
      border: 1px solid #707070;
      border-radius: 2px;
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  color: ${colors.darkerGrey};
  letter-spacing: 0;
`

const Label = styled.label`
  color: #585860;
  font-size: 12px;
  font-weight: 300;
`

const CharacterLimit = styled.p`
  color: ${colors.darkerGrey};
  font-size: 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const InputFooter = styled(Row)`
  margin: 10px 0 5px 0;
  justify-content: space-between;
`

const BulletList = styled.ul`
  font-size: 15px;
  margin-top: 15px;
  li {
    line-height: 1.5;
    list-style-type: initial;
  }
  @media screen and (max-width: ${breakPoints['large-max']}) {
    li {
      font-size: 12px;
      line-height: 17px;
    }
  }
`

const DeliverySectionPickup = ({ order, charLimit = 100 }) => {
  const getTestID = generateTestId()
  return (
    <>
      {!order.isPickup && (
        <>
          <Input
            aria-describedby="typeCountLimit"
            id="howToDeliver"
            name="tell us how to deliver"
            onChange={e => setDeliveryInfo(e.target.value, 'additionalDirections')}
            placeholder="Instructions for Delivery"
            type="text"
            data-testid="howToDeliver"
            value={order.additionalDirections}
            variant="outlined"
            style={{ marginTop: '15px' }}
            inputProps={{
              disableUnderline: true,
              maxLength: charLimit,
              'data-testid': getTestID('checkuot', 'How To Deliver Input'),
            }}
          />
          <InputFooter>
            <Label htmlFor="howToDeliver">Help us find your home (e.g. directions, gate code, etc.)</Label>
            <CharacterLimit id="typeCountLimit">
              {order.additionalDirections.length}/{charLimit} <span className="hide508">Characters Remaining</span>
            </CharacterLimit>
          </InputFooter>
        </>
      )}
      {order.isPickup && (
        <>
          <BulletList>
            <li>Photo ID matching the billing name on the order required at time of pick up.</li>
            <li>Customer is responsible for transportation and assembly of merchandise.</li>
            <li>Furniture may only be picked up on the scheduled date.</li>
          </BulletList>
        </>
      )}
    </>
  )
}

DeliverySectionPickup.propTypes = {
  order: objectOf(any),
  charLimit: any,
}

export default DeliverySectionPickup

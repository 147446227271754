import React from 'react'
import PropTypes from 'prop-types'
import { PaymentType, ReviewType, ShippingOrDeliveryType, BitpayPayment } from './types'

const CheckoutContentType = props => {
  const { checkoutStep, nextSection, order, onSetCheckoutStep, loading } = props
  if (checkoutStep === 'review' && order?.selectedPaymentType === 'bitpay') {
    return <BitpayPayment nextSection={nextSection} order={order} checkoutStep={checkoutStep} />
  }
  return (
    <>
      {checkoutStep !== 'review' && (
        <ShippingOrDeliveryType order={order} nextSection={nextSection} checkoutStep={checkoutStep} loading={loading} />
      )}
      {checkoutStep === 'review' && (
        <ReviewType nextSection={nextSection} order={order} checkoutStep={checkoutStep} loading={loading} />
      )}
      {checkoutStep === 'payment' && <PaymentType order={order} onSetCheckoutStep={onSetCheckoutStep} />}
    </>
  )
}

CheckoutContentType.propTypes = {
  checkoutStep: PropTypes.string,
  loading: PropTypes.any,
  nextSection: PropTypes.any,
  onSetCheckoutStep: PropTypes.any,
  order: PropTypes.any,
}

export default CheckoutContentType

import React from 'react'
import styled from 'styled-components'
import { objectOf, any, number, bool, string } from 'prop-types'
import { decodeHtml } from '@helpers/string-helper'
import { productUrl } from '@helpers/route'
import RTGLink from '../../../shared/link'

const ImageWrapper = styled.img`
  width: 71px;
  height: 63px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.p`
  font-size: 15px;
  font-weight: 700;
`

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 354px;
`

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 40px;
  width: 210px;
`

const QuantityText = styled.p`
  text-transform: uppercase;
  font-size: 12px;
`

const VendorProduct = ({ product, quantity, requiredAddon, warrantyEnabled, isStoreSku }) => {
  let route = null
  if (product && product.route) route = product.route
  return (
    <ProductWrapper key={product.sku}>
      {product?.primary_image ? (
        <RowWrapper>
          <RTGLink
            data={{
              slug: productUrl(route),
              altDesc: product.title ? product.title : '',
            }}
            aria-hidden="true"
            tabIndex="-1"
            disabled={requiredAddon || isStoreSku}
          >
            <ImageWrapper
              src={`${product.primary_image}${product.sku !== '83333333' ? '&h=150' : ''}`}
              alt=""
              role="presentation"
              aria-hidden="true"
            />
          </RTGLink>
          <DetailWrapper>
            <Title>
              {product.title ? decodeHtml(product.title) : `SKU: ${product.sku}`}{' '}
              {warrantyEnabled && <span className="warranty-label">+Furniture Protection Plan</span>}
            </Title>
            <QuantityText>QTY: {` ${quantity}`}</QuantityText>
          </DetailWrapper>
        </RowWrapper>
      ) : (
        <RowWrapper>
          <RTGLink
            data={{
              slug: productUrl(route),
              altDesc: product.title ? product.title : '',
            }}
            disabled={requiredAddon || isStoreSku}
          >
            <DetailWrapper>
              <Title>
                {product.title ? decodeHtml(product.title) : `SKU: ${product.sku}`}{' '}
                {warrantyEnabled && <span className="warranty-label">+Furniture Protection Plan</span>}
              </Title>
              <QuantityText>QTY: {` ${quantity}`}</QuantityText>
            </DetailWrapper>
          </RTGLink>
        </RowWrapper>
      )}
    </ProductWrapper>
  )
}

VendorProduct.propTypes = {
  product: objectOf(any),
  quantity: number,
  requiredAddon: bool,
  warrantyEnabled: bool,
  isStoreSku: string,
}

export default VendorProduct

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setShippingInvalidFields } from '@redux/modules/checkout'
import ShippingSection from './shipping-section'

const ShippingSectionWrapper = ({ order, checkoutStep, shippingInvalidFields, clearInvalidFields }) => (
  <ShippingSection
    order={order}
    checkoutStep={checkoutStep}
    invalidFields={shippingInvalidFields}
    clearInvalidFields={clearInvalidFields}
  />
)

const mapStateToProps = state => ({ ...state.checkout })
const mapDispatchToProps = dispatch => ({
  clearInvalidFields: () => dispatch(setShippingInvalidFields([])),
})

ShippingSectionWrapper.propTypes = {
  checkoutStep: PropTypes.string,
  clearInvalidFields: PropTypes.func,
  order: PropTypes.object,
  shippingInvalidFields: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingSectionWrapper)

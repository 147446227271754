import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import { abbreviateState } from '@helpers/geo-location'
import {
  getActualPaymentType,
  getPaymentCondensedAddressInfo,
  canShowBilling,
  getCreditCardType,
} from '@helpers/checkout/payment-section/payment-section'
import GenesisLogo from '@shared/svgs/genesis-logo'
import { getPayPalEmail } from '@helpers/checkout/payment-section/paypal'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import MaskedCredit from '../../masked-credit'

const StyledPaymentCondensed = styled.div`
  text-align: left;
  margin-left: 1em;
  h3 {
    font-weight: 600;
    text-align: left;
  }
  .billing-condensed {
    margin-bottom: 1rem;
    .left-info {
      float: left;
      margin-right: 1em;
      text-transform: capitalize;
    }
    .right-info {
      .affirm-logo {
        margin-top: 0.5rem;
        margin-left: -1rem;
        height: 1.75rem;
      }
      .cc-img {
        height: 3rem;
      }
    }
    .apple-pay-button {
      width: auto;
      height: 1.75em;
    }
  }
  .giftcard {
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 6rem;
      li {
        padding-right: 0.25rem;
        font-size: ${fonts.txtSmall};
        @media only screen and (min-width: ${breakPoints['large-min']}) {
          font-size: ${fonts.txtMedium};
        }
      }
    }
    button {
      font-weight: 600;
      color: ${colors.primary};
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const SideBySideFlex = styled.div`
  display: flex;
`

const PaymentCondensed = ({ order }) => {
  const { payer, shippingAddress, paymentInfo } = order
  const shippingInfoArr = shippingAddress.addressLookup.split(',')
  const cardType = getCreditCardType(order)
  const showBilling = canShowBilling(order)
  const discoverCardsType = cType => cType === '004' || cType === '042'
  const financePlan = paymentInfo.filter(
    payment => payment.paymentType === 'FIN' || payment.paymentType === 'GEN' || payment.paymentType === 'DBUY',
  )[0]
  let lastFour = ''
  if (financePlan && financePlan.paymentProperties && financePlan.paymentProperties.accountNumber) {
    lastFour = financePlan.paymentProperties.accountNumber.slice(12)
  }
  return (
    <>
      <StyledPaymentCondensed className="payment-condensed grid-x" data-testid="payment-condensed">
        {paymentInfo.map(payment => {
          if (!showBilling) {
            return null
          }
          const actualPaymentType = getActualPaymentType(payment)
          const addressInfo = getPaymentCondensedAddressInfo(order, actualPaymentType)
          if (payment.paymentType !== 'GIFT') {
            return (
              <div className="billing-condensed small-12 large-6" key={payment.paymentType}>
                <h3>Billing Information</h3>
                <SideBySideFlex>
                  <div className="left-info">
                    {actualPaymentType !== 'Affirm' && `${addressInfo.firstName} ${addressInfo.lastName}`}
                    <br />
                    {actualPaymentType !== 'Visa Checkout' &&
                      actualPaymentType !== 'Affirm' &&
                      actualPaymentType !== 'Rooms To Go Finance' && (
                        <>
                          {shippingAddress.addressLookup &&
                            shippingAddress.addressLookupSuccess &&
                            !payer.billingDifferent &&
                            actualPaymentType !== 'PayPal' &&
                            actualPaymentType !== 'Apple Pay' &&
                            actualPaymentType !== 'Digital Buy' && (
                              <>
                                {shippingInfoArr[0]}
                                <br />
                                {shippingInfoArr[1]}
                              </>
                            )}
                          {(!shippingAddress.addressLookupSuccess ||
                            actualPaymentType === 'PayPal' ||
                            actualPaymentType === 'Apple Pay' ||
                            actualPaymentType === 'Digital Buy' ||
                            payer.billingDifferent) && (
                            <>
                              {addressInfo.address1}
                              {addressInfo.address2 &&
                                addressInfo.address2 !== '' &&
                                addressInfo.address2 !== 'null' &&
                                addressInfo.address2 !== 'undefined' &&
                                ` ${addressInfo.address2}`}
                              <br />
                              {`${addressInfo.city} `}
                              {`${abbreviateState(addressInfo.state)} `}
                              {addressInfo.zip}
                            </>
                          )}
                        </>
                      )}
                    {(actualPaymentType === 'Visa Checkout' || actualPaymentType === 'Rooms To Go Finance') &&
                      addressInfo.zip}
                  </div>
                  {actualPaymentType && (
                    <>
                      {actualPaymentType === 'Digital Buy' && (
                        <div className="right-info">
                          <PaymentSvg uniqueNameForId="paymentRtg" cards={['rtg']} width={60} cordY="0" />
                        </div>
                      )}
                      {actualPaymentType === 'Apple Pay' && (
                        <div className="right-info">
                          <div className="apple-pay-button apple-pay-button-black">
                            <span className="text">Checkout with</span>
                            <span className="logo" />
                          </div>
                          <br />
                        </div>
                      )}
                      {actualPaymentType === 'PayPal' && (
                        <div className="right-info">
                          <img
                            src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
                            alt="PayPal Logo"
                          />
                          <br />
                          {getPayPalEmail(order)}
                        </div>
                      )}
                      {actualPaymentType === 'Visa Checkout' && (
                        <div className="right-info">
                          <img
                            alt="Visa Checkout"
                            src="https://assets.secure.checkout.visa.com/VCO/images/acc_99x34_blu01.png"
                          />
                        </div>
                      )}
                      {actualPaymentType === 'Affirm' && (
                        <div className="right-info">
                          <PaymentSvg uniqueNameForId="paymentAffirm" cards={['affirm']} width={60} cordY="0" />
                        </div>
                      )}
                      {actualPaymentType === 'bitpay' && (
                        <div className="right-info">
                          <PaymentSvg uniqueNameForId="paymentBitpay" cards={['bitpay']} width={90} cordY="0" />
                        </div>
                      )}
                      {actualPaymentType === 'Rooms To Go Finance' && (
                        <div className="right-info">
                          <PaymentSvg uniqueNameForId="paymentRtg2" cards={['rtg']} width={60} cordY="0" />
                        </div>
                      )}
                      {actualPaymentType === 'Klarna' && (
                        <div className="right-info">
                          <img
                            src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
                            width="100%"
                            alt="Klarna payment option"
                            style={{ backgroundColor: '#ffb3c7', borderRadius: '5px', maxHeight: '55px' }}
                          />
                        </div>
                      )}
                      {actualPaymentType === 'Genesis' && (
                        <div className="right-info">
                          <GenesisLogo />
                          <p>
                            <MaskedCredit lastFour={lastFour} />
                          </p>
                          <p>${financePlan.authorizedAmount}</p>
                        </div>
                      )}
                      {actualPaymentType === 'Credit' && (
                        <>
                          {cardType === '001' && (
                            <div className="right-info">
                              <PaymentSvg uniqueNameForId="paymentVisa" cards={['visa']} width={60} cordY="0" />
                            </div>
                          )}
                          {cardType === '002' && (
                            <div className="right-info">
                              <PaymentSvg uniqueNameForId="paymentMc" cards={['mastercard']} width={60} cordY="0" />
                            </div>
                          )}
                          {cardType === '003' && (
                            <div className="right-info">
                              <PaymentSvg uniqueNameForId="paymentAmex" cards={['amex']} width={60} cordY="0" />
                            </div>
                          )}
                          {discoverCardsType(cardType) && (
                            <div className="right-info">
                              <PaymentSvg uniqueNameForId="paymentDiscover" cards={['discover']} width={60} cordY="0" />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </SideBySideFlex>
              </div>
            )
          }
          return null
        })}
      </StyledPaymentCondensed>
    </>
  )
}

PaymentCondensed.propTypes = {
  order: PropTypes.object,
}
export default PaymentCondensed

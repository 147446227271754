import React from 'react'
import { string, any, arrayOf, object, shape, instanceOf } from 'prop-types'
import PlanDetails from './plan-details'

const FinancePlan = ({ cardProvider, plans, financePlanRef, financePlan2Ref }) => {
  const genesisPlan = plans.filter(plan => plan?.financeCode === 'GENESIS')[0]
  const synchronyPlans = plans.filter(plan => plan?.financeCode !== 'GENESIS')

  return (
    <section>
      {cardProvider === 'Synchrony'
        ? synchronyPlans &&
          synchronyPlans.map((plan, index) => {
            if (index > 1) return null
            let planRef = null
            if (index === 0) {
              planRef = financePlanRef
            }
            if (index === 1) {
              planRef = financePlan2Ref
            }
            return (
              plan?.financeCode && (
                <PlanDetails key={plan.financeCode} plan={plan} cardProvider={cardProvider} financePlanRef={planRef} />
              )
            )
          })
        : genesisPlan && <PlanDetails plan={genesisPlan} cardProvider={cardProvider} />}
    </section>
  )
}
FinancePlan.propTypes = {
  cardProvider: string,
  plans: arrayOf(object).isRequired,
  financePlanRef: shape({
    current: instanceOf(any),
  }).isRequired,
  financePlan2Ref: shape({
    current: instanceOf(any),
  }).isRequired,
}

export default FinancePlan

import React from 'react'
import { objectOf, any, string, bool } from 'prop-types'
import { abbreviatedMonths, months, weekdays } from '@helpers/string-helper'
import { splitDates } from '@helpers/checkout/delivery-section'
import styled from 'styled-components'
import { breakPoints, colors } from '@constants/styles'
import { getRegionZone } from '@helpers/geo-location'
import BoltIcon from '@mui/icons-material/Bolt'
import CalendarPopup from '@components/checkout/checkout-parts/delivery-section/delivery-section-calendar-popup'
import CalendarPopupSplit from '@components/checkout/checkout-parts/delivery-section/delivery-section-calendar-popup-split'
import DistributionCenterPopup from './distribution-center-popup'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Bold = styled.b`
  font-weight: bold;
`

const Title = styled.p`
  font-size: 18px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: 15px;
  }
`

const BlockColumn = styled(Column)`
  align-items: center;
`

const DateBlock = styled.div`
  align-items: center;
  background-color: ${colors.primary};
  display: flex;
  flex-direction: column;
  height: 55px;
  justify-content: center;
  margin: 0 10px 10px 0;
  width: 55px;
  p {
    color: ${colors.white};
    font-size: 20px;
  }
`

const BlockConnector = styled.div`
  background-color: ${colors.primary};
  height: 10px;
  margin: -10px 10px 0 0;
  width: 5px;
`

const BlockMonth = styled.p`
  line-height: 24px;
`

const BlockDay = styled.p`
  font-weight: bold;
  line-height: 24px;
`
const NotesWrapper = styled.ul`
  padding: 0 10px 0 20px;
  list-style: disc;
`

const Note = styled.li`
  font-size: 15px;
  margin: 7px 0;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: 12px;
    margin: 0;
  }
`

const ItalicNote = styled(Note)`
  font-style: italic;
  line-height: 20px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: 12px;
  }

  button {
    font-size: 15px;
    color: ${colors.primary};
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
  }
`

const formatDate = date =>
  `${weekdays?.[date.getDay()]}, ${months[date.getMonth()]} ${date
    .getDate()
    .toString()
    .padStart(2, '0')}, ${date.getFullYear()}`

export const DeliverySectionShippingType = ({
  order,
  fullDeliveryDate,
  isExpress,
  myRef,
  isSplit = false,
  shipmentsData,
  shipment,
  index,
}) => {
  const expressDelivery = isExpress !== undefined ? isExpress : order.isExpress
  const possibleDates = order?.defaultDelivery ? splitDates(order.deliveryDate) : '2019-11-21'
  const regionInPR = getRegionZone().region === 'PR'
  const isDoorwayDelivery = regionInPR ? false : order.doorwayDelivery

  const DefaultDelivery = () => (
    <Row>
      <BlockColumn>
        <DateBlock>
          <BlockMonth>{abbreviatedMonths[possibleDates.deliverDate.getMonth()]}</BlockMonth>
          <BlockDay>
            {possibleDates.deliverDate
              .getDate()
              .toString()
              .padStart(2, '0')}
          </BlockDay>
        </DateBlock>
        <BlockConnector />
        <DateBlock>
          <BlockMonth>{abbreviatedMonths[possibleDates.fortNight.getMonth()]}</BlockMonth>
          <BlockDay>
            {possibleDates.fortNight
              .getDate()
              .toString()
              .padStart(2, '0')}
          </BlockDay>
        </DateBlock>
      </BlockColumn>
      <Column>
        <Title>
          Between <Bold>{formatDate(possibleDates.deliverDate)}</Bold> and{' '}
          <Bold>{formatDate(possibleDates.fortNight)}</Bold>
        </Title>
        {!isDoorwayDelivery && (
          <Note data-testid="default-delivery">
            We will contact you to confirm actual delivery date and charge once your order is fully processed.
          </Note>
        )}
      </Column>
    </Row>
  )

  const PickupOrder = () => (
    <Row>
      <BlockColumn>
        <DateBlock>
          <BlockMonth>{abbreviatedMonths[new Date(fullDeliveryDate).getMonth()]}</BlockMonth>
          <BlockDay>
            {new Date(fullDeliveryDate)
              .getDate()
              .toString()
              .padStart(2, '0')}
          </BlockDay>
        </DateBlock>
      </BlockColumn>
      <Column>
        <Title>
          Pick-up from <DistributionCenterPopup /> {'on: '}
          <Bold data-testid="pickup-delivery">{fullDeliveryDate}</Bold>
        </Title>
      </Column>
    </Row>
  )

  const NonDefaultDelivery = () => {
    let message = expressDelivery
      ? 'You will be notified of the delivery window prior to delivery.'
      : 'You will be notified of the estimated delivery window 2 days prior to delivery.'
    const showDefaultMessage = !order.calendarType || (order.calendarType && order.calendarType !== 'weekly')
    if (isDoorwayDelivery) {
      message = 'Placed inside the entryway or garage. Does not include professional assembly or removal of packaging.'
    } else if (!showDefaultMessage) {
      message = 'Leaving our warehouse on: '
    }
    return (
      <Row>
        <BlockColumn>
          <DateBlock>
            <BlockMonth>{abbreviatedMonths[new Date(fullDeliveryDate).getMonth()]}</BlockMonth>
            <BlockDay>
              {new Date(fullDeliveryDate)
                .getDate()
                .toString()
                .padStart(2, '0')}
            </BlockDay>
          </DateBlock>
        </BlockColumn>
        <Column>
          <Title>
            {expressDelivery && <BoltIcon color="success" style={{ verticalAlign: 'text-bottom' }} />}
            <Bold>
              {isSplit && (
                <CalendarPopupSplit
                  order={order}
                  myRef={myRef}
                  initialDate={shipment.deliveryDate}
                  showAsLink
                  bold
                  shipmentsData={shipmentsData}
                  shipment={shipment}
                  index={index}
                  text={fullDeliveryDate}
                  testId="select-delivery-date-shipping-type-split"
                />
              )}
              {!isSplit && (
                <CalendarPopup
                  order={order}
                  myRef={myRef}
                  initialDate={order.deliveryDate}
                  showAsLink
                  bold
                  text={fullDeliveryDate}
                  testId="select-delivery-date-shipping-type"
                />
              )}
            </Bold>
          </Title>
          {!isDoorwayDelivery && (
            <NotesWrapper>
              <Note data-testid="non-default-delivery">4 hour delivery window.</Note>
              <Note>{message}</Note>
              {expressDelivery && <Note>May increase delivery charge.</Note>}
              <ItalicNote>
                {!expressDelivery && <span>Need it sooner? </span>}
                Click{' '}
                {isSplit && (
                  <CalendarPopupSplit
                    order={order}
                    myRef={myRef}
                    initialDate={shipment.deliveryDate}
                    showAsLink
                    shipmentsData={shipmentsData}
                    shipment={shipment}
                    index={index}
                    testId="select-delivery-date-notes-split"
                  />
                )}
                {!isSplit && (
                  <CalendarPopup
                    order={order}
                    myRef={myRef}
                    initialDate={order.deliveryDate}
                    showAsLink
                    testId="select-delivery-date-notes"
                  />
                )}{' '}
                to see other available delivery dates.
              </ItalicNote>
            </NotesWrapper>
          )}
          {isDoorwayDelivery && (
            <NotesWrapper>
              <Note data-testid="non-default-delivery">4 hour delivery window.</Note>
              <Note>{message}</Note>
              <ItalicNote>
                {!expressDelivery && <span>Need it sooner? </span>}
                Click <CalendarPopup order={order} myRef={myRef} initialDate={order.deliveryDate} showAsLink /> to see
                other available delivery dates.
              </ItalicNote>
            </NotesWrapper>
          )}
        </Column>
      </Row>
    )
  }

  return (
    <>
      {order?.defaultDelivery && <DefaultDelivery />}
      {!order?.defaultDelivery && !order?.isPickup && <NonDefaultDelivery />}
      {!order?.defaultDelivery && order?.isPickup && <PickupOrder />}
    </>
  )
}

DeliverySectionShippingType.propTypes = {
  order: objectOf(any),
  fullDeliveryDate: string,
  isExpress: bool,
  isSplit: bool,
  myRef: any,
  shipmentsData: any,
  shipment: any,
  index: any,
}

export default DeliverySectionShippingType

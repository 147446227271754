import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { breakPoints, colors, fonts } from '@constants/styles'
import { onApplyGiftCard, setOrderGiftCardInfo } from '@helpers/checkout/payment-section/gift-cards'
import loaderLight from '../../../../../assets/images/loader-light.svg'

const StyledGiftCardInputs = styled.div`
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  @media only screen and (min-width: ${breakPoints.medium}) {
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
  p {
    font-size: ${fonts.txtXsmall};
  }
  .gift-card-number {
    width: 60%;
    @media only screen and (max-width: ${breakPoints['large-max']}) {
      width: 100%;
    }
  }
  .gift-card-pin {
    width: 60%;
    @media only screen and (max-width: ${breakPoints['large-max']}) {
      width: 100%;
    }
  }
  input[type='text'] {
    line-height: 20px;
    padding: 10px;
    @media only screen and (max-width: ${breakPoints['large-max']}) {
      width: 100% !important;
    }
  }
  .apply-gift-card-btn {
    background-color: ${colors.primary};
    color: ${colors.primaryInvert};
    border-radius: 0px;
    height: 2.5em;
    text-align: center;
    box-sizing: border-box;
    font-size: ${fonts.txtMedium};
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    top: 1em;
    min-width: 12.35em;
    @media only screen and (max-width: ${breakPoints['large-max']}) {
      top: 0.5rem;
      width: 100% !important;
    }
    &.cancel {
      width: 10%;
      margin: 0.25em 5%;
      min-width: 6.35em;
      @media only screen and (max-width: ${breakPoints['large-max']}) {
        margin: 0.25em 0;
      }
    }
    .loader {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  .error {
    color: ${colors.red};
    margin-bottom: 1rem;
    font-size: ${fonts.txtSmall};
  }
`

const GiftCardInputs = ({ unableToAddMessage, order, invalidFields, loading, setGiftCardState }) => (
  <StyledGiftCardInputs className="grid-x">
    <div className="small-12">
      <label className="" htmlFor="giftCardNumber">
        {unableToAddMessage !== '' && <p className="error">*{unableToAddMessage}</p>}
        <p>Gift Card Number *</p>
        <input
          type="text"
          className={classNames('gift-card-number', {
            invalid: invalidFields.includes('giftCardNumber'),
          })}
          name="Gift Card Number"
          aria-label="Gift Card Number"
          onChange={e => setOrderGiftCardInfo(e.target.value, 'giftCardNumber')}
        />
      </label>
      <br />
      <label className="" htmlFor="giftCardPin">
        <input
          type="text"
          className={classNames('gift-card-pin', {
            invalid: invalidFields.includes('giftCardPin'),
          })}
          placeholder="Gift Card Pin *"
          name="Pin"
          aria-label="Pin"
          onChange={e => setOrderGiftCardInfo(e.target.value, 'giftCardPin')}
        />
      </label>
      <br />
      <button
        type="button"
        className="apply-gift-card-btn small-12 medium-3"
        tabIndex="0"
        value="Apply Gift Card"
        aria-label="Apply Gift Card"
        onClick={() => onApplyGiftCard(order, setGiftCardState)}
      >
        {!loading ? 'apply gift card' : <img className="loader" alt="Removing gift card" src={loaderLight} />}
      </button>
      {order.giftCardInfo.addAnotherCard && (
        <button
          type="button"
          className="apply-gift-card-btn cancel small-12 medium-1"
          tabIndex="0"
          value="Cancel"
          aria-label="Cancel"
          onClick={() => setOrderGiftCardInfo(false, 'addAnotherCard')}
        >
          Cancel
        </button>
      )}
    </div>
  </StyledGiftCardInputs>
)

GiftCardInputs.propTypes = {
  invalidFields: PropTypes.any,
  loading: PropTypes.any,
  order: PropTypes.any,
  setGiftCardState: PropTypes.any,
  unableToAddMessage: PropTypes.string,
}

export default GiftCardInputs

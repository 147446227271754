import React from 'react'
import { arrayOf, string, boolean } from 'prop-types'
import useOneTimeChangeBool from '@hooks/useOneTimeChangeBool'

const AcceptanceErrorMessage = ({ invalidFields, acceptanceFieldChecked, message }) => {
  const isSubmissionTriedAndInvalid = invalidFields && invalidFields.length > 0 && !invalidFields.includes('order')
  const shownFirstTime = useOneTimeChangeBool(isSubmissionTriedAndInvalid)

  return (
    <>
      {((shownFirstTime && !acceptanceFieldChecked) || (!shownFirstTime && isSubmissionTriedAndInvalid)) && (
        <p className="invalid">{message}</p>
      )}
    </>
  )
}

AcceptanceErrorMessage.propTypes = {
  invalidFields: arrayOf(string),
  acceptanceFieldChecked: boolean,
  message: string,
}

export default AcceptanceErrorMessage

import React from 'react'
import { objectOf, any, string, shape, instanceOf } from 'prop-types'
import styled from 'styled-components'
import { colors } from '@constants/styles'
import SynchronyFinancePlan from './synchrony-finance-plan'
import { setFinancePlan } from './helpers'

const DetailsWrapper = styled.section`
  margin: 0 0 10px 0;
  display: flex;
  > input {
    margin-left: 0;
  }
  > label {
    margin-left: 5px;
    width: 100%;
  }
`

const PlanDetails = ({ plan = {}, cardProvider = '', financePlanRef }) => (
  <DetailsWrapper>
    {cardProvider && cardProvider === 'Synchrony' ? (
      <SynchronyFinancePlan plan={plan} setFinancePlan={setFinancePlan} financePlanRef={financePlanRef} />
    ) : null}
  </DetailsWrapper>
)

PlanDetails.propTypes = {
  plan: objectOf(any),
  cardProvider: string,
  financePlanRef: shape({
    current: instanceOf(any),
  }),
}

export default PlanDetails

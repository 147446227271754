import React, { useRef } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { setOrderInfo } from '@helpers/checkout/global'
import { applePayEnabled, applePaySupported } from '@helpers/checkout/payment-section/apple-pay'
import { object } from 'prop-types'
import ApplePay from './apple-pay'

const Label = styled.span`
  font-size: 14px;
  margin-left: 8px;
  display: inline-block;
  text-transform: capitalize;
`

const Container = styled(Button)`
  border-top: 1px solid #dedede;
  padding-top: 6px;
  justify-content: flex-start;
`

const Left = styled(Grid)`
  width: 175px;
  height: 55px;
  margin-left: -5px;
  z-index: 1;
`

const ApplePayPaymentOption = ({ order }) => {
  const applePayRef = useRef(null)
  const showApplePay =
    ((order?.paymentInfo && !order.paymentInfo.length) ||
      order?.paymentInfo?.filter(({ paymentType }) => paymentType === 'APP').length) &&
    applePaySupported() &&
    applePayEnabled()
  return (
    <Container
      onClick={() => applePayRef?.current?.handleClick?.()}
      variant="text"
      color="primary"
      fullWidth
      hidden={!showApplePay}
      style={{ padding: '0 8px', minHeight: '70px' }}
    >
      <Left item>
        <ApplePay
          ref={applePayRef}
          type="Plain"
          isCheckout
          setOrderInfo={() => setOrderInfo('Apple Pay', 'selectedPaymentType')}
          style={{ height: '55px' }}
        />
      </Left>
      <Label>Apple Pay</Label>
    </Container>
  )
}

ApplePayPaymentOption.propTypes = {
  order: object,
}

export default ApplePayPaymentOption

// dependencies
import React from 'react'
import propTypes, { any } from 'prop-types'
import styled from 'styled-components'
import _replace from 'lodash/replace'
import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Avatar } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

// assets
import PaymentSvg from '@shared/svgs/paymentSvgComp'

// helpers
import { currencyFormatUS } from '@helpers/string-helper'

// components
import { PAYMENT_TYPES, PAYMENT_CHECKOUT_LABELS, PAYMENT_CYBERV3_LABELS, PAYMENT_LOGOS } from './payment-section'

const PaymentAppliedItem = ({ payment, order, key, cardInfoRef, setPaymentRemoveConfirmationModalVisible }) => {
  const PaymentAppliedIcon = styled(Avatar)`
    height: 25px;
    background-color: transparent;
  `

  const getPaymentTypeLabel = () => {
    if (payment.paymentType === PAYMENT_TYPES.CYBERV3) {
      return PAYMENT_CYBERV3_LABELS[payment?.paymentProperties?.card?.cardName]
    }
    return (
      _replace(
        PAYMENT_CHECKOUT_LABELS[payment.paymentType],
        '{{account_number}}',
        payment.paymentProperties?.cardNumber?.substr(-4) || payment?.paymentProperties?.accountNumber?.substr(-4), // add for DBUY
      ) || 'Credit Card'
    )
  }

  if (order.giftCardInfo?.useGiftCard === false && payment.paymentType === PAYMENT_TYPES.GIFT) {
    return null
  }

  const label = `${getPaymentTypeLabel()} ${currencyFormatUS(payment.authorizedAmount?.toFixed(2))}`
  const logo = PAYMENT_LOGOS[payment.paymentType] || payment?.paymentProperties?.card?.cardName

  return (
    <ListItem disableGutters>
      <ListItemAvatar>
        <PaymentAppliedIcon variant="square">
          <PaymentSvg
            cards={[logo]}
            cordY="0"
            vpHeight="50"
            width="50px"
            renderGiftCard={payment.paymentType === PAYMENT_TYPES.GIFT}
            uniqueNameForId={key}
          />
        </PaymentAppliedIcon>
      </ListItemAvatar>
      <ListItemText
        primary={label}
        secondary={payment.paymentType === PAYMENT_TYPES.DBUY ? payment.paymentProperties.financePlan : false}
      />
      <ListItemSecondaryAction>
        <IconButton
          color="primary"
          aria-label="delete"
          onClick={() => {
            cardInfoRef.current = { ...payment }
            setPaymentRemoveConfirmationModalVisible(true)
          }}
        >
          <CancelIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

PaymentAppliedItem.propTypes = {
  payment: propTypes.object,
  order: propTypes.object,
  key: propTypes.number,
  cardInfoRef: any,
  setPaymentRemoveConfirmationModalVisible: propTypes.func,
}

export default PaymentAppliedItem

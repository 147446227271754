import React from 'react'
import styled from 'styled-components'
import { array, any, bool, arrayOf, object, objectOf, string } from 'prop-types'
import { breakPoints } from '@constants/styles'
import { months } from '@helpers/string-helper'
import { shouldShowAdditionalInstructions } from '@helpers/delivery'
import { splitDates } from '@helpers/checkout/delivery-section'
import SplitDeliverySummary from '@shared/split-delivery-summary'
import DeliveryProduct from '../delivery-product'
import DistributionCenterPopup from '../distribution-center-popup'
import DeliveryTypeSection from './delivery-type-section'
import { DeliveryCondensedWrapper } from '../styles'

const DeliverDateText = styled.span`
  @media screen and (max-width: ${breakPoints['large-max']}) {
    display: block;
    margin: 0.3rem 0;
  }
`

const DeliveryCondensed = ({
  deliveryDate,
  order,
  rtgDeliveryItems,
  vendorDeliveryItems,
  upsDeliveryItems,
  uspsDeliveryItems,
  cart,
  storeCartLineItems,
}) => {
  const onlineCartItems = cart && cart.cartItems && cart.cartItems.length > 0
  const anyCartItems = onlineCartItems || (storeCartLineItems && storeCartLineItems.length > 0)
  const doorwayDelivery = !order.isPickup && order.doorwayDelivery
  const normalDelivery = !order.isPickup && !order.doorwayDelivery
  const possibleDates = order && order.defaultDelivery ? splitDates(order.deliveryDate) : '2019-11-21'
  if (order?.splitDeliveryDates) {
    return <SplitDeliverySummary order={order} cart={cart} />
  }
  return (
    <DeliveryCondensedWrapper className="grid-x">
      {rtgDeliveryItems.length > 0 && (
        <div className="deliver-section small-12 large-5">
          <>
            {doorwayDelivery && <h3>Doorway Delivery</h3>}
            {normalDelivery && <h3>Premium Delivery from Rooms To Go</h3>}
            <h4 className="small-12">
              {!order.isPickup && (
                <>
                  <p className="bold">
                    *{`${order.isExpress ? 'EXPRESS: ' : ''}`}$
                    {doorwayDelivery ? order.doorwayDeliveryCharge : order.totalDeliveryCharge}
                  </p>

                  {order.defaultDelivery && (
                    <p inline>
                      <span>Estimated Delivery Date: </span>
                      <DeliverDateText>
                        {`${months[possibleDates.deliverDate.getMonth()]} ${possibleDates.deliverDate.getDate()}`} -{' '}
                        {`${months[possibleDates.fortNight.getMonth()]} ${possibleDates.fortNight.getDate()}`}
                      </DeliverDateText>
                    </p>
                  )}
                  {!order.defaultDelivery && (
                    <>
                      <p>estimated delivery: </p>
                      <p className="bold">{deliveryDate}</p>
                    </>
                  )}
                </>
              )}
              {order.isPickup && (
                <>
                  <div>
                    Pick-up from <DistributionCenterPopup /> on:
                  </div>
                  <p className="bold">{deliveryDate}</p>
                </>
              )}
            </h4>
            {anyCartItems &&
              rtgDeliveryItems.map((item, index) => {
                const itemInCart = cart.cartItems.filter(cartItem => cartItem.sku === item.sku)[0]
                return (
                  <DeliveryProduct
                    key={item.sku}
                    product={itemInCart ? itemInCart.product : item}
                    productCount={rtgDeliveryItems.length}
                    quantity={item.quantity}
                    index={index}
                    requiredAddon={item.required}
                    noImage
                    warrantyEnabled={item.warrantyEnabled}
                  />
                )
              })}
          </>
        </div>
      )}
      {vendorDeliveryItems.length > 0 && (
        <DeliveryTypeSection deliveryItems={vendorDeliveryItems} deliveryDate={deliveryDate} cart={cart} />
      )}
      {upsDeliveryItems.length > 0 && (
        <DeliveryTypeSection deliveryItems={upsDeliveryItems} deliveryDate={deliveryDate} cart={cart} ups />
      )}
      {uspsDeliveryItems.length > 0 && (
        <DeliveryTypeSection deliveryItems={uspsDeliveryItems} deliveryDate={deliveryDate} cart={cart} usps />
      )}
      {order && order.additionalDirections !== '' && shouldShowAdditionalInstructions(order) && (
        <div className="provided-instructions-container small-12">
          <p className="provided-instructions bold">Provided Instructions:</p>
          <p className="provided-instructions">{order.additionalDirections}</p>
        </div>
      )}
    </DeliveryCondensedWrapper>
  )
}

DeliveryCondensed.propTypes = {
  deliveryDate: string,
  order: objectOf(any),
  rtgDeliveryItems: arrayOf(object),
  vendorDeliveryItems: arrayOf(object),
  upsDeliveryItems: arrayOf(object),
  uspsDeliveryItems: arrayOf(object),
  isExpress: bool,
  cart: objectOf(any),
  storeCartLineItems: array,
}

export default DeliveryCondensed

import React from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import { colors } from '@constants/styles'

const StyledCloseButton = styled.button`
  background: ${colors.white};
  border-radius: 50%;
  height: 27px;
  width: 27px;
`

const DeliveryModalCloseIcon = ({ closeModal }) => (
  <StyledCloseButton
    aria-label="Close"
    className="close-modal"
    onClick={closeModal}
    onKeyDown={closeModal}
    tabIndex="0"
    type="button"
    value="Close"
  >
    <img
      alt="close icon"
      src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23003566' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
    />
  </StyledCloseButton>
)

DeliveryModalCloseIcon.propTypes = {
  closeModal: func.isRequired,
}

export default DeliveryModalCloseIcon

import React, { useState } from 'react'
import Modal from 'react-modal'
import { object, string, bool, func } from 'prop-types'
import { colors, breakPoints, fonts } from '@constants/styles'
import { getStore } from '@helpers/store-locator'
import { DoorwayDeliveryMessage, showDoorwayDeliveryOption } from '@helpers/delivery'
import RTGLink from '@shared/link'
import styled from 'styled-components'
import DoorwayDeliveryImage from '@assets/images/delivery-types/doorway_delivery.svg'
import ProfessionalDeliveryImage from '@assets/images/delivery-types/professional_delivery.svg'
import SelfPickupImage from '@assets/images/delivery-types/self_pickup.svg'
import { getRegionZone } from '@helpers/geo-location'
import DeliveryOption from './delivery-option'
import DeliveryModalCloseIcon from './delivery-modal-close-icon'
import Footer from './footer'
import { PROFESSIONAL_DELIVERY, PICK_UP, DOORWAY_DELIVERY } from './constants'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

export const StyledDeliveryTypeModal = styled(Modal)`
  color: ${colors.darkerGrey};
  &:focus {
    outline: none;
  }
`

const ModalHeader = styled.div`
  display: flex;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    flex-direction: row-reverse;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
    margin: 0 0 10px 0;
  }
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    flex-direction: row-reverse;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${fonts.txtLarge};
  color: ${colors.darkerGrey};
  font-weight: 700;
  width: 75%;
  text-align: center;
  margin: 0 auto;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: 18px;
    line-height: 16px;
    padding: 0 0 0 27px;
  }
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    padding: 10px 0 50px 0;
  }
`

const RadioContainer = styled.div`
  display: flex;
  background: ${colors.white};
  overflow: auto;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    flex-direction: column;
  }
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    flex-direction: row;
  }
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  position: fixed;
  right: auto;
  bottom: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  max-height: 95vh;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    min-width: 90vw;
    padding: 10px;
  }
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    min-width: 1024px;
    padding: 20px;
  }
`

const BulletList = styled.ul`
  font-size: 15px;
  li {
    line-height: 1.5;
    list-style-type: initial;
  }
  @media screen and (max-width: ${breakPoints['large-max']}) {
    li {
      font-size: 12px;
      line-height: 17px;
    }
  }
`

const ItalicNote = styled.p`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-top: 15px;
  margin-left: -15px;
`

const PickupName = styled.span`
  font-weight: 700;
`
const PickupAddress = styled.span`
  font-style: italic;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    a {
      font-size: 12px;
    }
  }
`

const StyledListItem = styled.li`
  color: #eb141f; // red
  font-size: 15px;
  font-weight: 700;
`

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 3px;
`

const regionInPR = getRegionZone().region === 'PR'

const DeliveryModal = ({ closeModal, deliveryZone, modalOpen, order, selectedDeliveryMode }) => {
  const store = getStore(order)
  const showDoorwayDelivery = showDoorwayDeliveryOption(deliveryZone, order.lineItems)
  const isExpressDelivery = order.isExpress
  const [deliveryType, setDeliveryType] = useState(selectedDeliveryMode)
  return (
    <StyledDeliveryTypeModal
      contentLabel="Change Delivery Type"
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          position: 'fixed',
          zIndex: '100',
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          backgroundColor: 'rgba(0,0,0,0.4)',
        },
        content: {
          maxHeight: '80vh',
        },
      }}
    >
      <ModalContent>
        <ModalHeader>
          <CloseButtonContainer>
            <DeliveryModalCloseIcon closeModal={closeModal} />
          </CloseButtonContainer>
          <Title>Select Delivery Type</Title>
        </ModalHeader>

        <RadioContainer>
          <DeliveryOption
            deliveryFee={`$${order?.tentativeDeliveryCharge}`}
            isChecked={deliveryType === PROFESSIONAL_DELIVERY}
            imgSrc={ProfessionalDeliveryImage}
            name={PROFESSIONAL_DELIVERY}
            setDeliveryType={setDeliveryType}
            title="Premium Delivery and Set-Up"
          >
            <BulletList>
              <li>Delivery to any room, professional assembly, and removal of packaging.</li>
              <li>4-hour delivery window.</li>
              <li>You will be notified of the estimated delivery window 2 days prior to delivery.</li>
            </BulletList>
          </DeliveryOption>
          {store && order?.pickupCalendar?.length > 0 && (
            <DeliveryOption
              deliveryFee="FREE"
              isChecked={deliveryType === PICK_UP}
              imgSrc={SelfPickupImage}
              name={PICK_UP}
              setDeliveryType={setDeliveryType}
              title="Self-Pickup"
            >
              <BulletList>
                <li>
                  Self pickup from <PickupName>{`${store?.city} Distribution Center: `}</PickupName>
                  <PickupAddress>
                    {store?.address1} {store?.address2 || ''}, {`${store?.city}, ${store?.state} ${store?.zip}`} <br />
                    <a href={`tel:${store?.phone?.replace(/\//g, '').replace(/-/g, '')}`}>{`${store?.phone?.replace(
                      '/',
                      '-',
                    )}`}</a>
                  </PickupAddress>
                </li>
                <li>Photo ID matching the billing name on the order required at time of pick up.</li>
                <li>You are responsible for transportation and assembly of merchandise.</li>
                <li>Furniture may only be picked up on the scheduled date.</li>
              </BulletList>
            </DeliveryOption>
          )}
          {showDoorwayDelivery && (
            <DeliveryOption
              deliveryFee={`$${order?.doorwayDeliveryCharge}`}
              isChecked={deliveryType === DOORWAY_DELIVERY}
              isDoorwayDelivery
              isExpressDelivery={isExpressDelivery}
              imgSrc={DoorwayDeliveryImage}
              name={DOORWAY_DELIVERY}
              setDeliveryType={setDeliveryType}
              title={DoorwayDeliveryMessage.default}
            >
              <BulletList>
                <li>Placed inside the entryway or garage.</li>
                <li>4-hour delivery window</li>
                <li>You will be notified of the estimated delivery window 2 days prior to delivery.</li>
                <StyledListItem>
                  Does NOT include professional assembly or removal of packaging.
                  <ItalicNote>
                    By selecting Doorway Delivery, you agree that you are solely responsible for assembly as set forth
                    in the
                    <RTGLink
                      action="click"
                      style={{ fontSize: '12px' }}
                      data={{
                        action: 'click',
                        target: '_blank',
                        title: 'Rooms To Go Terms of Use',
                        url: regionInPR
                          ? 'https://vault.pactsafe.io/s/e9b4b02e-6b86-4043-82f7-abd86913713a/legal.html#contract-rjvll6vhc'
                          : 'https://misc.rtg-prod.com/rtgcom-terms-of-sale.pdf',
                      }}
                    >
                      &nbsp;Online Terms and Conditions of Sale, Limited Product Warranty, and Dispute Resolution /
                      Arbitration Agreement.
                    </RTGLink>
                  </ItalicNote>
                </StyledListItem>
              </BulletList>
            </DeliveryOption>
          )}
        </RadioContainer>
        <Footer closeModal={closeModal} order={order} selectedDeliveryMode={deliveryType} />
      </ModalContent>
    </StyledDeliveryTypeModal>
  )
}

DeliveryModal.propTypes = {
  closeModal: func,
  deliveryZone: string,
  modalOpen: bool,
  order: object,
  selectedDeliveryMode: string,
}

export default DeliveryModal

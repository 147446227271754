import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'
import '@comp-sass/product/product-parts/add-to-cart-modal.sass'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import { LoadingSpinner } from '@components/shared/LoadingSpinner'
import loaderLight from '@assets/images/loader-light.svg'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

const ModalWrapper = styled(Modal)`
  .modal-content {
    max-width: 600px;
  }
  .text-container {
    text-align: center;
  }
  .deny-btn,
  .accept-btn {
    width: 100%;
    color: ${colors.white};
    height: 2.5rem;
    font-weight: 600;
    font-size: ${fonts.txtSmall};
    max-width: 200px;
    margin: 1rem auto 0 auto;
    &:hover {
      opacity: 0.85;
    }
  }
  .deny-btn {
    background: ${colors.red};
    @media only screen and (max-width: ${breakPoints.small}) {
      margin-bottom: 1rem;
    }
  }
  .accept-btn {
    background: ${colors.primary};
  }
`

const LoadingSpinnerWrapper = styled.div`
  height: 3rem;
  width: 100%;
  margin: 1rem 0 0 0;
`

const AlertModal = props => {
  const { shouldShowModal, btnClass, mdlClass, label, closeModal, children } = props
  return (
    <ModalWrapper
      isOpen={shouldShowModal}
      contentLabel={label}
      className={`info-modal add-to-cart-modal ${mdlClass}`}
      onRequestClose={closeModal}
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className="modal-content">
        <div className="grid-x">
          <div className="text-container small-12">{children}</div>
          {closeModal && (
            <>
              <button
                type="button"
                className={`accept-btn ${btnClass}`}
                tabIndex="0"
                value="Ok"
                aria-label="Ok accept button"
                onClick={closeModal}
              >
                OK
              </button>
            </>
          )}
          {!closeModal && (
            <>
              <LoadingSpinnerWrapper>
                <LoadingSpinner alt="Submitting Synchrony credit card" src={loaderLight} />
              </LoadingSpinnerWrapper>
            </>
          )}
        </div>
      </div>
    </ModalWrapper>
  )
}

AlertModal.propTypes = {
  btnClass: PropTypes.any,
  children: PropTypes.any,
  closeModal: PropTypes.any,
  label: PropTypes.any,
  mdlClass: PropTypes.any,
  shouldShowModal: PropTypes.any,
}

export default AlertModal

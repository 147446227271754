import React, { useState, useEffect, useRef, useMemo } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import { getFromBrowserStorage } from '@helpers/storage'
import ReactAutocomplete from 'react-autocomplete'
import { objectOf, any, string, arrayOf, func } from 'prop-types'
import { states, getRegionZone } from '@helpers/geo-location'
import { updateAddress } from '@services/checkout'
import { onItemKeyDown, onInputBlur, onInputKeyDown } from '@helpers/input-helper'
import {
  setContactInfo,
  setShippingAddressInfo,
  setAddress,
  onAddressLookupChange,
  onAddressLookupSelect,
  onClickChangeAddress,
  onStateChange,
  onMenuVisibilityChange,
  addressItemsFocus,
  getAddressSpecificBody,
} from '@helpers/checkout/shipping-section'
import { setOrderField, setOrderInfo } from '@helpers/checkout/global'
import { generateErrorMessage } from '@helpers/errors'
import { withTestId } from '@hooks/generateTestId'
import ErrorMessage from '@shared/error-message'
import CheckoutInput from '../checkout-input'
import CondensedInfo from './shipping-condensed'
import AddressSuggestionModal from '../address-suggestion-modal'

const ShippingSection = ({ order, getTestID, _autocomplete, checkoutStep, invalidFields, clearInvalidFields }) => {
  const refs = {
    changeAddressBtn: useRef(null),
    firstNameInput: useRef(null),
    lastNameInput: useRef(null),
    address1Input: useRef(null),
    address2Input: useRef(null),
    zipInput: useRef(null),
    cityInput: useRef(null),
    phoneInput: useRef(null),
    emailInput: useRef(null),
  }

  const [addressItems, setAddressItems] = useState([])
  const [autocompleteCopy, setAutocompleteCopy] = useState(_autocomplete)
  const [lookupDelay, setLookupDelay] = useState(undefined)

  useEffect(
    () => setAutocompleteCopy(_autocomplete),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_autocomplete],
  )
  useEffect(() => {
    const stepOneManualEntry = getFromBrowserStorage('session', 'StepOneManualEntry')
    if (stepOneManualEntry.toString() === 'true') {
      setShippingAddressInfo(false, 'showAddressLookup')
    }

    const { shippingAddress, storeInfo } = order
    const { address1, state, zip, city } = shippingAddress

    // Update to accurate shipping info from addressLookup if unavailable product error occurred
    // after changing address and user was booted back to cart
    if (order.shippingAddress.addressLookupSuccess) {
      const addressLookupSpacedParts = order.shippingAddress.addressLookup.split(' ')
      setShippingAddressInfo({
        address1: order.shippingAddress.addressLookup.split(',')[0],
        addressLookup: order.shippingAddress.addressLookup,
        zip: order.shippingAddress.addressLookup.slice(-5),
        state: addressLookupSpacedParts[addressLookupSpacedParts.length - 2],
        city: order.shippingAddress.addressLookup
          .split(',')?.[1]
          ?.split(' ')
          ?.slice(1, -2)
          ?.toString()
          ?.replace(',', ' '),
        showAddressLookup: true,
        addressLookupSuccess: order.shippingAddress.addressLookupSuccess,
      })
      updateAddress(getAddressSpecificBody(order))
    }

    // Setting delivery texts to true on component mount
    // The checkbox for opt-in is no longer available
    // Need to set opt-in as true for all users
    setOrderInfo(true, 'deliveryTexts')
    if ((address1, state, zip, city)) {
      // Address lookup will not function properly when prepopulating
      // switch to manual
      setAddress('No matches.')
    }

    // If it's store-cart then remove auto-completed address
    // because it'll receive address from store-cart
    // For more information please see the FD-1669
    if (storeInfo && storeInfo.storeCartId) {
      setOrderField('shippingAddress', {
        ...shippingAddress,
        addressLookup: '',
        addressLookupSuccess: false,
        showAddressLookup: false,
      })
    }

    return () => {
      clearInvalidFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formErrors = useMemo(() => {
    if (invalidFields.length < 1) {
      return null
    }

    const createErrorMessage = (field, index) => {
      const errorMessage = generateErrorMessage(field)
      if (index === 0) {
        const fieldRef = refs[`${field}Input`]
        if (fieldRef && !fieldRef.current?.value?.length) {
          fieldRef.current?.focus()
        }
      }

      return errorMessage
    }

    return (
      <>
        {invalidFields.map((field, index) => (
          <ErrorMessage key={field} invalidFields={[field]} customMessage={createErrorMessage(field, index)} />
        ))}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidFields])

  const updateAddressItems = items => {
    setAddressItems(items)
    addressItemsFocus(items, autocompleteCopy)
  }

  const checkForGlobalAddressId = async address => {
    const { globalAddressId } = addressItems.find(suggestedAddress => suggestedAddress.label === address)
    return onAddressLookupSelect(address, autocompleteCopy, refs.changeAddressBtn, globalAddressId)
  }

  const getTestIDWrapper = description => getTestID('checkout', description)

  const addressLookupParts = order?.shippingAddress?.addressLookup.split(',')
  const suggestModal = invalidFields.includes('unable to verify')
  const regionInPR = getRegionZone().region === 'PR'
  if (regionInPR) {
    order.shippingAddress.showAddressLookup = false
  }
  return (
    <>
      {checkoutStep !== 'shipping' && order.contact.firstName && <CondensedInfo order={order} />}
      {suggestModal && (
        <AddressSuggestionModal modalOpen={suggestModal} order={order} suggestion={order.suggestedAddress} />
      )}
      {checkoutStep === 'shipping' && (
        <ShippingForm className="grid-x cell">
          <p className="required-label small-12">*Asterisks indicate required fields</p>
          {formErrors}
          <div className="cell small-12 large-6">
            <CheckoutInput
              type="text"
              field="firstName"
              label="First Name"
              name="first name"
              info={order.contact}
              setInfo={setContactInfo}
              invalidFields={invalidFields}
              parentRef={refs.firstNameInput}
              required
              testID={getTestIDWrapper('shipping-firstName-input')}
            />
          </div>
          <div className="cell small-12 large-6">
            <CheckoutInput
              type="text"
              field="lastName"
              label="Last Name"
              name="last name"
              info={order.contact}
              setInfo={setContactInfo}
              invalidFields={invalidFields}
              parentRef={refs.lastNameInput}
              required
              testID={getTestIDWrapper('shipping-lastName-input')}
            />
          </div>
          {order.shippingAddress.showAddressLookup && !order.shippingAddress.addressLookupSuccess && (
            <div className="cell small-12 large-6">
              <label htmlFor="address" className="address-autocomplete">
                <span className="address-label" aria-hidden="true">
                  Address*
                </span>
                <span id="addressLabel" className="hide508">
                  Start typing to receive address suggestions
                </span>
              </label>
              <ReactAutocomplete
                ref={el => {
                  setAutocompleteCopy(el)
                }}
                wrapperStyle={{ display: 'block' }}
                items={addressItems}
                getItemValue={item => item.label}
                inputProps={{
                  id: 'address',
                  type: 'text',
                  'data-testid': getTestIDWrapper('shipping-address-lookup-input'),
                  name: 'address-lookup',
                  placeholder: 'Address Lookup',
                  'aria-haspopup': 'listbox',
                  'aria-autocomplete': 'list',
                  'aria-controls': 'addressSuggest',
                  'aria-activedescendant': '',
                  'aria-required': true,
                  'aria-labelledby': 'addressLabel',
                  className: classNames('address-lookup-input', {
                    invalid: invalidFields.includes('addressLookup'),
                  }),
                  onKeyDown: e => onInputKeyDown(e, autocompleteCopy),
                  onBlurCapture: e => onInputBlur(e),
                }}
                renderInput={props => <input {...props} autoComplete="new-password" />}
                onMenuVisibilityChange={() => onMenuVisibilityChange(addressItems, updateAddressItems)}
                renderMenu={children => (
                  <ul id="addressSuggest" role="listbox">
                    {children}
                  </ul>
                )}
                renderItem={item => (
                  <li
                    key={item.id}
                    id={item.id === 'no results' ? 'addsugnoresults' : `addsug${item.id}`}
                    className="address-list-item"
                    // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                    role="option"
                    tabIndex="0"
                    onKeyDown={e => {
                      onItemKeyDown(e, autocompleteCopy, refs.changeAddressBtn)
                    }}
                  >
                    {item.id === 'no results' && (
                      <>
                        {item.label}
                        <button
                          type="button"
                          className={classNames('address-manual-btn', {
                            'results-exist': item.label === '',
                          })}
                          onClick={() => setAddress('No matches.')}
                        >
                          Enter address manually.
                        </button>
                      </>
                    )}
                    {item.label !== 'No matches.' && <>{item.label}</>}
                  </li>
                )}
                value={order.shippingAddress.addressLookup}
                onChange={e => {
                  e.preventDefault()
                  const address = e.target.value

                  setShippingAddressInfo({
                    addressLookup: address,
                    addressLookupSuccess: false,
                  })
                  if (lookupDelay) {
                    clearTimeout(lookupDelay)
                  }
                  const timeOutId = setTimeout(() => onAddressLookupChange(address, updateAddressItems), 500)
                  setLookupDelay(timeOutId)
                }}
                onSelect={checkForGlobalAddressId}
                shouldItemRender={item => {
                  const address = item.label.split(',') // "406 Calle E", "Bayamon PR 00959"
                  if (address && address[1]) {
                    if (regionInPR) return address[1].match(' PR ') || item.label === 'No matches.' || item.label === '' // PR only
                    return !address[1].match(' PR ') // US only
                  }
                  return true
                }}
              />
              <div className="manual-address">
                <button type="button" onClick={() => setShippingAddressInfo(false, 'showAddressLookup')}>
                  Enter address manually.
                </button>
              </div>
            </div>
          )}

          {!order.shippingAddress.showAddressLookup && !order.shippingAddress.addressLookupSuccess && (
            <>
              <div className="cell small-12 large-6">
                <CheckoutInput
                  type="text"
                  className="street"
                  field="address1"
                  label="Street Address"
                  info={order.shippingAddress}
                  setInfo={setShippingAddressInfo}
                  invalidFields={invalidFields}
                  parentRef={refs.address1Input}
                  required
                  testID={getTestIDWrapper('shipping-address1-input')}
                />
              </div>
              <div className="cell small-12 large-6">
                <CheckoutInput
                  type="text"
                  className="apt"
                  field="address2"
                  label="Apt, Suite, Etc"
                  info={order.shippingAddress}
                  setInfo={setShippingAddressInfo}
                  parentRef={refs.address2Input}
                  testID={getTestIDWrapper('shipping-address2-input')}
                />
              </div>

              <div className="cell small-12 large-6">
                <CheckoutInput
                  type="text"
                  className="city"
                  field="city"
                  label="City"
                  info={order.shippingAddress}
                  setInfo={setShippingAddressInfo}
                  invalidFields={invalidFields}
                  parentRef={refs.cityInput}
                  required
                  testID={getTestIDWrapper('shipping-city-input')}
                />
              </div>
              <div className="cell small-12 large-6">
                <label className="label state" htmlFor="state">
                  State*
                  <select
                    name="state"
                    id="state"
                    className={classNames('state', {
                      invalid: invalidFields.includes('state'),
                    })}
                    value={order.shippingAddress.state.toUpperCase()}
                    onChange={onStateChange}
                    data-testid={getTestIDWrapper('shipping-state-input')}
                  >
                    {(!order.shippingAddress.state || order.shippingAddress.state === '') && (
                      <option value="none">*State</option>
                    )}
                    {states
                      .filter(state => {
                        if (!regionInPR) {
                          return state[1] !== 'PR'
                        }
                        return states
                      })
                      .map(state => (
                        <option key={state[1]} value={state[1]}>
                          {state[1]}
                        </option>
                      ))}
                  </select>
                </label>
              </div>
              <div className="cell small-12 large-6">
                <CheckoutInput
                  type="text"
                  className="zip"
                  field="zip"
                  label="Zip"
                  info={order.shippingAddress}
                  setInfo={setShippingAddressInfo}
                  invalidFields={invalidFields}
                  parentRef={refs.zipInput}
                  required
                  testID={getTestIDWrapper('shipping-zip-input')}
                />
              </div>
            </>
          )}
          {order.shippingAddress.addressLookupSuccess && (
            <>
              <div className="address-selected card">
                <svg className="checkmark-add-to-cart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark-add-to-cart__circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark-add-to-cart__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <span id="addressChosen">
                  <p>{addressLookupParts?.[0]}</p>
                  <p>{addressLookupParts?.[1]}</p>
                </span>
                <button
                  ref={refs.changeAddressBtn}
                  value="Change Address"
                  name="change_address"
                  className="change-address-btn"
                  aria-label="Change Address"
                  aria-describedby="addressChosen"
                  data-testid={getTestIDWrapper('shipping-change-address-btn')}
                  onClick={e => onClickChangeAddress(e, autocompleteCopy)}
                  onKeyDown={e => {
                    if (e.keyCode === 32 || e.keyCode === 13) {
                      onClickChangeAddress(e, autocompleteCopy)
                    }
                  }}
                  type="button"
                >
                  Change
                </button>
              </div>
              <div className="cell small-12 large-6">
                <CheckoutInput
                  type="text"
                  className="apt"
                  field="address2"
                  label="Apt, Suite, Etc"
                  info={order.shippingAddress}
                  setInfo={setShippingAddressInfo}
                />
              </div>
            </>
          )}

          <div className="cell small-12 large-6">
            <CheckoutInput
              type="tel"
              field="phone"
              label="Phone Number"
              additional="Numbers and hyphens only"
              name="tel"
              info={order.contact}
              setInfo={setContactInfo}
              invalidFields={invalidFields}
              parentRef={refs.phoneInput}
              required
              hasVerbiage
              testID={getTestIDWrapper('shipping-phone-input')}
            />
          </div>
          <div className="cell small-12 large-6">
            <CheckoutInput
              type="tel"
              field="altPhone"
              label="Alternate Phone"
              name="alt tel"
              info={order.contact}
              setInfo={setContactInfo}
              invalidFields={invalidFields}
              testID={getTestIDWrapper('shipping-altPhone-input')}
            />
          </div>
          <div className="cell small-12 large-6">
            <CheckoutInput
              field="email"
              info={order.contact}
              invalidFields={invalidFields}
              label="Email"
              name="email"
              required
              setInfo={setContactInfo}
              type="email"
              parentRef={refs.emailInput}
              testID={getTestIDWrapper('shipping-email-input')}
            />
          </div>
          {!regionInPR && (
            <div className="cell small-12">
              <div className="checkbox-container">
                <CheckoutInput
                  afterComponent={<p>I want to receive Rooms To Go email promotions and news letters.</p>}
                  className="text-me"
                  field="emailCampaign"
                  info={order}
                  label="I want to receive Rooms To Go email promotions and news letters."
                  setInfo={setOrderInfo}
                  type="checkbox"
                  testID={getTestIDWrapper('shipping-emailcampaign-checkbox')}
                />
              </div>
            </div>
          )}
        </ShippingForm>
      )}
    </>
  )
}

const ShippingForm = styled.form`
  &&& {
    text-align: left;
    width: 101.5%;
    @media only screen and (max-width: ${breakPoints.small}) {
      width: 95%;
      padding: 1rem;
    }
    @media only screen and (min-width: ${breakPoints['large-min']}) {
      padding: 0;
    }
    select.state {
      border: 1px solid ${colors.grey};
    }
    .state {
      width: 100%;
      height: 45px;
      @media only screen and (max-width: ${breakPoints['large-max']}) {
        margin-bottom: 1.5rem;
      }
      @media only screen and (max-width: ${breakPoints.small}) {
        width: 98.8%;
      }
      &.invalid {
        color: ${colors.red};
        border-color: ${colors.red};
      }
    }
    .address-selected {
      width: 93.5% !important;
      padding: 1rem 1rem 0 1rem;
      margin-bottom: 2.5%;
      margin-top: 1rem;
      float: left;
      margin-right: 20px;
      @media only screen and (max-width: ${breakPoints.small}) {
        font-size: 70%;
      }
      @media only screen and (min-width: ${breakPoints['large-min']}) {
        width: 57.5% !important;
        margin-bottom: 1.5%;
      }
      svg {
        float: left !important;
        margin-right: 1rem;
        margin-bottom: 0;
        height: 2.6rem;
        width: 2.6rem;
        @media only screen and (max-width: ${breakPoints.small}) {
          margin-top: 0;
        }
      }
      p {
        font-size: ${fonts.txtSmall};
        width: 50%;
        float: left;
        @media only screen and (max-width: ${breakPoints.small}) {
          width: 70%;
        }
      }
      .change-address-btn {
        background-color: ${colors.primary};
        color: ${colors.primaryInvert};
        border-radius: 0;
        padding: 12px;
        text-align: center;
        box-sizing: border-box;
        font-size: ${fonts.txtMedium};
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 10px;
        top: -0.3rem;
        position: relative;
        float: left;
        width: 100%;
        @media only screen and (min-width: ${breakPoints['medium-min']}) {
          float: right;
          width: 30%;
          top: -1.25rem;
        }
        @media only screen and (min-width: ${breakPoints['large-min']}) {
          float: right;
          margin-left: 56px;
          width: 100px;
          margin-top: 0;
          top: -1.2rem;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .required-label {
      margin: 1em 0 1.5em 0;
      font-weight: 600;
      @media only screen and (max-width: ${breakPoints.small}) {
        font-size: ${fonts.txtSmall};
        margin: 0.5em 0 1em 0;
      }
    }
    .address-label {
      width: 100%;
      display: block;
    }
    .label {
      display: block;
      @media only screen and (min-width: ${breakPoints['medium-min']}) {
        width: 100% !important;
        float: left;
      }
      input {
        width: 95%;
      }
    }
    .checkbox-container {
      font-size: ${fonts.txtSmall};
      display: inline-block;
      margin: 0.5rem 3em 1.2em 0;
      z-index: 0;
      @media only screen and (max-width: ${breakPoints['large-max']}) {
        margin-top: 1.5%;
      }
      p {
        display: inline;
        margin-left: 0.75em;
        &.invalid {
          color: ${colors.red};
        }
        @media only screen and (max-width: ${breakPoints.small}) {
          font-size: ${fonts.txtSmall};
        }
      }
    }

    input[role='combobox'] {
      width: 100%;
      margin: 0 2.5% 1.5% 0;
      line-height: 20px;
      padding: 10px;
      border-radius: 5px;
      @media only screen and (max-width: ${breakPoints.small}) {
        width: 97.5% !important;
      }
    }

    div {
      position: relative;
      z-index: 1;
      padding-right: 0.5em;
      @media only screen and (max-width: ${breakPoints.small}) {
        width: 97.5%;
        padding-right: 0;
      }
    }

    ul {
      background-color: #fff;
      border: solid 1px #dedede;
      width: 94.9%;
      top: 41px;
      margin-top: -19px;
      @media only screen and (max-width: ${breakPoints.small}) {
        width: 97.5% !important;
      }
      li.address-list-item {
        padding: 0.2em 1em;
        position: unset !important;
        display: inline-block !important;
        height: 10% !important;
        width: 100% !important;
        font-size: ${fonts.txtMedium};
        &:nth-child(1) {
          margin-top: 21px;
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          position: relative !important;
          top: 0 !important;
        }
        &:hover,
        &:focus {
          color: ${colors.primary};
          background: ${colors.lightGrey} !important;
        }
        .address-manual-btn {
          float: right;
          color: ${colors.primary};
          margin-top: 0.3em;
          font-size: 100%;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
          &.results-exist {
            float: left;
            margin-top: 0;
            padding-bottom: 0.2em;
          }
        }
      }
    }
  }
`

ShippingSection.propTypes = {
  order: objectOf(any),
  invalidFields: arrayOf(string),
  clearInvalidFields: func,
  getTestID: func,
  checkoutStep: string,
  _autocomplete: objectOf(any),
}

export default withTestId(ShippingSection)

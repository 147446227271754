import React from 'react'
import styled from 'styled-components'
import { string, node, bool, oneOfType, func } from 'prop-types'
import { breakPoints, fonts } from '@constants/styles'
import RadioButton from './radio-button'

const Text = styled.span`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-size: 18px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: 15px;
  }
`

const Image = styled.img.attrs(({ name, imgSrc }) => ({
  alt: name,
  src: imgSrc,
  role: 'image',
}))`
  object-fit: contain;
  width: 50px;
`

const OptionGroup = styled.div`
  flex: 1 1;
`

const InputSection = styled.div`
  border: ${props => (props.isChecked ? '1px solid #7faad0' : '1px solid white')};
  border-radius: 4px;
  background-color: ${props => (props.isChecked ? '#f8fbfd' : 'white')};
  padding: 15px;
  height: 100%;
  cursor: pointer;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    padding: 5px 15px 15px;
  }
`

const DeliverySection = styled.div`
  line-height: 2;
  margin-left: 20px;
  .bold {
    font-size: ${fonts.txtSmall};
    font-weight: 700;
  }
`
const ImageContainer = styled.div`
  text-align: center;
  img {
    width: 50px;
    height: 35px;
  }
`
const TitleContainer = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: 15px;
    text-align: left;
  }
`
const PriceContainer = styled.div`
  text-align: center;
  margin-top: 5px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const TopMobile = styled.div`
  display: none;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    display: flex;
    flex-direction: row;
  }
`
const TopDesktop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    display: none;
  }
`
const Middle = styled.div`
  margin-top: 20px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    margin-top: 5px;
  }
`
const BottomDesktop = styled.div`
  margin: auto auto 0;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    display: none;
  }
`

const DeliveryOption = ({ children, deliveryFee, isChecked, imgSrc, name, setDeliveryType, title }) => {
  const deliveryFeeText = Object.is(deliveryFee, '$0') ? 'FREE' : deliveryFee
  return (
    <OptionGroup onClick={() => setDeliveryType(name)}>
      <InputSection isChecked={isChecked}>
        <Wrapper>
          <TopMobile>
            <RadioButton checked={isChecked} name={name} onChange={() => setDeliveryType(name)} mobile />
            <TitleContainer>
              {title}
              {deliveryFee && (
                <Text bold>
                  {' - '}
                  {deliveryFeeText}
                </Text>
              )}
            </TitleContainer>
          </TopMobile>
          <TopDesktop>
            <ImageContainer>
              <Image alt={name} imgSrc={imgSrc} />
            </ImageContainer>
            <TitleContainer>{title}</TitleContainer>
            <PriceContainer>{deliveryFee && <Text bold>{deliveryFeeText}</Text>}</PriceContainer>
          </TopDesktop>
          <Middle>
            <DeliverySection>{children}</DeliverySection>
          </Middle>
          <BottomDesktop>
            <RadioButton checked={isChecked} name={name} onChange={() => setDeliveryType(name)} />
          </BottomDesktop>
        </Wrapper>
      </InputSection>
    </OptionGroup>
  )
}

DeliveryOption.propTypes = {
  children: node,
  deliveryFee: string,
  isChecked: bool.isRequired,
  imgSrc: string.isRequired,
  name: string.isRequired,
  setDeliveryType: func,
  title: oneOfType([string, node]).isRequired,
}

export default DeliveryOption

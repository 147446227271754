import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import { states } from '@helpers/geo-location'
import { setPayerInfo } from '@helpers/checkout/payment-section/payment-section'
import {
  setBillingAddressInfo,
  onBillingStateChange,
  onChangeBillingDifferent,
} from '@helpers/checkout/payment-section/billing-address'
import { generateErrorMessage } from '@helpers/errors'
import ErrorMessage from '@shared/error-message'
import CheckoutInput from '../../checkout-input'
import AddressSuggestionModal from '../../address-suggestion-modal'
import loaderLight from '../../../../../assets/images/loader-light.svg'

const BillingContainer = styled.div`
  //border-bottom: 1px solid ${colors.grey};
  padding-top: 0.75em;
  .billing {
    margin-top: 1rem;
    .edit-billing-btn {
      color: ${colors.primary};
      text-decoration: underline;
    }
  }
  .billing-label {
    margin-left: 1rem;
  }
`

const StyledBillingAddress = styled.div`
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;

  label {
    width: 100%;
    @media only screen and (min-width: ${breakPoints.medium}) {
      width: 48% !important;
      float: left;
    }

    input {
      width: 100%;
      @media only screen and (min-width: ${breakPoints.medium}) {
        width: 93.5%;
      }
    }
  }

  .state {
    width: 50%;
    padding: 10px;
    margin-bottom: 2.5%;
    border: solid 1px #dedede;
    box-sizing: border-box;
    border-radius: 0px;
  }

  .billing-input-row {
    display: flex;
    justify-content: space-between;

    input {
      width: 49%;
    }
  }

  @media only screen and (max-width: ${breakPoints.small}) {
    padding-right: 0.5em;
  }

  .required-label {
    margin-bottom: 0.25em;
    @media only screen and (max-width: ${breakPoints.small}) {
      font-size: ${fonts.txtSmall};
    }
  }

  .invalid-message {
    font-size: ${fonts.txtSmall};
    margin-top: 1rem;
  }

  .submit-billing-container {
    text-align: right;
    @media only screen and (max-width: ${breakPoints.small}) {
      width: 98%;
    }

    .submit-billing-btn {
      background-color: ${colors.primary};
      color: ${colors.primaryInvert};
      border-radius: 0px;
      text-align: center;
      box-sizing: border-box;
      font-size: ${fonts.txtMedium};
      text-transform: uppercase;
      font-weight: bold;
      width: 10.3em;
      margin-top: 0.75em;
      padding: 12px;

      .loader {
        height: 1.688rem;
        margin: -1.2rem -1rem -1rem;
      }
    }
  }
`

const BillingAddress = ({
  order,
  invalidFields,
  loading,
  submitBillingAddress,
  clearInvalidFields,
  setBillingState,
}) => {
  const suggestModal = invalidFields.length === 1 && invalidFields.includes('unable to verify')
  return (
    <BillingContainer>
      <input
        id="billingAddressCheckbox"
        type="checkbox"
        className="billing-address-checkbox"
        name="Edit Billing Address"
        label="Edit Billing Address"
        aria-label="Edit Billing Address"
        checked={!order.payer.billingDifferent}
        onChange={e => onChangeBillingDifferent(e, order)}
      />
      <span className="billing-label">Billing address same as shipping</span>
      {order.payer.billingDifferent && !order.payer.billingSubmitted && (
        <StyledBillingAddress>
          {invalidFields.length > 0 && (
            <ErrorMessage invalidFields={invalidFields} customMessage={generateErrorMessage(invalidFields)} />
          )}
          {suggestModal && (
            <AddressSuggestionModal
              setBillingState={setBillingState}
              modalOpen={suggestModal}
              order={order}
              suggestion={order.suggestedAddress}
              closeModal={clearInvalidFields}
            />
          )}
          <div className="billing-input-row">
            <CheckoutInput
              type="text"
              field="firstName"
              placeholder="First Name *"
              info={order.payer}
              setInfo={setPayerInfo}
              invalidFields={invalidFields}
              required
            />
            <CheckoutInput
              type="text"
              field="lastName"
              placeholder="Last Name *"
              info={order.payer}
              setInfo={setPayerInfo}
              invalidFields={invalidFields}
              required
            />
          </div>
          <CheckoutInput
            type="text"
            className="street"
            field="address1"
            placeholder="Address Line 1 *"
            info={order.billingAddress}
            setInfo={setBillingAddressInfo}
            invalidFields={invalidFields}
            required
          />
          <CheckoutInput
            type="text"
            className="apt"
            field="address2"
            placeholder="Address Line 2"
            info={order.billingAddress}
            setInfo={setBillingAddressInfo}
          />
          <div className="billing-input-row">
            <CheckoutInput
              type="text"
              className="city"
              field="city"
              placeholder="City *"
              info={order.billingAddress}
              setInfo={setBillingAddressInfo}
              invalidFields={invalidFields}
              required
            />
            <select
              name="state"
              aria-label="State*"
              className={classNames('state', {
                invalid: invalidFields.includes('state'),
              })}
              value={order.billingAddress.state.toUpperCase()}
              onChange={selected => onBillingStateChange(selected, setBillingAddressInfo)}
            >
              {(!order.billingAddress.state || order.billingAddress.state === '') && (
                <option
                  value="none"
                  style={{
                    color: '#707070',
                  }}
                >
                  State *
                </option>
              )}
              {states.map(state => (
                <option key={state[1]} value={state[1]}>
                  {state[1]}
                </option>
              ))}
            </select>
          </div>
          <CheckoutInput
            type="text"
            className="zip"
            field="zip"
            placeholder="ZIP Code *"
            info={order.billingAddress}
            setInfo={setBillingAddressInfo}
            invalidFields={invalidFields}
            required
          />
          <div className="submit-billing-container">
            <button
              id="submitBillingBtn"
              type="button"
              className="submit-billing-btn"
              tabIndex="0"
              value="Update Address"
              aria-label="Update Address"
              onClick={() => submitBillingAddress()}
            >
              {!loading && <>Update Address</>}
              {loading && <img className="loader" alt="Submitting new billing address" src={loaderLight} />}
            </button>
          </div>
        </StyledBillingAddress>
      )}
      {order.payer.billingDifferent && order.payer.billingSubmitted && (
        <div className="billing">
          <h4>Billing Information</h4>
          <button
            type="button"
            className="edit-billing-btn"
            tabIndex="0"
            value="Edit Billing Address"
            aria-label="Edit Billing Address"
            onClick={() => setPayerInfo(false, 'billingSubmitted')}
          >
            Edit Billing Address
          </button>
          <div className="left-info">
            {`${order.payer.firstName} ${order.payer.lastName}`}
            <br />
            {order.billingAddress.address1}
            {order.billingAddress.address2 &&
              order.billingAddress.address2 !== '' &&
              order.billingAddress.address2 !== 'null' &&
              order.billingAddress.address2 !== 'undefined' &&
              ` ${order.billingAddress.address2}`}
            <br />
            {`${order.billingAddress.city} `}
            {`${order.billingAddress.state} `}
            {order.billingAddress.zip}
          </div>
        </div>
      )}
    </BillingContainer>
  )
}

BillingAddress.propTypes = {
  clearInvalidFields: PropTypes.any,
  invalidFields: PropTypes.any,
  loading: PropTypes.any,
  order: PropTypes.any,
  setBillingState: PropTypes.any,
  submitBillingAddress: PropTypes.func,
}

export default BillingAddress

import React from 'react'
import { connect } from 'react-redux'
import { setDeliveryCalendar } from '@helpers/checkout/delivery-section'
import { array, arrayOf, string, objectOf, any, object, bool } from 'prop-types'
import DeliverySection from './delivery-section'

class DeliverySectionWrapper extends React.Component {
  componentDidMount() {
    const { order } = this.props
    this.setDeliveryData(order)
  }

  componentDidUpdate(prevProps) {
    const { order } = this.props
    if (
      JSON.stringify(prevProps.order.shippingAddress) !== JSON.stringify(order.shippingAddress) ||
      JSON.stringify(prevProps.order.lineItems) !== JSON.stringify(order.lineItems) ||
      JSON.stringify(prevProps.order.deliveryCalendar) !== JSON.stringify(order.deliveryCalendar)
    ) {
      this.setDeliveryData(order)
    }
  }

  setDeliveryData = order => {
    if (
      order &&
      order.totalDeliveryCharge > -1 &&
      order.shippingAddress &&
      order.deliveryCalendar &&
      order.deliveryCalendar.length
    ) {
      setDeliveryCalendar(order.deliveryCalendar, order.pickupCalendar, order.expressCalendar || [])
    }
  }

  render() {
    const {
      order,
      checkoutStep,
      checkoutStepsCompleted,
      deliveryInvalidFields,
      cart,
      deliveryCalendar,
      storeCartLineItems,
    } = this.props
    return (
      <DeliverySection
        order={order}
        checkoutStep={checkoutStep}
        checkoutStepsCompleted={checkoutStepsCompleted}
        invalidFields={deliveryInvalidFields}
        cart={cart}
        deliveryCalendar={deliveryCalendar}
        storeCartLineItems={storeCartLineItems}
      />
    )
  }
}

DeliverySectionWrapper.propTypes = {
  order: objectOf(any),
  checkoutStep: string,
  checkoutStepsCompleted: objectOf(bool),
  deliveryInvalidFields: array,
  cart: object,
  deliveryCalendar: arrayOf(object),
  storeCartLineItems: array,
}

const mapStateToProps = ({ checkout, cart }) => ({
  order: checkout.order,
  checkoutStep: checkout.checkoutStep,
  checkoutStepsCompleted: checkout.checkoutStepsCompleted,
  deliveryInvalidFields: checkout.deliveryInvalidFields,
  cart: cart.cart,
  deliveryCalendar: checkout.deliveryCalendar,
})

export default connect(mapStateToProps)(DeliverySectionWrapper)

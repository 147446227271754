import React from 'react'
import { string } from 'prop-types'
import { SynchronyApplyWrapper } from './styles'

const SynchronyApply = ({ postbackRefId }) => (
  <SynchronyApplyWrapper>
    <div>
      <p>Don't have a Rooms To Go Finance Account?</p>
    </div>
    <div className="apply-link">
      <form
        id="creditApplnForm"
        name="frmApplicationTermsCond"
        action={process.env.GATSBY_SYNCHRONY_URL}
        method="POST"
        target="_blank"
      >
        <input type="hidden" id="uniqueId" name="uniqueId" value={process.env.GATSBY_CREDIT_UNIQUE_ID} />
        <input type="hidden" id="postbackRefId" name="postbackRefId" value={postbackRefId} />
        <input type="hidden" name="pcgc" value={process.env.GATSBY_CREDIT_PCGC} />
        <input type="hidden" name="mid" value={process.env.GATSBY_CREDIT_MID} />
        <input
          role="link"
          id="applyFinance"
          type="submit"
          data-seo="applyFinance"
          className="fake-link"
          aria-label="Continue with online application (opens in new window)"
          value="Apply Now >"
        />
      </form>
    </div>
  </SynchronyApplyWrapper>
)

SynchronyApply.propTypes = {
  postbackRefId: string,
}

SynchronyApply.defaultProps = {
  postbackRefId: '',
}

export default SynchronyApply

import React, { useState } from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { objectOf, any, func, shape, instanceOf } from 'prop-types'
import DownPayment from './down-payment'
import CheckoutInput from '../../checkout-input'
import { AfterComponentWrapper, AfterComponentLabel, LinkButton } from './styles'
import RTGCreditModal from '../rtg-credit-modal'

const theme = createTheme({
  overrides: {
    MuiCollapse: {
      container: {
        border: '1px solid',
        padding: '0.75rem',
        maxWidth: '400px',
        marginTop: '0.75em',
        maxHeight: '120px',
      },
      entered: {
        overflow: 'scroll',
      },
    },
  },
})

const SynchronyFinancePlan = ({ plan, setFinancePlan, financePlanRef }) => {
  const [toggleModal, setToggleModal] = useState(false)
  return (
    <CheckoutInput
      type="radio"
      field={plan.siteFriendlyLabel}
      id={plan.name}
      label={plan.siteFriendlyLabel}
      info={plan}
      setInfo={setFinancePlan}
      name="finance"
      radioValue={plan.financeCode}
      afterComponent={
        <AfterComponentWrapper>
          <AfterComponentLabel>{plan.siteFriendlyLabel}</AfterComponentLabel>
          <DownPayment downPaymentRequired={plan.downPaymentRequired} />
          <LinkButton type="button" className="link" onClick={() => setToggleModal(!toggleModal)}>
            Promotion Details {toggleModal ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </LinkButton>
          {plan?.promoMessage && (
            <ThemeProvider theme={theme}>
              <Collapse in={toggleModal} timeout="auto" unmountOnExit>
                <div dangerouslySetInnerHTML={{ __html: plan.promoMessage.childMarkdownRemark.html }} />
              </Collapse>
            </ThemeProvider>
          )}
          {/* {toggleModal && ( */}
          {/*  <RTGCreditModal */}
          {/*    modalOpen={toggleModal} */}
          {/*    closeModal={() => setToggleModal(!toggleModal)} */}
          {/*    promoMessage={plan.promoMessage} */}
          {/*  /> */}
          {/* )} */}
        </AfterComponentWrapper>
      }
      parentRef={financePlanRef}
    />
  )
}

SynchronyFinancePlan.propTypes = {
  plan: objectOf(any),
  setFinancePlan: func.isRequired,
  financePlanRef: shape({
    current: instanceOf(any),
  }),
}

SynchronyFinancePlan.defaultProps = {
  plan: {},
}

export default SynchronyFinancePlan

import React from 'react'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import scriptLoader from 'react-async-script-loader'
import { breakPoints, colors, fonts } from '@constants/styles'
import { sentryLogger, levels, setExtra, cleanOrderObject, sentryMessages } from '@helpers/sentry-logger'
import { store } from '@redux/store'
import { bool, object, func } from 'prop-types'
import { setOrder } from '@redux/modules/checkout'
import { getToken, updatePayment } from '@services/checkout'
import { setCheckoutStep } from '@helpers/checkout/global'
import { getCreditCardDecision, scrollTo } from '@helpers/checkout/payment-section/credit-card'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import loadingSvg from '@assets/images/loader-dark.svg'
import ScrollSneak from '@helpers/scroll-sneak'
import BillingAddress from './billing-address'

const StyledCreditCardMicroform = styled.div`
  //max-width: 559px;
  //height: 100%;
  //min-height: 340px;
  //margin-top: 0;
  //border: solid 1px ${colors.lightGrey};
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //@media only screen and (max-width: ${breakPoints.small}) {
  //  margin-top: 1rem;
  //}
  #card-expiration {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .microform-inner-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: ${breakPoints.small}) {
      padding: 0.5rem;
    }
    .microform-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .payment-header {
        padding-left: 0;
        display: block;
        color: black;
        font-size: ${breakPoints.txtMediumUp};
        @media only screen and (max-width: ${breakPoints.small}) {
          padding: 0;
          font-size: ${breakPoints.txtMedium};
        }
      }
    }
    .invalid-card {
      font-size: 0.85rem;
      margin-top: 0.5rem;
      color: ${colors.red};
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media only screen and (max-width: ${breakPoints.small}) {
        margin-top: 0.1rem;
        flex-grow: 1;
      }
      &.hidden {
        visibility: hidden;
      }
    }
    overflow: hidden;
    &.hidden {
      display: none;
    }
    .microform-form-container {
      display: flex;
      flex-direction: column;
    }
    iframe {
      height: 40px !important;
      border: 1px solid ${colors.lightGrey} !important;
      //padding: 3px 5px;
      padding: 10px;
    }
    .card-input {
      width: 49%;
      //margin-right: 1rem;
      //display: inline-table;
      // @media only screen and (max-width: ${breakPoints.small}) {
      //   width: 5rem;
      // }
      &.card-number {
        width: 100%;
      }
      label {
        font-size: 12px;
      }
      input,
      select {
        width: 49%;
        //display: block;
        //height: 40px !important;
        border: 1px solid ${colors.lightGrey};
        padding: 10px;
      }
      select {
        font-size: 0.85rem;
      }
    }
    #submit-card-btn {
      background: ${colors.primary};
      color: white;
      font-size: 1.25rem;
      padding: 1rem;
      margin-top: 0.75rem;
      width: 100%;
    }
  }
  .message {
    margin: 1rem 0;
  }
  .try-again {
    font-size: 1rem;
    color: ${colors.primary};
    &:hover {
      text-decoration: underline;
    }
  }
  .loading-icon {
    height: 4em;
    width: 100%;
    // background: ${colors.lightGrey};
    background-image: url(${loadingSvg});
    background-repeat: no-repeat;
    background-position: center;
  }
  .success-icon {
    width: 3.7rem;
    height: 3.7rem;
    padding: 0.25rem;
    .checkmark {
      margin: auto;
      width: 3.1rem;
      height: 3.1rem;
      border-radius: 50%;
      display: block;
      stroke-width: 4;
      stroke: #fff;
      stroke-miterlimit: 10;
      box-shadow: inset 0em 0em 0em #00ab67;
      -webkit-animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
      animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
    }
    .checkmark-circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: ${colors.green};
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
    .checkmark-check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
  }
  .error-icon {
    display: block !important;
    height: 50px !important;
    width: 50px !important;
    margin: 0.25rem;
    margin-left: -0.25rem;
    left: 0 !important;
    &:before {
      background: red;
      border-radius: 100%;
      height: 100%;
      width: 100%;
      color: ${colors.primaryInvert};
      font-size: 75px;
      top: 0;
      left: 0;
      line-height: 0.61;
      text-align: center;
    }
    &:after {
      transform: unset;
    }
    &:hover:before {
      transform: scale(1) rotate(45deg);
      font-size: 75px;
    }
  }
`

const scrollSneak = new ScrollSneak('CreditCardMicroform')
class CreditCardMicroform extends React.Component {
  state = {
    complete: false,
    errorMessage: null,
    inputError: false,
    microformIsLoading: false,
    didCreateMicroform: false,
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props
    const complete = getCreditCardDecision()
    if (complete) {
      this.setState({ complete })
    } else if (isScriptLoaded) {
      if (isScriptLoadSucceed) {
        this.setupMicroform()
      } else {
        this.onError('Something went wrong. Please ', 'microform script', 'Could not load microform script')
      }
    }
    scrollSneak.tryToScrollTo('.microform-header')
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/no-did-update-set-state */
    const { complete, errorMessage, microformIsLoading } = this.state
    const { isScriptLoaded, isScriptLoadSucceed } = this.props
    const newComplete = getCreditCardDecision()
    if (!newComplete && complete) {
      this.setState({ complete: false }, this.setupMicroform)
    }
    if (!isScriptLoaded && !microformIsLoading) {
      this.setState({ microformIsLoading: true })
    }
    if (isScriptLoaded && !prevProps.isScriptLoaded && !complete && !errorMessage) {
      if (isScriptLoadSucceed) {
        this.setupMicroform()
      } else {
        this.onError('Something went wrong. Please ', 'microform script', 'Could not load microform script')
      }
    }
  }

  setupMicroform() {
    const { order } = this.props
    const { didCreateMicroform } = this.state
    if (!didCreateMicroform) {
      this.setState({ microformIsLoading: true })
      getToken({ orderId: order.orderId })
        .then(res => {
          if (res.cybersourcev2Token) {
            const submitCardBtn = document.querySelector('#submit-card-btn')

            const captureContext = res.cybersourcev2Token

            const myStyles = {
              input: {
                'font-size': '14px',
                color: '#555',
              },
              ':disabled': { cursor: 'not-allowed' },
              valid: { color: '#3c763d' },
              invalid: { color: '#a94442' },
            }

            const flex = new window.Flex(captureContext)
            const microformInstance = flex.microform({ styles: myStyles })
            if (microformInstance._microformId) {
              const number = microformInstance.createField('number', { placeholder: 'Enter card number' })
              const securityCode = microformInstance.createField('securityCode', { placeholder: '•••' })
              let cardInfo = {}

              number.on('change', data => {
                if (data.valid) {
                  cardInfo = {
                    cardName: data.card[0].name,
                    cardType: data.card[0].cybsCardType,
                  }
                }
              })
              number.load('#cardNumber-container')
              securityCode.load('#securityCode-container')

              this.setState({ microformIsLoading: false, didCreateMicroform: true })
              submitCardBtn.addEventListener('click', () => this.onSubmitCard(microformInstance, cardInfo))
              submitCardBtn.addEventListener(
                'keydown',
                e => e.keyCode === 13 && this.onSubmitCard(microformInstance, cardInfo),
              )
              setTimeout(
                () => this.onError('Your session timed out. Please '),
                900000,
              ) /* Token will timeout after 15 mins */
            } else {
              this.onError(
                'Something went wrong. Please ',
                'microform creation',
                'Could not create microform with provided token',
              )
            }
          } else {
            this.onError('Something went wrong. Please ', 'microform token', 'No microform token provided')
          }
        })
        .catch(err => {
          this.onError('Something went wrong. Please ', 'microform token fetch', 'Could not fetch microform token', err)
        })
    }
  }

  newCard() {
    const { order, onClose } = this.props
    if (order && order.paymentInfo) {
      const paymentInfo = order.paymentInfo.filter(payment => payment.paymentType !== 'CYBERV3')
      const newOrder = {
        ...order,
        paymentInfo: paymentInfo.length > 0 ? paymentInfo : null,
        isNonFinanceCredit: true,
      }
      if (newOrder.paymentInfo === null) {
        updatePayment({
          orderId: order.orderId,
          paymentInfo: [],
        })
          .then(data => {
            if (onClose) onClose()
            store.dispatch(setOrder(data))
            this.tryAgain()
          })
          .catch(() => {
            sentryLogger({
              configureScope: {
                type: setExtra,
                level: levels.error,
                orderId: order.orderId,
                paymentType: paymentInfo[0].paymentType,
                order: cleanOrderObject(order),
              },
              captureMessage: {
                type: 'text',
                message: sentryMessages.paymentUpdateFailure,
                level: levels.error,
              },
            })
          })

        this.onError(
          'Something went wrong. Please ',
          'microform updatePayment',
          'error when calling updatePayment when selecting new card',
          '',
          levels.info,
        )
      } else {
        store.dispatch(setOrder(newOrder))
        this.tryAgain()
      }
    }
  }

  tryAgain() {
    scrollSneak.sneak()
    window.location.reload()
  }

  onSubmitCard(microformInstance, cardInfo) {
    const { order, onClose } = this.props
    this.setState({ inputError: false, microformIsLoading: true })

    const cardExpirationMonth = document.querySelector('#cardExpirationMonth')
    const cardExpirationYear = document.querySelector('#cardExpirationYear')
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1

    const options = {
      expirationMonth: cardExpirationMonth.value,
      expirationYear: cardExpirationYear.value,
    }

    const invalidExpDate =
      Number(options.expirationYear) === currentYear && Number(options.expirationMonth) < currentMonth

    microformInstance.createToken(options, (err, mfResponse) => {
      if (err || invalidExpDate) {
        this.setState({ inputError: true, microformIsLoading: false })
        return
      }
      const creditCardPaymentInfo = {
        paymentType: 'CYBERV3',
        paymentProperties: {
          token: mfResponse,
          card: cardInfo,
        },
      }
      const paymentTypesToClear = ['AFF', 'VISA', 'PALV2', 'CYBERV3']
      const paymentInfo = order.paymentInfo.filter(payment => !paymentTypesToClear.includes(payment.paymentType))
      paymentInfo.push(creditCardPaymentInfo)
      updatePayment({
        orderId: order.orderId,
        paymentInfo,
      })
        .then(newOrder => {
          this.setState({ microformIsLoading: false })
          if (newOrder.paymentInfo) {
            if (onClose) onClose()
            store.dispatch(setOrder({ ...newOrder, isNonFinanceCredit: true }))
            setCheckoutStep(null, 'payment', 'review')
            scrollTo('payment')
          } else {
            this.onError(
              'Something went wrong. Please ',
              'microform updatePayment',
              'No paymentInfo found',
              '',
              levels.info,
            )
          }
        })
        .catch(error => {
          this.onError(
            'Something went wrong. Please ',
            'microform updatePayment',
            'error when submitting credit card',
            error,
            levels.error,
          )
        })
    })
  }

  onError(errorMessage, errorTitle = null, errorDescription = null, errorData = null, level = levels.info) {
    const { order } = this.props
    if (errorTitle && errorDescription) {
      sentryLogger({
        configureScope: {
          type: setExtra,
          error: errorData,
          level,
          order: cleanOrderObject(order),
        },
        captureMessage: {
          type: 'text',
          message: `${errorTitle} - ${errorDescription}`,
          level,
        },
      })
    }
    this.setState({
      errorMessage,
      microformIsLoading: false,
      complete: false,
    })
  }

  renderMonthSelection() {
    const months = [
      '01 - January',
      '02 - February',
      '03 - March',
      '04 - April',
      '05 - May',
      '06 - June',
      '07 - July',
      '08 - August',
      '09 - September',
      '10 - October',
      '11 - November',
      '12 - December',
    ]
    const monthOptions = months.map(month => (
      <option value={month.substring(0, 2)} key={month}>
        {month}
      </option>
    ))
    return (
      <select id="cardExpirationMonth" name="ccmonth">
        {monthOptions}
      </select>
    )
  }

  renderYearSelection() {
    const currentYear = new Date().getFullYear()
    const yearOptions = new Array(20).fill(null).map((a, index) => {
      const newYear = currentYear + index
      return (
        <option value={newYear} key={newYear}>
          {newYear}
        </option>
      )
    })
    return (
      <select id="cardExpirationYear" name="ccyear">
        {yearOptions}
      </select>
    )
  }

  render() {
    const { order } = this.props
    const { complete, errorMessage, microformIsLoading, inputError } = this.state
    const shouldHideForm = complete || errorMessage || microformIsLoading
    return (
      <div>
        <StyledCreditCardMicroform>
          <div className={classNames('microform-inner-container', { hidden: shouldHideForm })}>
            <div className="microform-header">
              <PaymentSvg
                uniqueNameForId="creditMicroForm"
                cards={['discover', 'mastercard', 'visa', 'amex']}
                cordY="0"
                vpHeight="50"
                width="175px"
                height="50px"
              />
              <Typography variant="caption">* indicates a required field</Typography>
            </div>
            {inputError && (
              <Typography variant="caption" color="error">
                Please input valid card information.
              </Typography>
            )}
            <div className="microform-form-container">
              <div className="card-input card-number">
                <label htmlFor="cardNumber-container">Card/Debit Card Number*</label>
                <div id="cardNumber-container" />
              </div>
              <label htmlFor="card-expiration">Credit Card Expiration Date*</label>
              <div id="card-expiration" className="card-input">
                {this.renderMonthSelection()}
                {this.renderYearSelection()}
              </div>
              <div className="card-input">
                <label htmlFor="securityCode-container">CVV*</label>
                <div id="securityCode-container" className="form-control" />
              </div>
            </div>
            <BillingAddress order={order} />
            <button type="button" id="submit-card-btn">
              Continue
            </button>
          </div>
          {microformIsLoading && !complete && !errorMessage && <div className="loading-icon" />}
          {complete && !errorMessage && (
            <>
              <div className="success-icon">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <title>Credit card application was successful.</title>
                  <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
              </div>
              <span className="message">Credit card application was successful.</span>
              <button
                type="button"
                className="try-again"
                tabIndex="0"
                value="Enter new card"
                aria-label="Enter new card"
                onClick={() => this.newCard()}
              >
                Enter new card
              </button>
            </>
          )}
          {!complete && errorMessage && (
            <>
              <i className="error-icon icon close" aria-label="failed to apply credit card" />
              <div className="message">
                {errorMessage}
                <button
                  type="button"
                  className="try-again"
                  tabIndex="0"
                  value="Refresh page"
                  aria-label="Refresh page"
                  onClick={this.tryAgain}
                >
                  try again.
                </button>
              </div>
            </>
          )}
        </StyledCreditCardMicroform>
      </div>
    )
  }
}

CreditCardMicroform.propTypes = {
  isScriptLoaded: bool,
  isScriptLoadSucceed: bool,
  order: object,
  onClose: func,
}

export default scriptLoader([`https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js`])(
  CreditCardMicroform,
)

import styled from 'styled-components'
import { Tab, TabList, TabPanel } from 'react-tabs'
import { breakPoints, colors, fonts } from '@constants/styles'
import { Link } from 'gatsby'
import loadingSvg from '@assets/images/loader-dark.svg'
import { Typography } from '@mui/material'

export const PaymentForm = styled.div`
  width: 430px;
  //border-radius: 5px;
  //padding: 0.6em;
  text-align: left;
  @media only screen and (max-width: ${breakPoints.medium}) {
    width: 100%;
  }
  .invalid-message {
    margin-bottom: 0;
    text-align: left;
  }
  .hide {
    display: none;
  }
  .billing-address-checkbox {
    margin-left: calc(0.5% + 0.3em);
  }
  .required-label {
    margin: 0 0 0.5rem 0;
    font-weight: 600;
    @media only screen and (max-width: ${breakPoints.small}) {
      font-size: ${fonts.txtSmall};
      margin: 0.5em 0 1em 0;
    }
  }
  .state {
    width: 97.5%;
    margin: 0 2.5% 2.8% 0;
    height: 46px;
    @media only screen and (min-width: ${breakPoints.medium}) {
      width: 93.5%;
    }
  }
  .more-payments {
    &:focus {
      outline: none;
    }
  }
  .credit-card-container {
    &:focus {
      outline: none;
    }
    .billing-iframe {
      min-height: 25.5rem;
      &.accepted {
        min-height: 0;
      }
    }
    .credit-card-iframe {
      max-width: 559px;
      padding-left: 0.15rem;
      @media only screen and (min-width: ${breakPoints.medium}) {
        padding-left: 0.37rem;
      }
      &.loading {
        margin-top: 0.75rem;
        margin-left: 0.17rem;
        height: 28.7rem;
        max-width: 554px;
        background: ${colors.lightGrey};
        border: solid 1px ${colors.grey};
        background-image: url(${loadingSvg});
        background-repeat: no-repeat;
        background-position: center;
        @media only screen and (min-width: ${breakPoints.medium}) {
          margin-left: 0.3rem;
        }
      }
    }
  }
  .payment-form-container {
    .buttons-container {
      justify-content: space-around;
      margin-bottom: 0.75em;
      .loader {
        background: none !important;
      }
      .paypal-button-wrapper {
        background: #0370ba;
        z-index: 0;
        overflow: hidden;
        @media only screen and (max-width: ${breakPoints.small}) {
          display: flex;
          padding-top: 10px;
          justify-content: center;
          align-self: center;
          height: 3em;
          min-height: 3.5rem;
          width: 100%;
          margin-top: 5%;
          .paypal-buttons > iframe.visible {
            max-width: 100%;
            padding-left: 3%;
          }
        }
        @media only screen and (min-width: ${breakPoints.small}) and (max-width: ${breakPoints['large-min']}) {
          width: 100%;
          margin-top: 5%;
          max-height: 2.5em;
        }
        @media only screen and (min-width: ${breakPoints['large-min']}) {
          height: 4rem;
          text-align: center;
          display: table;
          margin: 0.5% 0.5%;
          position: relative;
          max-width: 100%;
          vertical-align: middle;
          width: 100%;
          div {
            background: #0370ba;
            height: 3em;
            cursor: pointer;
            margin: 0 -1em 0 -1em;
            @media only screen and (max-width: ${breakPoints.small}) {
              iframe {
                margin-top: 1%;
              }
            }
            @media only screen and (min-width: ${breakPoints['large-min']}) and (max-width: ${breakPoints['x-large']}) {
              iframe {
                margin-top: 7.5%;
              }
            }
            @media only screen and (min-width: 1383px) {
              iframe {
                margin-top: 4%;
              }
            }
          }
        }
        @media only screen and (min-width: 1383px) {
          div {
            margin: 0.25em -1em 0 -1em;
          }
        }
      }
      .applepay-button-wrapper {
        background: #000;
        z-index: 0;
        overflow: hidden;
        height: 2.5em;
        cursor: pointer;
        button {
          height: 42px !important;
        }
        @media only screen and (max-width: ${breakPoints.small}) {
          display: flex;
          align-items: center;
          height: 3em;
          min-height: 3.5rem;
          width: 45%;
          margin-top: 5%;
          > {
            max-height: 20px;
          }
          div {
            margin: -0.25em 0 0 0;
          }
        }
        @media only screen and (min-width: ${breakPoints.small}) and (max-width: ${breakPoints['large-min']}) {
          width: 45%;
          margin-top: 5%;
          max-height: 2.5em;
        }
        @media only screen and (min-width: ${breakPoints['large-min']}) {
          height: 4rem;
          text-align: center;
          display: flex;
          align-items: center;
          margin: 0.5% 0.5%;
          position: relative;
          max-width: 19%;
          vertical-align: middle;
          width: 19%;
          div {
            margin: 0.75em 0 0;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .payment-type {
      min-height: 2.5rem;
      width: 45%;
      background: ${colors.darkerGrey};
      @media only screen and (max-width: ${breakPoints.small}) {
        min-height: 3.5rem;
        &:nth-last-of-type(1) {
          margin-top: 5%;
        }
      }
      @media only screen and (min-width: ${breakPoints.small}) and (max-width: ${breakPoints['large-min']}) {
        &:nth-last-of-type(1) {
          margin-top: 5%;
        }
      }
      &:nth-last-of-type(1) {
        @media only screen and (max-width: ${breakPoints.small}) {
          margin-top: 5%;
        }
      }
      &:hover {
        background: #757575;
      }
      &.active {
        background: ${colors.primary};
        &:hover {
          background: ${colors.lightBlue};
        }
      }
      &.disabled {
        opacity: 0.25;
        &:hover {
          background: ${colors.darkGrey};
        }
      }
      @media only screen and (min-width: ${breakPoints['large-min']}) {
        height: 4rem;
        text-align: center;
        display: table;
        margin: 0.5% 0.5%;
        position: relative;
        max-width: 19%;
        vertical-align: middle;
      }
      .payment-type-text {
        width: 100%;
        display: table;
        font-weight: 700;
        cursor: pointer;
        padding: 0.2rem;
        text-align: center;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          padding: 1.5rem;
        }
        span {
          display: block;
        }
        &.active {
          background: ${colors.primary};
          &:hover {
            background: ${colors.lightBlue};
          }
        }
        font-size: ${fonts.txtSmall};
        color: ${colors.primaryInvert};
        @media only screen and (min-width: ${breakPoints['large-min']}) {
          font-size: ${fonts.txtSmall};
        }
      }
    }
    .more-payment-type {
      height: 8.5em;
      border: solid 3px ${colors.grey};
      text-align: center;
      display: table;
      margin: 0.5% 0.5%;
      position: relative;
      cursor: pointer;
      @media only screen and (min-width: ${breakPoints['large-min']}) {
        max-width: 33%;
      }
      @media only screen and (max-width: ${breakPoints.small}) {
        height: 7em;
        margin: 0.5% 0;
      }
      &.active {
        border-color: ${colors.primary} !important;
      }
      &:hover {
        border-color: ${colors.primary} !important;
      }
      &.affirm-disabled {
        background: ${colors.grey};
        opacity: 0.7;
        &:hover {
          border-color: ${colors.grey};
        }
        .v-learn {
          font-size: ${fonts.txtSmall};
          color: ${colors.primary};
          text-align: center;
        }
      }
    }
    input[type='radio'] {
      margin-right: 1em;
      margin-bottom: 1em;
    }
  }
  .gift-card-container {
    margin-top: 0.6em;
    padding: 0.2em 0;
    margin-left: 0.8%;
    margin-bottom: 1rem;
    width: 99%;
    &.gift-cards-active {
      border-bottom: solid 1px ${colors.grey};
    }
    .checkbox-label {
      font-size: unset;
    }
    .note {
      margin-top: 0.5rem;
      font-size: ${fonts.txtSmall};
      @media only screen and (min-width: ${breakPoints.medium}) {
        font-size: ${fonts.txtSmall};
      }
    }
    .invalid-message {
      margin-bottom: 0;
      margin-top: 1rem;
    }
  }
  input[type='checkbox'] {
    margin-right: 1em;
    margin-left: calc(0.3em);
    margin-top: 1em;
  }
  .rooms-to-go-credit-container {
    margin-left: 0.5%;
    &:focus {
      outline: none;
    }
  }
`

export const FullWidthCentered = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const BtnContainer = styled.div`
  width: 100%;
`

export const SubmitButtonContainer = styled.span`
  width: 50%;
  display: inline-block;
  text-align: left;
  button {
    border-radius: 4px;
    min-height: 40px;
    width: 205px;
  }
  @media (max-width: ${breakPoints.small}) {
    text-align: center;
    width: 100%;
  }
`

export const CancelBtnWrapper = styled.span`
  width: 50%;
  @media (max-width: ${breakPoints.small}) {
    margin: 0 auto;
    display: flex;
    width: 100%;
  }
  button {
    min-height: 40px;
    width: 205px;
    margin-left: 5px;
    margin-top: -2px;
    @media (max-width: ${breakPoints.small}) {
      margin: 0 auto;
    }
  }
`
export const FinancingSvgWrapper = styled(Typography)`
  margin: 5px 0 15px;
  svg {
    position: relative;
    display: inline-block;
    top: 5px;
  }
`

export const SubmitRtgButton = styled.button`
  width: 10em;
  margin-right: 4%;
  background-color: ${({ disabled }) => (disabled ? colors.disabled : colors.primary)};
  border-radius: 0;
  color: ${({ disabled }) => (disabled ? colors.darkerGrey : colors.primaryInvert)};
  border: 0px;
  padding: 12px;
  text-align: center;
  box-sizing: border-box;
  font-size: ${fonts.txtMedium};
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? colors.disabled : colors.lightBlue)};
  }
  @media (max-width: ${breakPoints.small}) {
    margin-right: 0;
  }
`

export const CreditOptionsButton = styled(Link)`
  background-color: ${colors.primary};
  border-radius: 0;
  color: ${colors.primaryInvert};
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  font-size: ${fonts.txtMedium};
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    background-color: ${colors.lightBlue};
  }
`

export const SynchronyApplyWrapper = styled.section`
  display: flex;
  padding: 0.75em 0;
  margin-bottom: 10px;

  > .apply-link {
    color: ${colors.primary};
    margin-left: 0.5rem;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${breakPoints.small}) {
    flex-direction: column;
    align-items: center;
  }
`

export const LoadingSpinner = styled.img`
  height: 1.25rem;
  width: 1.25rem;
  margin: 0;
  float: unset;
`

export const LogoWrapper = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: 30px;
    margin: 0 10px;
  }
`

export const DigitalBuyWrapper = styled.section`
  display: flex;
  padding: 0.75em 0;
  margin-bottom: 10px;

  > .digital-buy-link {
    color: ${colors.primary};
    margin-left: 0.5rem;
    font-weight: 600;
    @media (max-width: ${breakPoints.small}) {
      margin-left: 0;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${breakPoints.small}) {
    flex-direction: column;
    align-items: center;
  }
`

export const Note = styled.p`
  font-size: ${fonts.txtXsmall};
  font-weight: 300;
  font-weight: bold;
`

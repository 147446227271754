import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Radio = styled.input.attrs(({ name, checked, onChange }) => ({
  type: 'radio',
  name,
  checked,
  onChange,
}))`
  && {
    opacity: 1;
    position: relative;
    top: 5px;
  }
`

const RadioButton = ({ name, checked, onChange }) => <Radio name={name} checked={checked} onChange={onChange} />

RadioButton.propTypes = {
  checked: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.any,
}

export default RadioButton

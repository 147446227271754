import React from 'react'
import styled from 'styled-components'
import { arrayOf, object, bool, objectOf, any } from 'prop-types'
import { breakPoints } from '@constants/styles'
import VendorProduct from './vendor-product'
import ShippingBox from '../../../../assets/images/free-shipping-box.png'
import { DeliveryForm } from './styles'

const DirectShipping = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 30px;
`

const Description = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 0 0 0px 20px;
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0px 20px;
`

const DeliveryInfo = styled.p`
  font-size: 15px;
  line-height: 20px;
  color: #2e2e38;
`

const Header = styled.h3`
  font-size: 21px;
  line-height: 28px;
  color: #2e2e38;
`

const ShippedBy = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #2e2e38;
  font-weight: 700;
`

const PackageIcon = styled.img`
  width: 54px;
  height: 55.05px;
`

const FreeText = styled.span`
  font-weight: 700;
`

const ProductContainer = styled.div`
  flex: 1;
  margin: 20px 0 0px 40px;
  @media only screen and (max-width: ${breakPoints['medium-max']}) {
    margin: 20px 0 0px 10px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${breakPoints['medium-max']}) {
    flex-direction: column;
  }
`

const VendorSection = ({ deliveryItems, ups, cart, usps }) => {
  const shippedBy = isVendor => {
    if (ups) return ' via UPS'
    if (usps) return ' via USPS'
    if (isVendor) return 'Vendor Direct Shipping'
    return ''
  }
  return (
    <DeliveryForm className="grid-x">
      <DirectShipping>
        <Header>Items shipped to your door</Header>
        <ContentWrapper>
          <Description>
            <PackageIcon src={ShippingBox} alt="" aria-hidden="true" role="presentation" />
            <Detail>
              <ShippedBy>{`${shippedBy(true)}`}</ShippedBy>
              <DeliveryInfo>
                Delivery Fee: <FreeText>Free</FreeText>
              </DeliveryInfo>
            </Detail>
          </Description>
          <ProductContainer>
            {deliveryItems.map(item => {
              const itemInCart =
                cart &&
                cart.cartItems &&
                cart.cartItems.length > 0 &&
                cart.cartItems.filter(cartItem => cartItem.sku === item.sku)[0]
              return (
                <VendorProduct
                  key={item.sku}
                  product={itemInCart ? itemInCart.product : item}
                  quantity={item.quantity}
                  warrantyEnabled={item.warrantyEnabled}
                />
              )
            })}
          </ProductContainer>
        </ContentWrapper>
      </DirectShipping>
    </DeliveryForm>
  )
}

VendorSection.propTypes = {
  deliveryItems: arrayOf(object),
  ups: bool,
  usps: bool,
  cart: objectOf(any),
}

export default VendorSection

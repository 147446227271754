import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { colors } from '@constants/styles'
import { setCheckoutStep } from '@helpers/checkout/global'
import CheckoutStepContent from './checkout-step-content'
import CheckoutStepHeader from './checkout-step-header'

const StyledSection = styled.div`
  padding: 0.7em 0;
  background: ${colors.primaryInvert};
  text-align: right;
`

class CheckoutStep extends React.Component {
  handleKeyPress(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  render() {
    const {
      sectionTitle,
      sectionType,
      sectionNumber,
      previousSection,
      checkoutStep,
      loading,
      nextSection,
      onSetCheckoutStep,
      order,
      children,
    } = this.props
    return (
      <StyledSection>
        <CheckoutStepHeader
          sectionTitle={sectionTitle}
          sectionType={sectionType}
          sectionNumber={sectionNumber}
          previousSection={previousSection}
          checkoutStep={checkoutStep}
        />
        <CheckoutStepContent
          checkoutStep={checkoutStep}
          sectionType={sectionType}
          loading={loading}
          nextSection={nextSection}
          onSetCheckoutStep={onSetCheckoutStep}
          order={order}
        >
          {children}
        </CheckoutStepContent>
      </StyledSection>
    )
  }
}

CheckoutStep.propTypes = {
  checkoutStep: PropTypes.string,
  loading: PropTypes.bool,
  nextSection: PropTypes.string,
  onSetCheckoutStep: PropTypes.func,
  order: PropTypes.object,
  previousSection: PropTypes.string,
  sectionNumber: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionType: PropTypes.string,
  children: PropTypes.node,
}

const mapStateToProps = state => ({
  order: state.checkout.order,
  checkoutStep: state.checkout.checkoutStep,
  loading: state.checkout.loading,
})

const mapDispatchToProps = dispatch => ({
  onSetCheckoutStep: checkoutStep => dispatch(setCheckoutStep(checkoutStep)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStep)

/* eslint-disable func-names,no-param-reassign,no-shadow */
import React from 'react'
import { func } from 'prop-types'
import { getAffirmBody } from '@helpers/checkout/payment-section/affirm'
import AffirmButton from './affirm-button'

class AffirmButtonWrapper extends React.Component {
  state = {
    affirmLoaded: false,
  }

  affirmFunc = (l, g, m, e, a, f, b) => {
    const c = l[m] || {}
    const h = document.createElement(f)
    const n = document.getElementsByTagName(f)[0]
    const k = function(a, b, c) {
      return function() {
        // eslint-disable-next-line prefer-rest-params
        a[b]._.push([c, arguments])
      }
    }
    c[e] = k(c, e, 'set')
    const d = c[e]
    c[a] = {}
    c[a]._ = []
    d._ = []
    c[a][b] = k(c, a, b)
    a = 0
    for (b = 'set add save post open empty reset on off trigger ready setProduct'.split(' '); a < b.length; a++)
      d[b[a]] = k(c, e, b[a])
    a = 0
    for (b = ['get', 'token', 'url', 'items']; a < b.length; a++) d[b[a]] = function() {}
    h.async = !0
    h.src = g[f]
    if (n) n.parentNode.insertBefore(h, n)
    delete g[f]
    d(g)
    l[m] = c
    this.setState({ affirmLoaded: true })
  }

  affirmClick = () => {
    const { setOrderInfo } = this.props
    if (setOrderInfo instanceof Function) {
      setOrderInfo()
    }
    /* eslint-disable no-undef */
    affirm.checkout(getAffirmBody())
    affirm.checkout.open()
  }

  render() {
    const { affirmLoaded } = this.state
    const _affirm_config = {
      public_api_key: process.env.GATSBY_AFFIRM_API_KEY,
      script: `${process.env.GATSBY_AFFIRM_URL}/js/v2/affirm.js`,
    }
    return (
      <AffirmButton
        affirmLoaded={affirmLoaded}
        affirmFunc={this.affirmFunc}
        affirm_config={_affirm_config}
        affirmClick={this.affirmClick}
      />
    )
  }
}

AffirmButtonWrapper.propTypes = {
  setOrderInfo: func,
}

export default AffirmButtonWrapper

import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { breakPoints, fonts } from '@constants/styles'
import { abbreviateState } from '@helpers/geo-location'

const StyledShippingCondensed = styled.div`
  &&& {
    text-align: left;
    margin-left: 1em;
    min-height: 6.8em;
    h3 {
      font-weight: 600;
      text-align: left;
    }
    .left-info {
      float: left;
      margin-right: 6em;
      text-transform: capitalize;
      @media only screen and (max-width: ${breakPoints.small}) {
        font-size: ${fonts.txtSmall};
      }
    }
    .right-info {
      @media only screen and (max-width: ${breakPoints.small}) {
        font-size: ${fonts.txtSmall};
      }
    }
  }
`
const ShippingCondensed = ({ order }) => {
  const shippingInfoArr = order.shippingAddress.addressLookup.split(',')
  return (
    <StyledShippingCondensed>
      <div className="left-info">
        {`${order.contact.firstName} ${order.contact.lastName}`}
        <br />
        {order.shippingAddress.addressLookup && order.shippingAddress.addressLookupSuccess && (
          <>
            {shippingInfoArr[0]}
            <br />
            {shippingInfoArr[1]}
          </>
        )}
        {!order.shippingAddress.addressLookupSuccess && (
          <>
            {order.shippingAddress.address1}
            {order.shippingAddress.address2 &&
              order.shippingAddress.address2 !== '' &&
              ` ${order.shippingAddress.address2}`}
            <br />
            {`${order.shippingAddress.city} `}
            {`${abbreviateState(order.shippingAddress.state)} `}
            {order.shippingAddress.zip}
          </>
        )}
      </div>
      <div className="right-info">
        {order.contact.email}
        <br />
        {order.contact.phone}
        <br />
        {order.contact.altPhone && `Alt. Phone: ${order.contact.altPhone}`}
      </div>
    </StyledShippingCondensed>
  )
}

ShippingCondensed.propTypes = {
  order: PropTypes.any,
}

export default ShippingCondensed

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { colors, fonts } from '@constants/styles'
import onKeyDownCheck from '@helpers/508/onkeydown-check'
import { setCheckoutStep } from '@helpers/checkout/global'
import { getFromBrowserStorage } from '@helpers/storage'
import { checkoutStepsOrIndex } from '@helpers/checkout/checkout'

const SectionHeader = styled.div`
  margin: 0 1.5%;
  width: 97%;
  height: 3em;
  i.circle {
    float: left;
    width: 2.5em;
    height: 2.5em;
    text-align: center;
    background-color: #000;
    border-radius: 50%;

    p.section-number {
      font-size: 1.25rem;
      margin-top: 9%;
      font-weight: 600;
      color: ${colors.primaryInvert};
    }
  }

  h2.section-title {
    font-weight: bold;
    font-size: ${fonts.txtMediumUp};
    margin: 0.1em 0.5em;
    float: left;
    line-height: inherit;
  }

  button.edit-btn {
    float: right;
    margin: 0.45em calc(0.15em + 1%);
    text-transform: uppercase;
    color: ${colors.primary};
    font-size: ${fonts.txtMediumUp};
    font-weight: bold;
  }

  button.edit-btn:hover {
    text-decoration: underline;
  }

  button.edit-btn.disabled {
    color: ${colors.mediumGrey};
    text-decoration: none;
  }
`

const CheckoutStepHeader = props => {
  const { sectionTitle, sectionType, sectionNumber, previousSection, checkoutStep } = props
  const currentIndex = checkoutStepsOrIndex(checkoutStep)
  const order = getFromBrowserStorage('session', 'order')
  let editIsDisabled

  if (order && order.lineItems) {
    const storeCartLineItems = order.lineItems.filter(lineItem => lineItem.isStoreSku)
    const cartOnlyHasStoreCartItems = storeCartLineItems.length === order.lineItems.length
    editIsDisabled = currentIndex < sectionNumber || cartOnlyHasStoreCartItems
  }

  return (
    <SectionHeader>
      <i className="icon circle">
        <p className="section-number">{sectionNumber}</p>
      </i>
      <h2 id={`Step${sectionNumber}Header`} tabIndex="-1" className="section-title">
        {sectionTitle}
      </h2>
      {checkoutStep !== sectionType && !editIsDisabled && (
        <button
          className="edit-btn"
          value="Edit"
          type="button"
          aria-label={
            currentIndex < sectionNumber
              ? `Complete ${previousSection} step before proceeding to ${sectionTitle}`
              : null
          }
          onClick={e => setCheckoutStep(e, checkoutStep, sectionType)}
          onKeyDown={e => onKeyDownCheck(e.keyCode) && setCheckoutStep(e, checkoutStep, sectionType)}
        >
          EDIT <span className="hide508">{sectionTitle}</span>
        </button>
      )}
    </SectionHeader>
  )
}

CheckoutStepHeader.propTypes = {
  checkoutStep: PropTypes.string,
  previousSection: PropTypes.string,
  sectionNumber: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionType: PropTypes.string,
}

export default CheckoutStepHeader
